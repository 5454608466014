import { useForm } from '@tanstack/react-form'
import debug from 'debug'
import { useContext } from 'react'
import Button from 'src/components/buttons/button'
import CheckboxField from 'src/components/fields/checkbox.field'
import Form from 'src/components/fields/form'
import ListboxField from 'src/components/fields/listbox.field'
import NumberField from 'src/components/fields/number.field'
import TextField from 'src/components/fields/text.field'
import TextAreaField from 'src/components/fields/text-area.field'
import Icon from 'src/components/icons/icon'
import BasicModal from 'src/components/modals/modal.basic'
import { VendiaUniqueKey } from 'src/types/schema'
import { assert } from 'src/utils/assert'
import { isRequiredOnChange } from 'src/utils/form/validation'
import { useFirstFocus } from 'src/utils/hooks/use-first-focus'

import { SchemaDesignerContext, SchemaDesignerField } from './schema-designer-context'
import { Status } from './status'
import { canBeIndexedOrUnique, getAtPath, getExistingIndexKey, getSchemaKeyFromName, schemaTypeToLabel } from './utils'

const logger = debug('components:schema-designer:field-modal')

const MAX_ALLOWED_INDEXES = 12
const MAX_ALLOWED_UNIQUE_FIELDS = 2

type FormValues = {
  title?: string
  description?: string
  type?: string
  stringFormat?: string
  minLength?: string
  maxLength?: string
  min?: string
  max?: string
  arrayItemType?: string
  arrayOfArraysItemType?: string
  pattern?: string
  enumValues?: string
  required: boolean
  indexed: boolean
  isUnique: boolean
}

export function FieldModal({ title, isOpen }: { title: string; isOpen: boolean }) {
  const titleRef = useFirstFocus(isOpen)

  const {
    setDesignerState,
    schema,
    status,
    selectedFieldKey,
    selectedEntityKey,
    selectedFieldParentPath,
    upsertField,
  } = useContext(SchemaDesignerContext)

  assert(schema, 'schema is required for FieldModal')

  const parentObject = selectedFieldParentPath ? getAtPath({ schema, path: selectedFieldParentPath }) : undefined
  const selectedField = parentObject && selectedFieldKey ? parentObject?.properties?.[selectedFieldKey] : undefined

  let stringFormat = noneStringFormatOption.value
  if (status === Status.SHOW_FIELD_MODAL_EDIT) {
    // logger('resetting form with existing values', selectedField)
    // If we have a string format, match against options
    if (selectedField?.format) {
      // Allow them to keep unsupported formats - we select an "unknown" option and leave it alone on submit
      stringFormat =
        stringFormatOptions.find((o) => o.value === selectedField?.format)?.value ?? unknownStringFormatOption.value
    }
    // If they're using "pattern" for regex, we need to select the regex option
    if (selectedField?.pattern && stringFormat === 'none') {
      stringFormat = regexStringFormatOption.value
    }
    // If they're using "enum" for a list of values, we need to select the enum option
    if (Array.isArray(selectedField?.enum) && stringFormat === 'none') {
      stringFormat = enumStringFormatOption.value
    }
  }

  const onClose = () => {
    form.reset()
    setDesignerState({ status: Status.IDLE, selectedFieldParentPath: null, selectedFieldKey: null })
  }

  const form = useForm<FormValues>({
    defaultValues: {
      ...(selectedField as unknown as FormValues),
      title: selectedField?.title ?? selectedFieldKey ?? undefined,
      type: selectedField?.type ? typeOptions.find((o) => o.value === selectedField?.type)?.value : undefined,
      stringFormat: stringFormat,
      enumValues: selectedField?.enum?.join?.(', '),
      arrayItemType:
        selectedField?.type === 'array' && selectedField?.items?.type
          ? typeOptions.find((o) => o.value === selectedField?.items?.type)?.value
          : undefined,
      arrayOfArraysItemType:
        selectedField?.type === 'array' && selectedField?.items?.items?.type
          ? typeOptions.find((o) => o.value === selectedField?.items?.items?.type)?.value
          : undefined,
      required:
        Array.isArray(parentObject?.required) && selectedFieldKey
          ? parentObject?.required.includes(selectedFieldKey)
          : false,
      isUnique:
        Array.isArray(parentObject?.[VendiaUniqueKey]) && selectedFieldKey
          ? parentObject?.[VendiaUniqueKey].includes(selectedFieldKey)
          : false,
      indexed: !!getExistingIndexKey({ schema, entityKey: selectedEntityKey, fieldKey: selectedFieldKey }),
    },
    onSubmit: async ({ value }) => {
      upsertField({
        ...value,
        previousFieldKey: selectedFieldKey ?? undefined,
        minLength: parseInt(value.minLength!),
        maxLength: parseInt(value.maxLength!),
        min: parseInt(value.min!),
        max: parseInt(value.max!),
      } as SchemaDesignerField)
      onClose()
    },
  })

  const watchType = form.useStore((state) => state.values.type)
  const watchStringFormat = form.useStore((state) => state.values.stringFormat)
  const arrayItemType = form.useStore((state) => state.values.arrayItemType)

  const typeIsString = watchType === 'string'
  const typeIsNumberOrInt = watchType === 'number' || watchType === 'integer'

  return (
    <BasicModal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      buttons={[
        <Button
          key='cancel'
          kind='secondary'
          onClick={() => {
            logger('cancel clicked')
            onClose()
          }}
        >
          Cancel
        </Button>,
        <form.Subscribe selector={(state) => state.isFieldsValid} key='save'>
          {(isFieldsValid) => (
            <Button kind='primary' type='submit' form='field-modal-form' disabled={!isFieldsValid}>
              Save
            </Button>
          )}
        </form.Subscribe>,
      ]}
    >
      <Form<FormValues> id='field-modal-form' className='flex flex-col gap-4' form={form}>
        <div className='flex flex-col gap-4'>
          <TextField
            ref={titleRef}
            name='title'
            label='Name'
            placeholder='Name'
            form={form}
            validators={{
              onBlur: ({ value }) => {
                if (!value) return 'This field is required'
                const valueAsKey = getSchemaKeyFromName({ name: value })
                if (valueAsKey !== selectedFieldKey && parentObject?.properties?.[valueAsKey]) {
                  return 'An attribute with this name already exists'
                }
              },
            }}
          />
          <TextAreaField name='description' label='Description' placeholder='Description' form={form} />

          <div className='mb-4'>
            <ListboxField
              name='type'
              label='Data type'
              options={typeOptions}
              form={form}
              validators={isRequiredOnChange}
              wrapperClassName='mb-4'
            />

            {typeIsNumberOrInt && (
              <div className='mb-4 flex w-full gap-4'>
                <NumberField
                  label='Minimum value'
                  name='min'
                  placeholder='None'
                  form={form}
                  wrapperClassName={'w-1/2'}
                />
                <NumberField
                  label='Maximum value'
                  name='max'
                  placeholder='None'
                  form={form}
                  wrapperClassName={'w-1/2'}
                />
              </div>
            )}

            {typeIsString && (
              <div className='mb-4'>
                <ListboxField
                  name='stringFormat'
                  label='Text format'
                  description='Enforce a format for the text, e.g. "email" or provide your own regular expression'
                  options={watchStringFormat === 'unknown' ? stringFormatOptions : stringFormatOptions.slice(1)}
                  form={form}
                />
              </div>
            )}

            {typeIsString && watchStringFormat === 'regex' && (
              <div className='mb-4'>
                <TextField
                  name='pattern'
                  label='Regular expression'
                  placeholder='Regular expression'
                  description={
                    <span>
                      Provide a regular expression to validate the supplied text.{' '}
                      <a
                        className='inline-flex items-center gap-1'
                        target='_blank'
                        href='https://json-schema.org/understanding-json-schema/reference/string.html#regular-expressions'
                        rel='noopener noreferrer'
                      >
                        More about regular expressions
                        <Icon size={'xxs'} name='external' />
                      </a>
                      <br />
                      Note: the expression should <i>not</i> be wrapped in forward slashes or other delimiters (e.g.{' '}
                      <code>/^abc$/</code> should be <code>^abc$</code>)
                    </span>
                  }
                  form={form}
                  validators={{
                    onBlur: ({ value }) => {
                      if (!value) return 'This field is required because you have selected Custom regex for Text format'
                      try {
                        RegExp(value)
                      } catch (e) {
                        return 'The supplied regular expression is invalid'
                      }
                    },
                  }}
                />
              </div>
            )}

            {typeIsString && watchStringFormat === 'enum' && (
              <div className='mb-4'>
                <TextField
                  name='enumValues'
                  label='List allowed text values (Enum)'
                  description={
                    <span>
                      Provide a comma-separated list of allowed values for this field (e.g. <code>red,green,blue</code>
                      ).
                    </span>
                  }
                  form={form}
                  validators={{
                    onBlur: ({ value }) => {
                      if (!value)
                        return 'This field is required because you have selected List allowed values (Enum) for Text format'
                    },
                  }}
                />
              </div>
            )}

            {typeIsString && (
              <div className='mb-4 flex w-full gap-4'>
                <NumberField
                  label='Minimum length'
                  name='minLength'
                  placeholder='None'
                  form={form}
                  min={0}
                  wrapperClassName={'w-1/2'}
                  validators={{
                    onBlur: ({ value }) => {
                      if (value && value < 0) return 'Minimum length must be a positive integer'
                    },
                  }}
                />
                <NumberField
                  label='Maximum length'
                  name='maxLength'
                  placeholder='None'
                  form={form}
                  min={0}
                  wrapperClassName={'w-1/2'}
                  validators={{
                    onBlur: ({ value }) => {
                      if (value && value < 0) return 'Maximum length must be a positive integer'
                    },
                  }}
                />
              </div>
            )}

            {watchType === 'array' && (
              <div className='mb-4'>
                <ListboxField
                  name='arrayItemType'
                  label='Data type of list items'
                  options={typeOptions}
                  form={form}
                  validators={{
                    onBlur: ({ value }) => {
                      if (!value) return 'This field is required when "Data type" is set to "List"'
                    },
                  }}
                />
              </div>
            )}
            {/* Support for 2D arrays of scalars, trying to support 2D arrays but not trying that hard */}
            {arrayItemType === 'array' && (
              <div className='mb-4'>
                <ListboxField
                  name='arrayOfArraysItemType'
                  label='Data type of items of list of lists'
                  options={typeOptions.filter((o) => o.value !== 'array' && o.value !== 'object')}
                  form={form}
                  validators={{
                    onBlur: ({ value }) => {
                      if (!value) return 'This field is required when "Data type" is set to a "List" of "List" items'
                    },
                  }}
                />
              </div>
            )}
            <CheckboxField name='required' label='Required attribute' form={form} wrapperClassName='mb-2' />

            {canBeIndexedOrUnique(selectedFieldParentPath) && isScalarType(watchType!) && (
              <CheckboxField
                name='isUnique'
                label='Unique identifier'
                description='this attribute can be used to identify and update items'
                form={form}
                validators={{
                  onChange: ({ value }) => {
                    // Make sure they are not surpassing the max number of allowed unique fields
                    const existingUniqueCount = parentObject?.[VendiaUniqueKey]?.length ?? 0
                    const includesThisValue =
                      selectedFieldKey && parentObject?.[VendiaUniqueKey]?.includes(selectedFieldKey)
                    if (value && existingUniqueCount >= MAX_ALLOWED_UNIQUE_FIELDS && !includesThisValue) {
                      return `You've already reached the maximum number of unique attributes per entity (${MAX_ALLOWED_UNIQUE_FIELDS}).`
                    }
                  },
                }}
              />
            )}

            {canBeIndexedOrUnique(selectedFieldParentPath) && isScalarType(watchType!) && (
              <>
                <div className='mb-4 mt-2 border-t border-gray-200 pt-4'>
                  <div className='flex items-center gap-2 text-sm'>
                    <strong>Advanced settings </strong>
                    <i>- Optional</i>
                  </div>
                </div>
                <CheckboxField
                  name='indexed'
                  wrapperClassName='mb-2'
                  label='Indexed attribute'
                  description='improves query efficiency and enables sorting.'
                  form={form}
                  validators={{
                    onChange: ({ value }) => {
                      // Make sure they are not surpassing the max number of allowed indexes
                      const existingIndexCount = Object.keys(schema['x-vendia-indexes'] ?? {}).length
                      const includesThisValue = getExistingIndexKey({
                        schema,
                        entityKey: selectedEntityKey,
                        fieldKey: selectedFieldKey,
                      })
                      if (value && existingIndexCount >= MAX_ALLOWED_INDEXES && !includesThisValue) {
                        return `You've already reached the maximum number of indexes per Uni (${MAX_ALLOWED_INDEXES}).`
                      }
                    },
                  }}
                />
                <Button
                  target='_blank'
                  href='https://docs.vendia.com/platform/operational/modeling/data-modeling#indexes'
                  rel='noopener noreferrer'
                  iconPosition='right'
                  iconSize={'xs'}
                  icon='external'
                  kind='link'
                  label='Learn about indexes'
                />
              </>
            )}
          </div>
        </div>
      </Form>
    </BasicModal>
  )
}

// NOTE: not including "boolean" here because I don't _think_ we'll support booleans as "unique fields" and
// I _know_ we don't actually support booleans as indexes in the database
const isScalarType = (type: string) => ['string', 'integer', 'number'].includes(type)

const typeOptions = [
  { label: schemaTypeToLabel('string'), value: 'string' },
  { label: schemaTypeToLabel('integer'), value: 'integer' },
  { label: schemaTypeToLabel('number'), value: 'number' },
  { label: schemaTypeToLabel('boolean'), value: 'boolean' },
  { label: schemaTypeToLabel('object'), value: 'object' },
  { label: schemaTypeToLabel('array'), value: 'array' },
]

// 'none' 'unknown' and 'regex' are special values we use to decide whether to apply "format", show the custom regex input, etc.
const noneStringFormatOption = { label: 'None', value: 'none', description: 'No special formatting rules' }
// Allow users to keep a format they had before that we don't support yet
const unknownStringFormatOption = {
  label: 'Unknown format',
  value: 'unknown',
  description: `It looks like you were using a format that this schema design tool doesn't support yet.`,
}
const regexStringFormatOption = {
  label: 'Custom regex',
  value: 'regex',
  description: 'Specify a regular expression (e.g. ^abc$ for alphanumeric characters only)',
}
const enumStringFormatOption = {
  label: 'List allowed values (Enum)',
  value: 'enum',
  description: 'Specify a list of allowed values (e.g. red, green, blue)',
}
export const supportedStringFormatOptions = ['email', 'uri', 'date', 'time', 'date-time']
const stringFormatOptions = [
  unknownStringFormatOption,
  noneStringFormatOption,
  regexStringFormatOption,
  enumStringFormatOption,
  { label: 'Email', value: 'email', description: 'Values must be a valid email address (e.g. person@example.com)' },
  { label: 'URI', value: 'uri', description: 'A valid URI (e.g. http://www.example.com)' },
  { label: 'Date', value: 'date', description: 'A valid ISO date (e.g. 2018-11-13)' },
  { label: 'Date-time', value: 'date-time', description: 'A valid ISO date-time (e.g. 2018-11-13T20:20:39+00:00)' },
  // Time probably not used much and server validation requires "Z" suffix which isn't required by entity explorer validation
  // { label: 'Time', value: 'time', description: 'A valid ISO time (e.g. 20:20:39+00:00)' },
]
