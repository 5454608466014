import { LakehouseTableInput, ShareApp, Uni } from '@vendia/management-api-types'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import Button from 'src/components/buttons/button'
import Card, { CardRule } from 'src/components/containers/card'
import { LakehouseTableForm } from 'src/utils/lakehouse/types'

import { ProductPolicies } from '../product-policies'

export const SharingPoliciesCard = ({ table }: { table: LakehouseTableInput }) => {
  const [expanded, setExpanded] = useState(false)
  const navigate = useNavigate()

  const tableWithPolicies = { ...table, ...table.tableDefinition } as LakehouseTableForm

  return (
    <Card className='w-full' padding='none' overflow='visible'>
      <div>
        <div className='flex items-center justify-between p-6 text-sm font-bold'>
          <h4>Data Filtering</h4>
          <div className='flex gap-2'>
            {/*
              <Button
                kind='icon'
                size='small'
                icon='pencil'
                label='Edit sharing policies'
                onClick={() => navigate('../data/edit-tables')}
              />
            */}
            <Button
              kind='icon'
              size='small'
              icon={expanded ? 'chevron-up' : 'chevron-down'}
              label='Toggle data filtering details'
              onClick={() => setExpanded(!expanded)}
            />
          </div>
        </div>
        {expanded && (
          <div>
            <CardRule />
            <ProductPolicies table={tableWithPolicies} />
          </div>
        )}
      </div>
    </Card>
  )
}
