import { useEffect } from 'react'
import { useRouteError } from 'react-router'
import { captureException } from 'src/utils/misc/sentry'

import Button from '../buttons/button'

// Note - avoid using custom UI components as many rely on providers that don't wrap this error boundary fallback

export function ErrorFallback() {
  let error = useRouteError() as Error

  useEffect(() => {
    console.error('Fallback to error boundary page', error)
    captureException(error) // Error broken here.
  }, [error])

  return (
    <div className='min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8'>
      <div className='mx-auto max-w-max'>
        <main className='sm:flex'>
          <p className='h1'>500</p>
          <div className='sm:ml-6'>
            <div className='sm:border-l sm:border-gray-200 sm:pl-6'>
              <h1 className='h1'>Server Error</h1>
              <p className='mt-1 text-base text-gray-500'>Something unexpected happened 😬</p>
            </div>
            <div className='mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6'>
              <Button to='/'>Go back home</Button>
              <Button
                kind='secondary'
                onClick={() => {
                  window.location.href = 'mailto:support@vendia.com'
                }}
              >
                Contact support
              </Button>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
