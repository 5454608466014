'use client'

import * as SwitchPrimitives from '@radix-ui/react-switch'
import * as React from 'react'
import { mergeClassNames } from 'src/utils/tw'

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & { useGradient?: boolean }
>(({ className, useGradient = false, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={mergeClassNames(
      'focus:ring-brand peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full transition-colors focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-50',
      useGradient
        ? 'data-[state=checked]:bg-brand data-[state=unchecked]:bg-slate-300'
        : 'data-[state=checked]:bg-primary data-[state=unchecked]:bg-input',
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={mergeClassNames(
        'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow-sm transition-transform',
        'data-[state=checked]:translate-x-6 data-[state=unchecked]:translate-x-1',
      )}
    />
  </SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
