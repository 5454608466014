import { LakehouseTableColumnDef, LakehouseTableInput, Uni } from '@vendia/management-api-types'
import debug from 'debug'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router'
import MultiStepFlow from 'src/components/flows/multi-step-flow'
import Loader from 'src/components/loaders/page-loader'
import { ALL_PARTNERS_STAR } from 'src/components/tables/sharing-rules-table.utils'
import { StepId } from 'src/pages/uni-create/config'
import { StepCreateColumnSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/create-column-sharing-permissions.step'
import { StepCreateRowSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/create-row-sharing-permissions.step'
import { StepReviewSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/review-sharing-permissions.step'
import { StepSelectUpdateColumns } from 'src/pages/uni-create/flow-lakehouse/select-update-column.step'
import { StepTableSettings } from 'src/pages/uni-create/flow-lakehouse/table-settings.step'
import { generateTableAclInputFromAccessLevel } from 'src/utils/csv-uploader/graphql.utils'
import useApi from 'src/utils/hooks/use-api'
import { useGetLakehouseConnections } from 'src/utils/hooks/use-get-lakehouse-connections'
import { useGetLakehouseData } from 'src/utils/hooks/use-get-lakehouse-data'
import { useGetShareApps } from 'src/utils/hooks/use-get-share-apps'
import { useGetUni } from 'src/utils/hooks/use-get-uni'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import notify from 'src/utils/notify'

const logger = debug('app:updateVendiaTableFlow')

// NOTE: this is just used to update INCOMPLETE tables, but will likely have enough in common with
//  general "udpate a table sync schedule, ACLs, etc" flow that it can be used for that as well
//  Overall thought this would be different enough from the create flow that it should have a separate entry point

export const UpdateVendiaTableFlow = () => {
  const { data: uniData } = useGetUni()
  const uni = uniData?.getUni as Uni
  const { data } = useGetLakehouseData()
  const shareAppConfig = data?.currNodeConfig
  const connectionsQuery = useGetLakehouseConnections()
  const connections = connectionsQuery.data?.connections
  const { node: nodeName, tableId } = useParams()

  const navigate = useNavigate()
  const { refetch: refetchShareApps } = useGetShareApps({ uniName: uni?.name })
  const api = useApi()

  const existingTables = data?.tables ?? []
  const table = existingTables.find((table) => table.key === tableId)
  const connection = connections?.find((conn) => conn.id === table?.sourceConnectionId)

  const [selectedTableColumns, setSelectedTableColumns] = useState<LakehouseTableColumnDef[] | null>(null)
  useEffect(() => {
    async function fetchTableSchema() {
      const response = await api.getLakehouseTableSchema({
        tableName: table?.sourceTableDefinitions[0].tableName!,
        uniName: uni.name,
        nodeName: nodeName!,
        connectionId: connection?.id!,
        snowflakeConnection: connection?.snowflakeConnection ?? undefined,
        clouderaConnection: connection?.clouderaConnection ?? undefined,
      })
      if (response.errors) {
        notify.error(`There was a problem fetching the table schema: ${response.errors[0].message}<br/><br/>
          This may be due to a connection issue or the table no longer existing in the source system.`)
        navigate(`/uni/${uni?.name}/${nodeName}/data`)
        return
      }
      setSelectedTableColumns(response.getLakehouseTableSchema)
    }
    if (table && connection) {
      fetchTableSchema()
    }
  }, [table, connection])

  const updateTable = async ({ value }: { value: LakehouseFlowStepValues }) => {
    const { product: formTable, source, accessLevel } = value

    const updatedTableInput: LakehouseTableInput = {
      key: table?.key!,
      name: formTable.name,
      syncSchedule: formTable.syncSchedule,
      tableDefinition: {
        columnPolicies: formTable.columnPolicies,
        rowPolicies: formTable.rowPolicies,
        defaultColumnPolicyEffect: formTable.defaultColumnPolicyEffect,
      },
      sourceConnectionId: connection?.id!,
      sourceConnectionType: connection?.type!,
      sourceTableDefinitions: [
        {
          tableName: source.selectedTable,
          columns: value.selectedTableColumns,
          lastUpdatedColumn: value.lastUpdatedColumn,
          lastUpdatedColumnFormat: value.lastUpdatedColumnFormat,
          keyColumns: value.keyColumns,
        },
      ],
    }

    const aclInput = generateTableAclInputFromAccessLevel(accessLevel)

    const response = await api.updateLakehouseTable({
      input: {
        uniName: uni.name,
        nodeName: nodeName!,
        table: updatedTableInput,
        aclInput,
      },
    })

    if (response.errors) {
      notify.error(`Error adding table: ${response.errors.map((error) => error.message).join(', ')}`)
    } else {
      notify.success('Table added successfully!')
    }
  }

  if (!uni || !shareAppConfig || !table || !connection || !selectedTableColumns) {
    return (
      <div className='grid place-items-center'>
        <Loader />
      </div>
    )
  }

  if (existingTables && !table) {
    notify.error(`Table with id ${tableId} not found`)
    return <Navigate to={`/uni/${uni?.name}/${nodeName}/data`} />
  }

  if (connections && table && !connection) {
    notify.error(`The connection used to create this table was not found (id: ${table.sourceConnectionId})`)
    return <Navigate to={`/uni/${uni?.name}/${nodeName}/data`} />
  }

  return (
    <MultiStepFlow<LakehouseFlowStepValues>
      initialFlowState={{
        flowType: 'updateTable',
        // TODO: Populate this from table once we're persisting it
        accessLevel: {
          // Adding a key for each node name in uni.nodes defaulting to 'READ' for now
          [ALL_PARTNERS_STAR]: 'READ',
          ...(uni?.nodes?.reduce((acc, node) => ({ ...acc, [node.name]: 'READ' }), {}) ?? {}),
        },
        uniName: uni.name,
        nodeName: nodeName,
        product: {
          name: table.name,
          columnPolicies: table.tableDefinition.columnPolicies,
          rowPolicies: table.tableDefinition.rowPolicies,
          defaultColumnPolicyEffect: table.tableDefinition.defaultColumnPolicyEffect,
          syncSchedule: table.syncSchedule,
        },
        source: {
          selectedTable: table.sourceTableDefinitions[0].tableName,
          // Populating this, but only used to add a source logo on review step for the update flow for now
          connection: connection.type,
          availableTables: [],
          sourceRegion: '',
          cloudera: connection.clouderaConnection ? connection.clouderaConnection : undefined,
          snowflake: connection.snowflakeConnection ? connection.snowflakeConnection : undefined,
        },
        selectedTableColumns,
        // Start with an empty space entry so the first dropdown is rendered
        keyColumns: [''],
        lastUpdatedColumn: '',
        lastUpdatedColumnFormat: '',
        useIncrementalUpdates: true,
        existingVendiaTables: existingTables,
        existingConfig: shareAppConfig,
        connectionId: connection.id!,
      }}
      flowSteps={[
        {
          id: StepId.TableSettings,
          StepComponent: StepTableSettings,
        },
        {
          id: StepId.SelectUpdateColumns,
          StepComponent: StepSelectUpdateColumns,
        },
        {
          id: StepId.ColumnSharingPermissions,
          StepComponent: StepCreateColumnSharingPermissions,
        },
        {
          id: StepId.RowSharingPermissions,
          StepComponent: StepCreateRowSharingPermissions,
        },
        {
          id: StepId.SharingPermissions,
          StepComponent: StepReviewSharingPermissions,
        },
      ]}
      onSubmit={async ({ value }) => {
        await updateTable({ value })

        refetchShareApps()
        navigate(`/uni/${uni?.name}/${nodeName}/data`)
      }}
    />
  )
}
