import { useQuery } from '@tanstack/react-query'
import { LakehouseTableStatusEnum, ShareApp } from '@vendia/management-api-types'
import debug from 'debug'
import { useParams } from 'react-router'
import { useRecoilValue } from 'recoil'
import { LakehouseShareAppConfig, parseLakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { ShareAppName, Table } from 'src/types/types'
import useApi from 'src/utils/hooks/use-api'

import { useNodeApi } from '../api-provider'
import { selectedRoleState } from '../state'
import { useGetShareApps } from './use-get-share-apps'
const logger = debug('app:get-lakehouse-data')

export const useGetLakehouseData = () => {
  const { id: uniName, node: nodeName } = useParams()
  const api = useApi()
  const nodeApi = useNodeApi()

  const selectedRoleName = useRecoilValue(selectedRoleState).name
  const getShareAppsQuery = useGetShareApps({ uniName })
  const { refetch } = getShareAppsQuery
  const { data } = getShareAppsQuery as { data: ShareApp[] | undefined }

  // Stick this in query key to force a refetch when the data is updated
  const dataLastUpdated = getShareAppsQuery.dataUpdatedAt

  const enabled =
    Boolean(api) && Boolean(nodeApi?.urqlClient) && Boolean(uniName) && Boolean(selectedRoleName) && Boolean(data)

  return useQuery({
    queryKey: ['LakehouseData', selectedRoleName, uniName, nodeName, dataLastUpdated],
    queryFn: async () => {
      // The actual data plane tables, not using them yet but migrating soon and useful to debug/compare now
      const dataPlaneTables = (await fetchVendiaTables(nodeApi?.urqlClient)) as Table[]
      logger('data plane tables', dataPlaneTables)

      const lakehouseApps = data?.filter((shareApp) => shareApp.shareAppName === ShareAppName.LakehouseIngestion)
      const currNodeLakehouseApp = lakehouseApps?.find((app) => app.nodeName === nodeName)

      const lakehouseConfigPerNode = lakehouseApps?.reduce(
        (acc, app) => {
          const config = parseLakehouseShareAppConfig(app.shareAppConfig)
          return { ...acc, [app.nodeName]: config }
        },
        {} as Record<string, LakehouseShareAppConfig>,
      )

      const currNodeConfig = currNodeLakehouseApp && nodeName ? lakehouseConfigPerNode?.[nodeName as string] : undefined
      logger('currNodeConfig', currNodeConfig)

      const tables = dataPlaneTables.filter((table) => table.status !== LakehouseTableStatusEnum.Deleted) as Table[]

      logger('tables', tables)
      return {
        tables,
        currNodeConfig: currNodeConfig as LakehouseShareAppConfig,
        currNodeShareApp: currNodeLakehouseApp as ShareApp,
        lakehouseConfigPerNode: lakehouseConfigPerNode as Record<string, LakehouseShareAppConfig>,
        rawShareApps: data as ShareApp[],
        refetchShareApps: refetch,
      }
    },
    enabled,
    staleTime: 1000 * 30, // 30 seconds, be a little more aggressive to get fresh deployment status
  })
}

const fetchVendiaTables = async (urqlClient: any) => {
  const result = await urqlClient?.query(listTablesQuery, {}).toPromise()
  if (result?.error) {
    throw new Error(result.error?.message)
  }
  const tables = result?.data?.listVendia_TableItems?.Vendia_TableItems ?? []
  // logger('tables', tables)
  try {
    // Parse tableConfig and combine parsed fields with table item
    const niceTables = tables.map((table: any) => {
      const tableConfig = JSON.parse(table?.tableConfig ?? '{}')
      return { ...table, ...tableConfig } as Table
    })
    return niceTables
    // logger('niceTables', niceTables)
  } catch (e) {
    console.error('Error parsing tableConfig', e)
  }
}

const listTablesQuery = /* GraphQL */ `
  query ListTables {
    listVendia_TableItems(order: { _id: DESC }) {
      Vendia_TableItems {
        _id
        sourceDataUri
        name
        snapshotManifestUri
        snapshotManifestEtag
        replicationStatus
        createdTime
        updatedTime
        tableConfig
        _owner
      }
      nextToken
    }
  }
`
