import { Description, Label, Radio, RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import debug from 'debug'
import { PropsWithChildren } from 'react'

import Card from '../containers/card'
import { FormInputProps } from '../fields/types'
import RadioOption from './radio-option'

const logger = debug('app:RadioGroup')

export type RadioGroupInputProps = FormInputProps & {
  onChange?: (value: any) => void
  options: {
    value: any
    label: string | JSX.Element
    description?: string | JSX.Element
  }[]
  value?: string
  isWrapped?: boolean
  dividerClassName?: string
}

function RadioGroupInput({
  label,
  description,
  value,
  hasErrors,
  options,
  isWrapped,
  dividerClassName,
  ...rest
}: RadioGroupInputProps) {
  return (
    <RadioGroup
      {...rest}
      onClick={(e) => {
        // Clicks were triggering form submission
        e.preventDefault()
      }}
      defaultValue={value}
      className={clsx('flex flex-col gap-4', rest.className)}
    >
      {(label || description) && (
        <div>
          {label && (
            <Label className='flex flex-col gap-1'>
              <div className='text-sm font-semibold'>{label}</div>
            </Label>
          )}
          {description && <Description className='text-neutral-8 text-xs'>{description}</Description>}
        </div>
      )}
      <Wrapper isWrapped={isWrapped} className='flex flex-col gap-4'>
        {options.map((option, index) => (
          <RadioOption
            key={JSON.stringify(option.value)}
            option={option}
            index={index}
            hasErrors={hasErrors}
            dividerClassName={dividerClassName}
          />
        ))}
      </Wrapper>
    </RadioGroup>
  )
}

const Wrapper = ({
  children,
  isWrapped,
  className,
}: PropsWithChildren<{ isWrapped?: boolean; className?: string }>) => {
  if (isWrapped) {
    return (
      <Card padding='none' className={clsx('rounded-md', className)}>
        {children}
      </Card>
    )
  }
  return <div className={className}>{children}</div>
}

export default RadioGroupInput
