import React, { ReactNode } from 'react'
import Button from 'src/components/buttons/button'

interface EmptyStateProps {
  title: string
  description?: ReactNode
  imageSource: string
  actions?: ReactNode
}

export default function EmptyState({ title = '', description = '', imageSource = '', actions }: EmptyStateProps) {
  return (
    <div>
      <div className='grid gap-24 p-8 lg:grid-cols-2 lg:p-16'>
        <div className='flex justify-end'>
          <img src={imageSource} alt={title} />
        </div>
        <div className='flex flex-col justify-center gap-6'>
          <div className='flex flex-col justify-center'>
            <h2 className='h2 m-0 mb-5'>{title}</h2>
            <p>{description}</p>
          </div>
          <div className='flex flex-wrap gap-2'>{actions}</div>
        </div>
      </div>
    </div>
  )
}
