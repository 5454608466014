import { useMutation } from '@tanstack/react-query'
import { LakehouseConnectionTypeEnum, LakehouseSnowflakeConnectionInput } from '@vendia/management-api-types'
import debug from 'debug'
import { useNavigate } from 'react-router'
import TextField from 'src/components/fields/text.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { createOnChangeValidator, isRequiredValidator } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { useFirstFocus } from 'src/utils/hooks/use-first-focus'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'
import notify from 'src/utils/notify'

import { StepId } from '../config'

const logger = debug('app:Snowflake')

export const StepConnectSnowflake: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form
  const api = useApi()
  const navigate = useNavigate()
  const prefix = 'source.snowflake'
  const nameRef = useFirstFocus()

  const { uniName, nodeName } = form.useStore((s) => s.values)
  const connection = form.useStore((s) => s.values.source.connection)

  form.useField({ name: `${prefix}.authType`, defaultValue: 'PASSWORD' })

  const snowflake = {
    authType: 'PASSWORD',
    ...form.useStore((s) => s.values.source?.snowflake),
  }

  const getTablesMutation = useMutation({
    mutationFn: () =>
      api.getLakehouseTables({
        uniName: uniName!,
        nodeName: nodeName!,
        connectionInput: {
          type: LakehouseConnectionTypeEnum.Snowflake,
          snowflakeConnection: {
            ...(snowflake as LakehouseSnowflakeConnectionInput),
          },
        },
      }),
    onError: (error) => notify.error(`Error testing Snowflake connection: ${error}`),
    onSuccess: (response) => {
      logger('snowflake connection response', response)
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.getLakehouseTables) {
        notify.error('Error testing Snowflake connection')
        return
      }
      form.setFieldValue('source.availableTables', response.getLakehouseTables.tables as string[])
      form.setFieldValue('source.sourceRegion', response.getLakehouseTables.region as string)
      context.goToStep(StepId.SelectTable)
    },
  })

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Connect to Snowflake'
        description={'Let’s authenticate your Snowflake account'}
      />
      <ScrollableStepContent inset>
        <div className='mt-4 w-full max-w-4xl'>
          <div className='flex w-full flex-col gap-4'>
            <TextField
              ref={nameRef}
              name={`connectionName`}
              label='Name'
              description='Easily identify and reuse this connection to ingest additional tables.'
              form={form}
              maxLength={64}
              useNestedLabel
              validators={createOnChangeValidator(
                {
                  pattern: /^(?!\s*$).{1,64}$/,
                  message: 'The connection name must be between 1 and 64 characters long.',
                },
                true,
              )}
            />
            <TextField
              name={`${prefix}.account`}
              label='Snowflake Account Identifier'
              description={
                <span>
                  The first part of your Snowflake account&apos;s URL, eg.
                  &lt;account-identifier&gt;.snowflakecomputing.com.{' '}
                  <a
                    target='_blank'
                    className='text-link font-bold'
                    href='https://docs.snowflake.com/en/user-guide/admin-account-identifier'
                    rel='noreferrer'
                  >
                    Learn more
                  </a>
                </span>
              }
              className='!w-max flex-1 !rounded-none !border-l-0 !border-r-0'
              leftOfInput={
                <div className='text-light flex h-12 select-none items-center truncate rounded-md rounded-r-none bg-slate-100 px-4 shadow ring ring-1 ring-slate-300'>
                  https://
                </div>
              }
              rightOfInput={
                <div className='text-light flex h-12 select-none items-center truncate rounded-md rounded-l-none bg-slate-100 px-4 shadow ring ring-1 ring-slate-300'>
                  .snowflakecomputing.com
                </div>
              }
              placeholder='ABC123.us-east-1 or ORG123.ABC123'
              form={form}
              useNestedLabel
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectSnowflake,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connection === LakehouseConnectionTypeEnum.Snowflake),
              })}
            />

            <TextField
              name={`${prefix}.warehouse`}
              label='Warehouse'
              description='The warehouse that should be used to execute queries'
              form={form}
              useNestedLabel
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectSnowflake,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connection === LakehouseConnectionTypeEnum.Snowflake),
              })}
            />

            <TextField
              name={`${prefix}.database`}
              label='Database'
              description='The database containing the tables you want to share'
              form={form}
              useNestedLabel
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectSnowflake,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connection === LakehouseConnectionTypeEnum.Snowflake),
              })}
            />

            <TextField
              name={`${prefix}.username`}
              label='Username'
              description='The username for the Snowflake account you want to connect with'
              form={form}
              useNestedLabel
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectSnowflake,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connection === LakehouseConnectionTypeEnum.Snowflake),
              })}
            />

            <TextField
              name={`${prefix}.password`}
              label='Password'
              description='The password for the Snowflake account you want to connect with'
              form={form}
              type='password'
              useNestedLabel
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectSnowflake,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connection === LakehouseConnectionTypeEnum.Snowflake),
              })}
            />

            <TextField
              name={`${prefix}.schema`}
              label='Schema'
              description='The schema where your tables are located. "PUBLIC" schema will be used by default'
              type='text'
              form={form}
              useNestedLabel
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectSnowflake,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connection === LakehouseConnectionTypeEnum.Snowflake),
              })}
            />

            <TextField
              name={`${prefix}.role`}
              label='Role'
              description="The name of the role to use when connecting to Snowflake. If not provided, the user's default role will be used."
              form={form}
              useNestedLabel
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectSnowflake,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connection === LakehouseConnectionTypeEnum.Snowflake),
              })}
            />
          </div>
        </div>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.canSubmit, state.isPristine]}>
        {([canSubmit, isPristine]) => (
          <StepButtons
            context={context}
            isSubmitting={getTablesMutation.isPending}
            nextBlocked={getTablesMutation.isPending || !canSubmit || isPristine}
            hasCancel={true}
            onCancel={() => navigate('../', { relative: 'path' })}
            onNext={() => getTablesMutation.mutate()}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
