import { TableColumnInput } from '@vendia/management-api-types'
import { useEffect, useState } from 'react'
import CheckboxField from 'src/components/fields/checkbox.field'
import { VendiaFlowFormApi } from 'src/components/flows/types'
import Icon from 'src/components/icons/icon'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'

export const ColumnSelector = ({
  side, // left or right side table. this is temporary until we use the real sql ast
  tableName,
  availableColumns = [],
  selectedColumns = [],
  onChange,
  disabled = false,
  form,
  onColumnSelect,
}: {
  side: 'left' | 'right'
  tableName: string
  availableColumns?: TableColumnInput[]
  selectedColumns?: TableColumnInput[]
  onChange: (columns: TableColumnInput[]) => void
  disabled?: boolean
  form: VendiaFlowFormApi<LakehouseFlowStepValues>
  onColumnSelect?: (column: TableColumnInput) => void
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [highlightedColumn, setHighlightedColumn] = useState<TableColumnInput | null>(null)

  useEffect(() => {
    const validSelectedColumns = selectedColumns.filter((col: TableColumnInput) =>
      availableColumns.some((availCol) => availCol.name === col.name),
    )

    if (validSelectedColumns.length !== selectedColumns.length) {
      form.setFieldValue(`product.queryDefinition.${side}Table.selectedColumns`, validSelectedColumns)
      onChange(validSelectedColumns)
    }
  }, [availableColumns, side, form, onChange, selectedColumns])

  // reset highlighted column when search query changes
  useEffect(() => {
    setHighlightedColumn(null)
  }, [searchQuery])

  // the columns that match the search query
  const filteredColumns = availableColumns.filter((col: TableColumnInput) =>
    col.name.toLowerCase().includes(searchQuery.toLowerCase()),
  )

  const formatTypeForDisplay = (type: string) => {
    if (!type) return 'unknown'
    return (
      type
        // match any type followed by parentheses with numbers and commas
        // e.g. "VARCHAR(10)" -> "varchar"
        .replace(/(\w+)\(\d+(?:,\d+)*\)/i, '$1')
        .toLowerCase()
    )
  }

  const keyColumnsList = availableColumns.filter((col: TableColumnInput) => col.primaryKey)
  const columnsList = filteredColumns.filter((col: TableColumnInput) => !keyColumnsList.includes(col))

  // Column selection handlers
  const selectAll = () => {
    const columnsToSelect = searchQuery ? filteredColumns : availableColumns
    form.setFieldValue(`product.queryDefinition.${side}Table.selectedColumns`, columnsToSelect)
    onChange(columnsToSelect)
  }
  const selectNone = () => {
    form.setFieldValue(`product.queryDefinition.${side}Table.selectedColumns`, [])
    onChange([])
  }
  const selectKeyColumnsOnly = () => {
    const keysToSelect = searchQuery
      ? filteredColumns.filter((col: TableColumnInput) => col.primaryKey)
      : availableColumns.filter((col: TableColumnInput) => col.primaryKey)
    form.setFieldValue(`product.queryDefinition.${side}Table.selectedColumns`, keysToSelect)
    onChange(keysToSelect)
  }
  const toggleColumnSelection = (column: TableColumnInput) => {
    const isSelected = selectedColumns.some((col: TableColumnInput) => col.name === column.name)
    const newSelection = isSelected
      ? selectedColumns.filter((col: TableColumnInput) => col.name !== column.name)
      : [...selectedColumns, column]

    form.setFieldValue(`product.queryDefinition.${side}Table.selectedColumns`, newSelection)
    onChange(newSelection)
  }

  // If alt+click, add to join condition, if regular click, toggle selection or use onColumnSelect if provided
  // If custom handlers are provided, return early and let them handle the click
  const handleColumnClick = (column: TableColumnInput, event: React.MouseEvent) => {
    if (onColumnSelect) {
      onColumnSelect(column)
      return
    }
    toggleColumnSelection(column)
  }

  // key nav in the column list
  const handleKeyNav = (e: React.KeyboardEvent) => {
    if (filteredColumns.length === 0) return

    if (e.key === 'ArrowDown') {
      e.preventDefault()
      if (highlightedColumn === null) {
        setHighlightedColumn(filteredColumns[0])
      } else {
        const currentIndex = filteredColumns.indexOf(highlightedColumn)
        if (currentIndex < filteredColumns.length - 1) {
          setHighlightedColumn(filteredColumns[currentIndex + 1])
        }
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      if (highlightedColumn === null) {
        setHighlightedColumn(filteredColumns[filteredColumns.length - 1])
      } else {
        const currentIndex = filteredColumns.indexOf(highlightedColumn)
        if (currentIndex > 0) {
          setHighlightedColumn(filteredColumns[currentIndex - 1])
        }
      }
    } else if (e.key === 'Enter' && highlightedColumn) {
      e.preventDefault()
      toggleColumnSelection(highlightedColumn)
    }
  }

  return (
    <div>
      <div className='mb-3'>
        <h3 className='text-dark mb-1 truncate text-sm font-bold' title={tableName}>
          {tableName}
        </h3>
        <div className='flex items-center justify-between text-xs'>
          <div className='flex gap-2'>
            <span>Select</span>
            <button
              type='button'
              onClick={selectAll}
              disabled={disabled}
              className='disabled:text-light text-link p-0 text-xs hover:underline'
            >
              all
            </button>
            <button
              type='button'
              onClick={selectNone}
              disabled={disabled}
              className='disabled:text-light text-link p-0 text-xs hover:underline'
            >
              none
            </button>
            {keyColumnsList.length > 0 && (
              <>
                <button
                  type='button'
                  onClick={selectKeyColumnsOnly}
                  disabled={disabled}
                  className='disabled:text-light text-link p-0 text-xs hover:underline'
                >
                  keys
                </button>
              </>
            )}
          </div>

          <div className='text-light text-right text-xs'>
            {selectedColumns.length} of {availableColumns.length} selected
          </div>
        </div>
      </div>

      {/* Search input */}
      <div className='relative mb-3'>
        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
          <Icon name='search' size={16} className='text-light' />
        </div>
        <input
          type='text'
          className='block w-full rounded-md border border-slate-300 py-2 pl-10 pr-3 text-sm placeholder-slate-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500'
          placeholder='Search columns...'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyNav}
          disabled={disabled}
        />
        {searchQuery && (
          <button
            type='button'
            className='absolute inset-y-0 right-0 flex items-center pr-3'
            onClick={() => setSearchQuery('')}
          >
            <Icon name='x' size={16} className='text-light hover:text-dark' />
          </button>
        )}
      </div>

      {/* Column list with checkboxes */}
      {availableColumns.length > 0 ? (
        <div className='relative'>
          {filteredColumns.length > 0 ? (
            <div className='-mx-2'>
              {/* Key columns section */}
              {keyColumnsList.length > 0 && (
                <>
                  <div className='text-light mb-1 text-xs font-medium'>PRIMARY KEYS</div>
                  <div className='mb-3'>
                    {keyColumnsList.map((column) => (
                      <ColumnItem
                        key={column.name}
                        column={column}
                        side={side}
                        isSelected={selectedColumns.some((col) => col.name === column.name)}
                        isHighlighted={highlightedColumn === column}
                        isPrimaryKey={true}
                        disabled={disabled}
                        toggleColumnSelection={toggleColumnSelection}
                        handleColumnClick={handleColumnClick}
                        formatTypeForDisplay={formatTypeForDisplay}
                        form={form}
                      />
                    ))}
                  </div>
                </>
              )}

              {/* Regular columns section */}
              {columnsList.length > 0 && (
                <>
                  {keyColumnsList.length > 0 && <div className='text-light mb-1 text-xs font-medium'>COLUMNS</div>}
                  <div>
                    {columnsList.map((column) => (
                      <ColumnItem
                        key={column.name}
                        column={column}
                        side={side}
                        isSelected={selectedColumns.some((col) => col.name === column.name)}
                        isHighlighted={highlightedColumn === column}
                        isPrimaryKey={false}
                        disabled={disabled}
                        toggleColumnSelection={toggleColumnSelection}
                        handleColumnClick={handleColumnClick}
                        formatTypeForDisplay={formatTypeForDisplay}
                        form={form}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          ) : searchQuery ? (
            <div className='text-light flex items-center justify-center p-4'>
              <div className='text-center text-sm'>
                <p>No columns match your search</p>
                <button className='text-link mt-2 text-sm hover:underline' onClick={() => setSearchQuery('')}>
                  Clear search
                </button>
              </div>
            </div>
          ) : (
            <div className='text-light flex min-h-[200px] items-center justify-center'>
              <p>No columns available</p>
            </div>
          )}
        </div>
      ) : (
        <div className='text-light py-4 text-center'>No columns available</div>
      )}
    </div>
  )
}

// Column item component to reduce repetition
const ColumnItem = ({
  column,
  side,
  isSelected,
  isHighlighted,
  isPrimaryKey,
  disabled,
  toggleColumnSelection,
  handleColumnClick,
  formatTypeForDisplay,
  form,
}: {
  column: TableColumnInput
  side: 'left' | 'right'
  isSelected: boolean
  isHighlighted: boolean
  isPrimaryKey: boolean
  disabled: boolean
  toggleColumnSelection: (column: TableColumnInput) => void
  handleColumnClick: (column: TableColumnInput, event: React.MouseEvent) => void
  formatTypeForDisplay: (type: string) => string
  form: VendiaFlowFormApi<LakehouseFlowStepValues>
}) => {
  return (
    <div
      key={column.name}
      className={`group flex items-center justify-between rounded-md px-2 py-1.5 ${
        disabled ? 'opacity-60' : 'cursor-pointer hover:bg-blue-50'
      } ${isHighlighted ? 'bg-blue-50' : ''}`}
      onClick={(e) => !disabled && handleColumnClick(column, e)}
    >
      <div>
        <CheckboxField
          name={`${side}-column-${column.name}`}
          checked={isSelected}
          onChange={() => !disabled && toggleColumnSelection(column)}
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          disabled={disabled}
          form={form}
          label={
            <div>
              {isPrimaryKey && <Icon name='key' size={14} className='mr-2 text-yellow-600' />}
              {column.name}
            </div>
          }
        />
      </div>
      <span className='text-light font-mono text-xs'>{formatTypeForDisplay(column.type)}</span>
    </div>
  )
}
