import { RadioGroup } from '@headlessui/react'
import { FieldApi, useField } from '@tanstack/react-form'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Button from 'src/components/buttons/button'
import Card from 'src/components/containers/card'
import ListboxField from 'src/components/fields/listbox.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import RadioOption from 'src/components/inputs/radio-option'
import Tooltip from 'src/components/messages/tooltip'
import { isRequiredValidator } from 'src/utils/form/validation'
import { getColumnOptions } from 'src/utils/lakehouse/flows'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'

import { StepId } from '../config'

const TIME_COLUMN_TYPES = ['DATE', 'DATETIME', 'TIME', 'TIMESTAMP', 'TIMESTAMP_LTZ', 'TIMESTAMP_NTZ', 'TIMESTAMP_TZ']

export const StepSelectUpdateColumns: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const navigate = useNavigate()

  const form = context.form
  const { selectedTableColumns, keyColumns, useIncrementalUpdates, uniName, nodeName } = form.useStore(
    (state) => state.values,
  )
  const values = form.useStore((state) => state.values)

  const columnOptions = getColumnOptions(selectedTableColumns)
  const timeColumns = columnOptions.filter((column) =>
    TIME_COLUMN_TYPES.includes(selectedTableColumns.find((c) => c.name === column.value)?.type || ''),
  )
  const updateChoices = [
    {
      value: 'columnUpdate',
      label: 'Incremental Updates (recommended)',
      description: 'Update only data that has changed since last sync. Updates are faster and use less resources.',
      tooltip:
        'Ensure that your table has columns defining when a record was last updated and a unique ID for that record. This is often called "change tracking" within your database platform. Vendia uses the timestamp column to keep track of new or updated rows since the previous sync. The key column(s) are used to merge updated row changes into your existing data set in Vendia.',
    },
    {
      value: 'noUpdate',
      label: 'Full Updates',
      description: 'Update all data every sync. Updates may be slower and use more resources.',
      tooltip:
        'If your data does not need frequent updates or you do not have a "last updated" timestamp or unique ID column, then Vendia will update all records when data is synced.',
    },
  ]

  const resetFieldMeta = () => {
    // @ts-ignore - errorMap is not in the types
    form.setFieldMeta('lastUpdatedColumn', {
      ...form.getFieldMeta('lastUpdatedColumn'),
      errorMap: { onChange: undefined },
    })
    // @ts-ignore - specific key column index is not included in flow state
    form.setFieldMeta('keyColumns.0', {
      // @ts-ignore - specific key column index is not included in flow state
      ...form.getFieldMeta('keyColumns.0'),
      errorMap: { onChange: undefined },
    })
  }
  // something is causing the two listbox fields to gain and then lose focus during the initial render,
  // which causes the validation to immediately kick off. Real fix would be to track down the cause during
  // the render cycle, but for now just reset the field meta on load.
  useEffect(() => {
    setTimeout(() => {
      resetFieldMeta()
    })
  }, [])

  const [selected, setSelected] = useState(
    useIncrementalUpdates && timeColumns.length > 0 ? updateChoices[0].value : updateChoices[1].value,
  )
  form.setFieldValue('useIncrementalUpdates', selected === updateChoices[0].value)
  const selectionChange = (value: string) => {
    // Keep the selected value in stored at form level so user's choice persists thru step changes
    form.setFieldValue('useIncrementalUpdates', value === updateChoices[0].value)
    setSelected(value)
    form.setFieldValue('lastUpdatedColumn', '')
    form.setFieldValue('keyColumns', [''])
    resetFieldMeta()
  }
  const keyColumnField = useField<any, any>({
    name: 'keyColumns',
    form,
    mode: 'array',
    defaultValue: keyColumns,
  })
  async function addKeyColumn(field: FieldApi<any, any>) {
    await field.validate('change')
    if (field.state.meta.errors?.length === 0) {
      field.pushValue('')
    }
  }
  const clearKeyColumn = (index: number) => {
    keyColumnField.removeValue(index)
  }

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Enable incremental updates'
        description='Optimize the time and cost of continually syncing data updates from your source system'
      />
      <ScrollableStepContent inset>
        <RadioGroup className='w-full max-w-4xl' value={selected} onChange={selectionChange} name='incrementalChoice'>
          <Card className='min-h-64 w-full max-w-4xl rounded-md' contentExpandVertical>
            <div className='space-y-6'>
              <div className='flex items-center gap-4'>
                <div className='flex-grow'>
                  <RadioOption
                    key={JSON.stringify(updateChoices[0])}
                    option={updateChoices[0]}
                    labelClassName='text-lg font-semibold'
                  />
                </div>
                <div>
                  <Tooltip size={18}>
                    <div className='max-w-xl'>
                      <p>{updateChoices[0].tooltip}</p>
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className='space-y-3 rounded-lg border border-solid border-slate-300 bg-slate-100 p-5 shadow-sm'>
                <div>
                  <h4 className='text-sm font-semibold'>Timestamp Column</h4>
                  <p className='text-sm text-slate-600'>
                    Select the timestamp column that changes when a record is updated.
                  </p>
                </div>
                <ListboxField
                  name='lastUpdatedColumn'
                  label='Timestamp column'
                  options={timeColumns}
                  useNestedLabel
                  setWidthAccordingToOptions
                  wrapperClassName='!w-[400px]'
                  className='!w-full'
                  form={form}
                  validators={lakehouseFlowFieldValidator({
                    stepId: StepId.SelectUpdateColumns,
                    validator: isRequiredValidator(null, selected === updateChoices[0].value),
                  })}
                  disabled={selected === updateChoices[1].value}
                />
              </div>
              <div className='space-y-3 rounded-lg border border-solid border-slate-300 bg-slate-100 p-5 shadow-sm'>
                <div>
                  <h4 className='text-sm font-semibold'>Key Column</h4>
                  <p className='text-sm text-slate-600'>
                    Select the key column (unique ID) that defines each record. If you do not have a key column, specify
                    all columns that must be unique per record to create a "composite key" (like email and name).
                  </p>
                </div>
                {keyColumns.map((keyColumn, index) => {
                  const fieldPrefix = `keyColumns.${index}`
                  return (
                    <div className='flex items-center gap-2' key={fieldPrefix}>
                      <ListboxField
                        name={`${fieldPrefix}`}
                        label='Key column'
                        options={columnOptions}
                        useNestedLabel
                        setWidthAccordingToOptions
                        wrapperClassName='!w-[400px]'
                        className='!w-full'
                        form={form}
                        disabled={selected === updateChoices[1].value}
                        validators={lakehouseFlowFieldValidator({
                          stepId: StepId.SelectUpdateColumns,
                          validator: isRequiredValidator(null, selected === updateChoices[0].value && index === 0),
                        })}
                      />
                      {index !== 0 && (
                        <div>
                          <Button kind='icon' icon='trash' onClick={() => clearKeyColumn(index)} />
                        </div>
                      )}
                    </div>
                  )
                })}
                <Button
                  kind={'secondary'}
                  onClick={(e) => {
                    e.preventDefault()
                    addKeyColumn(keyColumnField)
                  }}
                  icon='plus-m'
                  iconSize={14}
                  className='mt-2'
                  disabled={keyColumns.some((keyColumn) => !keyColumn) || selected === updateChoices[1].value}
                >
                  Add key column
                </Button>
              </div>
            </div>
          </Card>
          <Card className='mt-2 min-h-32 w-full max-w-4xl rounded-md' contentExpandVertical>
            <div className='flex items-center gap-4'>
              <div className='flex-grow'>
                <RadioOption
                  key={JSON.stringify(updateChoices[1])}
                  option={updateChoices[1]}
                  labelClassName='text-lg font-semibold'
                />
              </div>
              <div>
                <Tooltip size={18}>
                  <div className='max-w-xl'>
                    <p>{updateChoices[1].tooltip}</p>
                  </div>
                </Tooltip>
              </div>
            </div>
          </Card>
        </RadioGroup>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.canSubmit]}>
        {() => (
          <StepButtons
            context={context}
            isSubmitting={false}
            hasCancel={true}
            onCancel={() => navigate(`/uni/${uniName}/${nodeName}/data`)}
            nextBlocked={false}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
