import { lazy } from 'react'
import { createBrowserRouter, Navigate, redirect } from 'react-router-dom'
import RouteWithApi, { RouteWithSubAPI } from 'src/utils/router/route-with-api'

import App from './app.root'
import { ErrorFallback } from './components/containers/error-fallback'
import UniSettingsLayout, {
  asyncLoader,
  LayoutNoNavbar,
  LayoutWithNavbar,
  LayoutWithNavbarNoUniDropdown,
  OrgsLayout,
  UniSideNavLayout,
} from './layouts/routes.layout'
import { entityViewModes as mode } from './pages/entity-browser/constants'
// Bundle login page with initial app load so there's no loader blip when user switches from signup to login
import Login from './pages/login.page'
import LogoutPage from './pages/logout.page'
import UniCreate from './pages/uni-create/uni-create.page'
import { CreateVendiaTableFlow } from './pages/uni-view/lakehouse/create-vendia-table.flow'
import { PageLakehouseOverview } from './pages/uni-view/lakehouse/lakehouse-overview.page'
import PageLakehouseSettings from './pages/uni-view/lakehouse/lakehouse-settings.page'
import { PageLakehouseTable } from './pages/uni-view/lakehouse/lakehouse-table.page'
import { UpdateVendiaTableFlow } from './pages/uni-view/lakehouse/update-vendia-table.flow'
import { UniRedirect } from './pages/uni-view/uni-redirect'

// Load everything else dynamically to slim down initial bundle size and allow code splitting to be aggressive
const OrgApiCredentials = asyncLoader(lazy(() => import('./pages/org/org-api-credentials.page')))
const OrgMembers = asyncLoader(lazy(() => import('./pages/org/org-members.page')))
const OrgOverview = asyncLoader(lazy(() => import('./pages/org/org-overview.page')))
const OrgProjects = asyncLoader(lazy(() => import('./pages/org/org-projects.page')))
const OrgRoles = asyncLoader(lazy(() => import('./pages/org/org-roles.page')))
const Callback = asyncLoader(lazy(() => import('./pages/callback.page')))
const Dashboard = asyncLoader(lazy(() => import('./pages/dashboard.page')))
const DeployToVendia = asyncLoader(lazy(() => import('./pages/deploy/deploy.page')))
const DeveloperSettings = asyncLoader(lazy(() => import('./pages/developer-settings.page')))
const EntityBrowser = asyncLoader(lazy(() => import('./pages/entity-browser/entity-browser.page')))
const EntityView = asyncLoader(lazy(() => import('./pages/entity-browser/entity-view.page')))
const LoginReset = asyncLoader(lazy(() => import('./pages/login-reset.page')))
const Marketplace = asyncLoader(lazy(() => import('./pages/marketplace.page')))
const OrgApiCredentialsCreate = asyncLoader(lazy(() => import('./pages/org/org-api-credentials-create.page')))
const OrgRoleAssign = asyncLoader(lazy(() => import('./pages/org/org-role-assign.page')))
const OrgRoleDetails = asyncLoader(lazy(() => import('./pages/org/org-role-details.page')))
const OrgRoleEdit = asyncLoader(lazy(() => import('./pages/org/org-role-edit.page')))
const ServiceRequest = asyncLoader(lazy(() => import('./pages/service-request.page')))
const Settings = asyncLoader(lazy(() => import('./pages/settings/settings.page')))
const SettingsChangeEmail = asyncLoader(lazy(() => import('./pages/settings/change-email.page')))
const SettingsChangePassword = asyncLoader(lazy(() => import('./pages/settings/change-password.page')))
const Signup = asyncLoader(lazy(() => import('./pages/signup.page')))
const UniList = asyncLoader(lazy(() => import('./pages/uni-list/uni-list.page')))
const UniJoin = asyncLoader(lazy(() => import('./pages/uni-join.page')))
const TransactionView = asyncLoader(lazy(() => import('./pages/transaction-view.page')))
const Analyze = asyncLoader(lazy(() => import('./pages/uni-view/analyze.page')))
const DataImporter = asyncLoader(lazy(() => import('./pages/data-importer/data-importer.page')))
const EvolveSchema = asyncLoader(lazy(() => import('./pages/uni-view/schema/flow-evolve-schema/evolve-schema.flow')))
const FileView = asyncLoader(lazy(() => import('./pages/file-view/file-view.page')))
const Files = asyncLoader(lazy(() => import('./pages/files/files.page')))
const UniExplorer = asyncLoader(lazy(() => import('./pages/uni-explorer/uni-explorer.page')))
const UniShare = asyncLoader(lazy(() => import('./pages/uni-view/share.page')))
const WorkflowsIframePage = asyncLoader(lazy(() => import('./pages/workflows/iframe.page')))
const WorkflowsPage = asyncLoader(lazy(() => import('./pages/workflows/workflows.page')))
const SmartContractDetails = asyncLoader(lazy(() => import('src/pages/smart-contracts/smart-contract-details.page')))
const SmartContracts = asyncLoader(lazy(() => import('src/pages/smart-contracts/smart-contracts.page')))
const UpsertSmartContract = asyncLoader(lazy(() => import('src/pages/smart-contracts/upsert-smart-contract.page')))
const Schema = asyncLoader(lazy(() => import('src/pages/uni-view/schema/schema.page')))
const AuthSettings = asyncLoader(lazy(() => import('./pages/uni-settings/auth-settings.page')))
const NodeResources = asyncLoader(lazy(() => import('./pages/uni-settings/node-resources.page')))
const DangerZone = asyncLoader(lazy(() => import('./pages/uni-settings/danger-zone.page')))
const ManageNode = asyncLoader(lazy(() => import('./pages/uni-settings/notifications.page')))
const UniSpecificSettings = asyncLoader(lazy(() => import('./pages/uni-settings/uni-specific-settings.page')))
const LakehouseJoin = asyncLoader(lazy(() => import('./pages/lakehouse-join/lakehouse-join.flow')))
const ConnectionManagement = asyncLoader(lazy(() => import('./pages/uni-settings/connection-management.page')))
const Onboarding = asyncLoader(lazy(() => import('./pages/onboarding.page')))

export const authRoutes = ['/login', '/login/reset', '/signup', '/callback', '/logout']

export const routeConfig = [
  {
    element: <LayoutNoNavbar />,
    children: [
      { path: '/login', element: <Login /> },
      { path: '/login/reset', element: <LoginReset /> },
      { path: '/signup', element: <Signup /> },
      { path: '/callback', element: <Callback /> },
      { path: '/logout', element: <LogoutPage /> },
    ],
  },

  /*
    Force user to verify email to continue to any other screen
    We also check if the org is loaded to avoid a flash of the verify email screen
    for SSO users whose UserAttributes.email_verified remains set to false forever
    (See corresponding band-aid in App.tsx where we set emailVerifed to true for SSO users)
  */
  { element: <LayoutWithNavbar />, children: [{ path: '/verify-email', element: <SettingsChangeEmail /> }] },

  // Onboarding while full self-signup is prevented
  { element: <LayoutWithNavbarNoUniDropdown />, children: [{ path: '/onboarding', element: <Onboarding /> }] },

  // Special case for joining a uni to stop redirect from the uni dropdown fetch
  { element: <LayoutWithNavbarNoUniDropdown />, children: [{ path: '/uni/:id/join', element: <UniJoin /> }] },

  // App routes
  {
    element: <LayoutWithNavbar />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: '/developer', element: <DeveloperSettings /> },
      { path: '/deploy', element: <DeployToVendia /> },

      // Temp service request
      { path: '/service-request', element: <ServiceRequest /> },

      // Settings
      { path: '/settings', element: <Settings /> },
      { path: '/settings/password', element: <SettingsChangePassword /> },
      { path: '/settings/email', element: <SettingsChangeEmail /> },

      // Organization
      {
        path: '/org',
        element: <OrgsLayout />,
        children: [
          { path: 'overview', element: <OrgOverview /> },
          { path: 'members', element: <OrgMembers /> },
          { path: 'roles', element: <OrgRoles /> },
          { path: 'projects', element: <OrgProjects /> },
          { path: 'api-credentials', element: <OrgApiCredentials /> },
        ],
      },
      { path: '/org/roles/create', element: <OrgRoleEdit /> },
      { path: '/org/roles/:roleId', element: <OrgRoleDetails /> },
      { path: '/org/roles/:roleId/edit', element: <OrgRoleEdit /> },
      { path: '/org/roles/:roleId/assign', element: <OrgRoleAssign /> },
      { path: '/org/api-credentials/create', element: <OrgApiCredentialsCreate /> },

      // AWS Marketplace
      { path: '/marketplace', element: <Marketplace /> },

      { path: '/create-uni', element: <UniCreate /> },

      // TODO: There's a bug with nested layouts that causes the buttons to render off the screen.
      // So, pulling this out to the top level for now.
      { path: '/uni/:id/lakehouse-join', element: <LakehouseJoin /> },
      // Uni Routes
      { path: '/uni', element: <UniList /> },

      // Uni Routes w/ UniSideNav
      {
        path: '/uni/:id',
        element: <UniSideNavLayout />,
        children: [
          {
            path: ':node',
            children: [
              { index: true, loader: () => redirect('entities') },
              { path: 'analyze', element: <RouteWithApi component={Analyze} /> },
              {
                path: 'blocks/:blockId/transactions/:transactionId',
                element: <RouteWithApi component={TransactionView} />,
              },
              { path: 'share', element: <RouteWithApi component={UniShare} /> },
              { path: 'workflows', element: <RouteWithApi component={WorkflowsPage} /> },
              { path: 'entities', element: <RouteWithSubAPI component={EntityBrowser} /> },
              {
                path: 'entities/:entity',
                children: [
                  { index: true, element: <RouteWithSubAPI component={EntityBrowser} /> },
                  { path: 'create', element: <RouteWithSubAPI component={EntityView} mode={mode.CREATE} /> },
                  { path: 'edit', element: <RouteWithSubAPI component={EntityView} mode={mode.EDIT} /> },
                  { path: ':entityId', element: <RouteWithSubAPI component={EntityView} mode={mode.READ} /> },
                  {
                    path: ':entityId/edit',
                    element: <RouteWithSubAPI component={EntityView} mode={mode.EDIT} />,
                  },
                ],
              },
              { path: 'files', element: <RouteWithSubAPI component={Files} /> },
              { path: 'files/:fileId', element: <RouteWithSubAPI component={FileView} /> },
              {
                // analytical uni routes - using 'data' as the base path for now
                path: 'data',
                children: [
                  { index: true, element: <RouteWithApi component={PageLakehouseOverview} /> },
                  { path: 'settings', element: <RouteWithApi component={PageLakehouseSettings} /> },
                  { path: 'tables/:table', element: <RouteWithApi component={PageLakehouseTable} /> },
                  { path: 'connections', element: <RouteWithApi component={ConnectionManagement} /> },
                ],
              },
            ],
          },
        ],
      },

      // Uni Routes without UniSideNav
      {
        element: <LayoutNoNavbar />,
        children: [
          {
            path: '/uni/:id',
            children: [
              // Handle anything that lands directly on the /uni/some-uni route (redirect to data distro, etc)
              { index: true, element: <UniRedirect /> },

              // node routes
              {
                path: ':node',
                children: [
                  { path: 'settings/schema/evolve', element: <EvolveSchema /> },
                  {
                    path: 'settings',
                    element: <UniSettingsLayout />,
                    children: [
                      { path: 'uni-settings', element: <UniSpecificSettings /> },
                      { path: 'resources', element: <RouteWithApi component={NodeResources} /> },
                      { path: 'success-notifications', element: <RouteWithApi component={ManageNode} /> },
                      { path: 'error-notifications', element: <RouteWithApi component={ManageNode} /> },
                      { path: 'danger-zone', element: <RouteWithApi component={DangerZone} /> },
                      { path: 'auth-settings', element: <RouteWithApi component={AuthSettings} /> },
                      { path: 'schema', element: <RouteWithApi component={Schema} /> },
                      {
                        path: 'smart-contracts',
                        children: [
                          { index: true, element: <RouteWithSubAPI component={SmartContracts} /> },
                          { path: 'create', element: <RouteWithSubAPI component={UpsertSmartContract} /> },
                          {
                            path: ':smartContractId',
                            element: <RouteWithSubAPI component={SmartContractDetails} />,
                          },
                          {
                            path: ':smartContractId/edit',
                            element: <RouteWithSubAPI component={UpsertSmartContract} />,
                          },
                          {
                            path: ':smartContractId/:smartContractVersionOrdinal',
                            element: <RouteWithSubAPI component={SmartContractDetails} />,
                          },
                        ],
                      },
                      { index: true, loader: () => redirect('resources') },
                    ],
                  },
                  { path: 'data-importer', element: <RouteWithSubAPI component={DataImporter} /> },
                  { path: 'explorer', element: <RouteWithSubAPI component={UniExplorer} /> },
                  {
                    path: 'workflows',
                    children: [
                      { path: 'recipes/:recipeId/*', element: <RouteWithApi component={WorkflowsIframePage} /> },
                      {
                        path: 'connections/:connectionId/*',
                        element: <RouteWithApi component={WorkflowsIframePage} />,
                      },
                    ],
                  },
                  // analytical uni flows
                  { path: 'data/add-table', element: <RouteWithApi component={CreateVendiaTableFlow} /> },
                  {
                    path: 'data/update-table/:tableId',
                    element: <RouteWithApi component={UpdateVendiaTableFlow} />,
                  },
                ],
              },
            ],
          },
        ],
      },

      // redirects
      { path: '/.well-known/change-password', element: <Navigate to='/settings/password' /> },
    ],
  },

  // entity explorer alternate demo "app" routes
  { path: '/uni/:id/:node/app', element: <RouteWithSubAPI component={EntityBrowser} appMode='demo' /> },
  {
    path: '/uni/:id/:node/app/:entity',
    element: <RouteWithSubAPI component={EntityBrowser} appMode='demo' />,
  },
  {
    path: '/uni/:id/:node/app/:entity/:entityId',
    element: <RouteWithSubAPI component={EntityView} mode={mode.READ} appMode='demo' />,
  },
  {
    path: '/uni/:id/:node/app/:entity/edit',
    element: <RouteWithSubAPI component={EntityView} mode={mode.EDIT} appMode='demo' />,
  },
  {
    path: '/uni/:id/:node/app/:entity/create',
    element: <RouteWithSubAPI component={EntityView} mode={mode.CREATE} appMode='demo' />,
  },

  // finally, catch all unmatched routes
  { path: '*', loader: () => redirect('/') },
]

export default function getAppRouter() {
  return createBrowserRouter([
    { id: 'root', path: '/', element: <App />, children: routeConfig, errorElement: <ErrorFallback /> },
  ])
}
