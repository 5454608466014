'use client'

import * as ResizablePrimitive from 'react-resizable-panels'
import { mergeClassNames } from 'src/utils/tw'

import Icon from '../icons/icon'

const ResizablePanelGroup = ({ className, ...props }: React.ComponentProps<typeof ResizablePrimitive.PanelGroup>) => (
  <ResizablePrimitive.PanelGroup
    className={mergeClassNames('flex h-full w-full data-[panel-group-direction=vertical]:flex-col', className)}
    {...props}
  />
)

const ResizablePanel = ResizablePrimitive.Panel

const ResizableHandle = ({
  withHandle,
  className,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelResizeHandle> & {
  withHandle?: boolean
}) => (
  <ResizablePrimitive.PanelResizeHandle
    className={mergeClassNames(
      // layout and style
      'focus-visible:ring-ring relative flex w-px items-center justify-center',
      // position
      'after:absolute after:inset-y-0 after:left-1/2 after:w-1 after:-translate-x-1/2',
      // focus
      'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-1',
      // vertical layout
      'data-[panel-group-direction=vertical]:h-px data-[panel-group-direction=vertical]:w-full',
      // vertical position
      'data-[panel-group-direction=vertical]:after:left-0 data-[panel-group-direction=vertical]:after:h-1',
      'data-[panel-group-direction=vertical]:after:w-full data-[panel-group-direction=vertical]:after:-translate-y-1/2',
      // vertical rotation
      'data-[panel-group-direction=vertical]:after:translate-x-0 [&[data-panel-group-direction=vertical]>div]:rotate-90',
      className,
    )}
    {...props}
  >
    {withHandle && (
      <div className='z-10 flex h-4 w-3 items-center justify-center rounded-sm bg-[transparent]'>
        {/* <GripVertical /> */}
        <Icon name='grip-vertical' size='xs' className='' />
      </div>
    )}
  </ResizablePrimitive.PanelResizeHandle>
)

export { ResizableHandle, ResizablePanel, ResizablePanelGroup }
