import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import Button from 'src/components/buttons/button'
import Collapsible from 'src/components/containers/collapsible'
import CheckboxField from 'src/components/fields/checkbox.field'
import ListboxField from 'src/components/fields/listbox.field'
import TextField from 'src/components/fields/text.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import { useDevSettings } from 'src/pages/developer-settings.page'
import { createOnChangeValidator, isRequiredOnChange } from 'src/utils/form/validation'
import useGetCurrentVendiaUserQuery from 'src/utils/hooks/use-current-vendia-user-query'
import { useFirstFocus } from 'src/utils/hooks/use-first-focus'
import { useGetOrg } from 'src/utils/hooks/use-get-org'
import { selectedRoleState } from 'src/utils/state'
import { Tier } from 'src/utils/subscription'

import { CreateFlow } from '../config'
import { getUniResourceFromString, namespaceToString, UniResource } from '../uni-resource'
import { buildUniFqn, buildUniNameFromAlias } from '../utils'
import { UniCreateFormValues } from './standard.flow'

export const StepCreateUni: StepComponent<UniCreateFormValues> = ({ context }) => {
  const form = context.form
  const uniNameRef = useFirstFocus()
  const { isLoading, devSettings } = useDevSettings()
  const [, setSearchParams] = useSearchParams({ flow: 'select-type' })
  const navigate = useNavigate()
  const [eligibleNamespaces, setEligibleDomains] = useState<UniResource[]>([])
  const [selectedRole] = useRecoilState(selectedRoleState)
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()
  const getOrg = useGetOrg()
  const subscriptionTier = getOrg?.data?.getOrganization?.subscription?.tier
  const isEnterprise = [Tier.ENTERPRISE, Tier.ENTERPRISE_TRIAL].includes(subscriptionTier as Tier)

  const uniName = form.useStore((state) => state.values.uniName)
  const uniNamespace = form.useStore((state) => state.values.uniNamespace)
  const randomSuffix = form.useStore((state) => state.values.randomSuffix)

  useEffect(() => {
    const role = getCurrentVendiaUserQuery?.data?.getUser?.roles?.find((r) => r.name === selectedRole.name)

    if (!role) {
      getCurrentVendiaUserQuery?.data?.getUser?.roles?.find((r) => r.isDefault || r.name === 'default')
    }

    if (role) {
      const eligibleUniResources: UniResource[] = role.capabilities
        // grab all create capabilities
        .filter((capability) => ['UNI_ALL', 'UNI_CREATE'].includes(capability.action))
        // transform UniResource(*.unis.domain.com#*) into .unis.domain.com
        .map((capability) => {
          return capability.resources
            .filter((resource) => resource.startsWith('UniResource'))
            .map((resource) => resource.replace('UniResource(', ''))
            .map((resource) => resource.replace(')', ''))
        })
        // smoosh arrays
        .flat()
        // remove duplicates
        .reduce((deduplicatedArray, currentItem) => {
          if (deduplicatedArray.includes(currentItem)) {
            return deduplicatedArray
          }
          return deduplicatedArray.concat([currentItem])
        }, [] as string[])
        // sort to ensure that custom namespaces show up first
        .sort((a, b) => {
          if (a.includes('vendia.net')) {
            return 1
          } else if (b.includes('vendia.net')) {
            return -1
          }
          return 0
        })
        .map((resourceString) => getUniResourceFromString(resourceString))
      setEligibleDomains(eligibleUniResources)
    }
  }, [getCurrentVendiaUserQuery?.data?.getUser?.roles, selectedRole.name])

  const hasOnlyAccessToMultiTenantNamespace =
    eligibleNamespaces.length === 1 &&
    eligibleNamespaces[0].subdomain &&
    eligibleNamespaces[0].domain === 'vendia' &&
    eligibleNamespaces[0].ext === 'net'

  const enableFinancialServicesErrorCount = form.useStore(
    (state) => state.fieldMeta.enableFinancialServices?.errors?.length || 0,
  )

  const uniNameFromAlias = buildUniNameFromAlias(uniName ?? '', randomSuffix)

  return (
    <StepWrapper>
      <ScrollableStepContent>
        <div className='flex w-full flex-1 flex-col items-center p-4 lg:px-8'>
          <StepContentHeader
            className='max-w-4xl'
            title='Configure your network'
            description={
              <p>
                Give your network a name based on <span className='font-bold'>who</span> you wish to share data with
                (e.g., "Supply Chain Partner Network") or <span className='font-bold'>the type of data</span> you plan
                to share (e.g., "Loyalty Rewards Program").
              </p>
            }
          />
          <div className='mb-8 flex w-full max-w-4xl flex-col justify-center gap-6'>
            <TextField
              ref={uniNameRef}
              name='uniName'
              label='Network name'
              placeholder='your-vendia-share-uni'
              validators={createOnChangeValidator(
                {
                  // Ensure the name is not just whitespace and is less than 100 characters
                  pattern: /^(?!\s*$).{1,100}$/,
                  message: 'The name must be between 1 and 100 characters long.',
                },
                true,
              )}
              form={form}
            />
            {!hasOnlyAccessToMultiTenantNamespace && (
              <ListboxField
                name='uniNamespace'
                label='Namespace'
                description='Namespace options for this network. Using a custom namespace guarantees single tenancy.'
                options={eligibleNamespaces.map((namespace) => ({
                  label: namespaceToString(namespace),
                  value: namespaceToString(namespace),
                }))}
                form={form}
                useNestedLabel
                validators={isRequiredOnChange}
              />
            )}
            {uniName && uniNamespace ? (
              <div className='text-neutral-11 text-sm'>
                <p>
                  The fully-qualified id of this Uni will be{' '}
                  <span className='font-mono'>{buildUniFqn(uniNameFromAlias, uniNamespace)}</span>.
                </p>
                <p>This identifier must be unique across all Unis and can not be changed.</p>
              </div>
            ) : null}
            {isEnterprise && (
              <>
                <hr className='w-full' />
                <Collapsible
                  title={'Advanced settings'}
                  description={'(optional)'}
                  forceOpen={enableFinancialServicesErrorCount > 0}
                  forceMountContent
                >
                  <div className='grid gap-6'>
                    <div className='grid gap-2'>
                      <div className='mb-4'>
                        <h3 className='mb-2 text-base font-bold'>Financial Services Protections </h3>
                        <p className='text-light mb-1 max-w-lg text-sm'>
                          Enable compliance protections such as Office of Foreign Assets Control (OFAC)-sanctioned
                          country geo-blocking. Please reference your organization's contract for terms and conditions.
                        </p>
                      </div>
                      <CheckboxField
                        name='enableFinancialServices'
                        label={<div className='flex items-center gap-1'>Enable protections</div>}
                        wrapperClassName='!mb-0'
                        form={form}
                      />
                    </div>
                  </div>
                </Collapsible>
              </>
            )}
          </div>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <div className='flex gap-4'>
          {devSettings?.entitlementsUniFlowEnabled ? (
            <Button
              kind='secondary'
              className='w-28'
              onClick={() => setSearchParams({ flow: CreateFlow.SELECT_TYPE })}
              data-testid='back-button'
            >
              Back
            </Button>
          ) : null}
          <Button kind='tertiary' onClick={() => navigate('/')} data-testid='cancel-button'>
            Cancel
          </Button>
        </div>
        <Button kind='primary' className='w-28' onClick={context.goNext} data-testid='next-button'>
          Next
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
