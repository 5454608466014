import { Label, Radio, RadioGroup } from '@headlessui/react'
import { FieldApi } from '@tanstack/react-form'
import clsx from 'clsx'
import { Fragment, ReactNode, useEffect, useState } from 'react'
import type { AccessLevel } from 'src/utils/csv-uploader/csv-uploader'
import { isRequiredOnChange } from 'src/utils/form/validation'
import { GetUniNodes } from 'src/utils/hooks/use-get-uni'

import { VendiaFormApi } from '../fields/form'
import Tooltip from '../messages/tooltip'
import { StickyTh } from './basic-table'
import { ALL_PARTNERS_STAR } from './sharing-rules-table.utils'

type RadioGroupProps = {
  field: FieldApi<any, any>
  label?: string | JSX.Element
  options: {
    value: any
    label: string | JSX.Element
  }[]
  className?: string
  isNested?: boolean
  allPartnersAccessLevel: string
}

const RadioGroupRow = ({ field, label, options, isNested, className, allPartnersAccessLevel }: RadioGroupProps) => {
  const value = field.state.value
  const error = field.state.meta.errors?.at(0)

  function getisDisabled(option: string) {
    if (field.name === `accessLevel.${ALL_PARTNERS_STAR}`) return false
    if (isNested) return false
    if (allPartnersAccessLevel === 'CUSTOM') return false

    const optionsNumericValues: Record<string, number> = {
      NO_ACCESS: 0,
      READ: 1,
      READ_WRITE: 2,
      CUSTOM: 3,
    }

    const optionNumericValue = optionsNumericValues[option]
    const allPartnersAccessLevelNumericValue = optionsNumericValues[allPartnersAccessLevel]

    return optionNumericValue < allPartnersAccessLevelNumericValue
  }

  return (
    <RadioGroup
      defaultValue={value || 'NO_ACCESS'}
      value={value || 'NO_ACCESS'}
      onChange={(value) => {
        return field.handleChange(value)
      }}
      as={Fragment}
    >
      <tr className={clsx(className, error && 'bg-error-0', isNested && 'bg-slate-50')}>
        <td className={clsx('whitespace-nowrap p-4 pl-6 text-sm', isNested && 'pl-8 sm:pl-12')}>
          <Label className='font-bold'>{label}</Label>
          {error && <span className='text-error-8 ml-4 font-bold'>{error}</span>}
        </td>
        {options.map((option) => (
          <Radio
            key={JSON.stringify(option.value)}
            value={option.value}
            as={Fragment}
            disabled={getisDisabled(option.value)}
            aria-label={option.label}
          >
            {({ checked }) => (
              <td className='px-3 py-4'>
                <Label className='cursor-pointer' data-testid={`sharing-option-${option.value}`}>
                  <div className='flex items-center justify-center'>
                    {checked ? (
                      getisDisabled(option.value) ? (
                        <div className='ring-brand size-5 rounded-full opacity-40 ring-8 ring-inset' />
                      ) : (
                        <div className='ring-brand size-5 rounded-full ring-8 ring-inset' />
                      )
                    ) : getisDisabled(option.value) ? (
                      <div className='size-5 rounded-full bg-slate-200 ring-1 ring-inset ring-slate-400' />
                    ) : (
                      <div
                        className={`size-5 rounded-full ring-1 ring-inset ${error ? 'ring-error-8' : 'ring-slate-400'}`}
                      />
                    )}
                  </div>
                  <div className='hidden'>{option.label}</div>
                </Label>
              </td>
            )}
          </Radio>
        ))}
        {isNested && <td />}
      </tr>
    </RadioGroup>
  )
}

interface SharingRulesTableProps {
  form: VendiaFormApi<any>
  partners: (GetUniNodes[number] | { name: string; label: ReactNode })[]
  entityProperties: string[]
  hideCustom?: boolean // TODO: Remove after demo
}

export default function SharingRulesTable({ form, partners, entityProperties, hideCustom }: SharingRulesTableProps) {
  const [isFirstRender, setIsFirstRender] = useState(true)
  const accessLevel = form.useStore((state) => state.values.accessLevel)
  const allPartnersAccessLevel = accessLevel ? accessLevel[ALL_PARTNERS_STAR] : 'NO_ACCESS'

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false)
    } else if (allPartnersAccessLevel !== 'CUSTOM') {
      const newAccessLevel: Record<string, AccessLevel> = {
        [ALL_PARTNERS_STAR]: allPartnersAccessLevel,
      }
      partners.forEach((partner) => {
        newAccessLevel[partner.name] = allPartnersAccessLevel
      })
      form.setFieldValue('accessLevel', newAccessLevel)
    }
  }, [allPartnersAccessLevel])

  const baseRadioOptions = [
    { value: 'NO_ACCESS', label: 'No access' },
    { value: 'READ', label: 'View' },
    { value: 'READ_WRITE', label: 'View/Edit' },
  ]

  const radioOptions = hideCustom ? baseRadioOptions : [...baseRadioOptions, { value: 'CUSTOM', label: 'Custom' }] // TODO: Remove after demo

  const nestedRadioOptions = baseRadioOptions

  return (
    <>
      <div className='grid gap-4'>
        <div className='w-full max-w-7xl overflow-hidden rounded-lg border border-slate-300 bg-slate-100'>
          <div>
            <table className='min-w-full table-auto divide-y divide-slate-200 pb-12'>
              <thead className='bg-slate-50'>
                <tr>
                  <StickyTh isFirstColumn className='w-1/3'>
                    Partner
                  </StickyTh>
                  <StickyTh isCentered>No access</StickyTh>
                  <StickyTh isCentered>View</StickyTh>
                  <StickyTh isCentered>View & edit</StickyTh>
                  {/* TODO: Remove after demo */}
                  {!hideCustom && <StickyTh isCentered>Custom</StickyTh>}
                </tr>
              </thead>
              <tbody className='divide-y divide-slate-200 bg-white'>
                <Fragment key={ALL_PARTNERS_STAR}>
                  <form.Field name={`accessLevel.${ALL_PARTNERS_STAR}`}>
                    {(field: FieldApi<any, any>) => (
                      <RadioGroupRow
                        field={field}
                        label={
                          <>
                            <span className='all-partners-label-text mr-2'>(Default Access)</span>
                            <Tooltip size={14}>
                              <div style={{ width: 360 }}>
                                These permissions will apply to all current and future partners added to the network.
                                Granting permission to all partners does not allow disabling access to individual
                                partners.
                              </div>
                            </Tooltip>
                          </>
                        }
                        options={radioOptions}
                        key={ALL_PARTNERS_STAR}
                        allPartnersAccessLevel={allPartnersAccessLevel}
                      />
                    )}
                  </form.Field>

                  {accessLevel?.[ALL_PARTNERS_STAR] === 'CUSTOM' &&
                    entityProperties.map((entityProperty) => (
                      <form.Field
                        name={`customAccessLevel.${ALL_PARTNERS_STAR}.${entityProperty}`}
                        key={`${ALL_PARTNERS_STAR}.${entityProperty}`}
                      >
                        {(field) => (
                          <RadioGroupRow
                            field={field}
                            label={entityProperty}
                            options={nestedRadioOptions}
                            key={`${ALL_PARTNERS_STAR}.${entityProperty}`}
                            allPartnersAccessLevel={allPartnersAccessLevel}
                          />
                        )}
                      </form.Field>
                    ))}
                </Fragment>

                {partners.map((partner) => (
                  <Fragment key={partner.name}>
                    <form.Field name={`accessLevel.${partner.name}`} key={partner.name} validators={isRequiredOnChange}>
                      {(field) => (
                        <RadioGroupRow
                          field={field}
                          label={partner.name}
                          options={radioOptions}
                          allPartnersAccessLevel={allPartnersAccessLevel}
                        />
                      )}
                    </form.Field>
                    {accessLevel?.[partner.name] === 'CUSTOM' && accessLevel?.[ALL_PARTNERS_STAR] !== 'NO_ACCESS' ? (
                      <tr>
                        <td colSpan={5} className='bg-warning-0 p-4 px-24 text-center'>
                          Note: <strong>All partners</strong> access controls that are more permissive than controls set
                          for this node will override these settings. You may wish to reduce{' '}
                          <strong>All partners</strong> access controls (or remove them entirely by setting to{' '}
                          <strong>No Access</strong>) when customizing permissions.
                        </td>
                      </tr>
                    ) : null}
                    {accessLevel?.[partner.name] === 'CUSTOM' &&
                      entityProperties.map((entityProperty) => (
                        <form.Field
                          name={`customAccessLevel.${partner.name}.${entityProperty}`}
                          key={`${partner.name}.${entityProperty}`}
                          validators={isRequiredOnChange}
                        >
                          {(field) => (
                            <RadioGroupRow
                              className='bg-slate-100'
                              isNested
                              label={entityProperty}
                              field={field}
                              options={nestedRadioOptions}
                              allPartnersAccessLevel={allPartnersAccessLevel}
                            />
                          )}
                        </form.Field>
                      ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
