import { Node } from '@vendia/management-api-types'
import type { AccessLevel, Acl, Field, SharingRule } from 'src/utils/csv-uploader/csv-uploader'
import { generateSharingRulesFromAcl } from 'src/utils/csv-uploader/graphql.utils'
import { GetUniNodes } from 'src/utils/hooks/use-get-uni'

// We need to use another value that isn't a valid node name
// We then encode/decode '*' when reading/writing acls
export const ALL_PARTNERS_STAR = '_STAR'

type InitialTableState = {
  accessLevel: Record<string, AccessLevel | 'CUSTOM'>
  customAccessLevel: Record<string, Record<string, AccessLevel>>
}

export const generateInitialTableStateFromAcls = (
  acls: Acl[],
  partners: Node[],
  entityProperties: string[],
): InitialTableState => {
  const sharingRules: SharingRule[] = generateSharingRulesFromAcl({ acl: acls })
  const accessLevel: Record<string, AccessLevel | 'CUSTOM'> = {}
  const customAccessLevel: Record<string, Record<string, AccessLevel>> = {}

  const keys = partners.map((partner) => partner.name).concat('*')

  keys.forEach((key) => {
    const sharingRule = sharingRules.find((sharingRule) => sharingRule.partner === key)

    const mappedKey = key === '*' ? ALL_PARTNERS_STAR : key

    if (sharingRule) {
      if (sharingRule.fields.length === 1 && sharingRule.fields[0].name === '*') {
        accessLevel[mappedKey] = sharingRule.fields[0].accessLevel
      } else {
        accessLevel[mappedKey] = 'CUSTOM'
        customAccessLevel[mappedKey] = {}
        sharingRule.fields.forEach((field) => {
          customAccessLevel[mappedKey][field.name] = field.accessLevel
        })
        entityProperties.forEach((entityProperty) => {
          if (!customAccessLevel[mappedKey][entityProperty]) {
            customAccessLevel[mappedKey][entityProperty] = 'NO_ACCESS'
          }
        })
      }
    } else {
      accessLevel[mappedKey] = 'NO_ACCESS'
      customAccessLevel[mappedKey] = {}
    }
  })

  return {
    accessLevel,
    customAccessLevel,
  }
}

export const generateSharingRule = ({
  partnerName,
  rootAccessLevel,
  customAccessLevel,
}: {
  partnerName: string
  rootAccessLevel: AccessLevel | 'CUSTOM'
  customAccessLevel?: Record<string, AccessLevel>
}): SharingRule => {
  const finalPartnerName = partnerName === ALL_PARTNERS_STAR ? '*' : partnerName
  if (rootAccessLevel === 'CUSTOM') {
    const fields: Field[] = Object.entries(customAccessLevel!).map(([fieldName, accessLevel]) => ({
      name: fieldName,
      accessLevel,
    }))

    return {
      partner: finalPartnerName,
      fields: [...fields],
    }
  }
  return {
    partner: finalPartnerName,
    fields: [
      {
        name: '*',
        accessLevel: rootAccessLevel,
      },
    ],
  }
}

interface GetDefaultAccessLevelsParams {
  partners?: GetUniNodes
  entityProperties: string[]
  isNewRecord: boolean
}

export function getDefaultAccessLevels({ partners = [], entityProperties, isNewRecord }: GetDefaultAccessLevelsParams) {
  const defaultAccessLevel = isNewRecord ? 'READ_WRITE' : 'NO_ACCESS'
  const defaultAccessLevels: Record<string, Record<string, any>> = {
    accessLevel: {},
    customAccessLevel: {},
  }

  const partnersWithAll = [{ name: ALL_PARTNERS_STAR }, ...partners]

  partnersWithAll.forEach((partner) => {
    // NOTE: If we default to anything other than NO_ACCESS, we'll need to fix a bug where
    // if a Node was set to "No Access", on the next edit page load it gets set to whatever
    // the default is. That's because in getInitialAccessLevels there is no value in the
    // existing acl to override the default.
    defaultAccessLevels.accessLevel[partner.name] = defaultAccessLevel
    defaultAccessLevels.customAccessLevel[partner.name] = {}
    entityProperties.forEach((entityProperty) => {
      defaultAccessLevels.customAccessLevel[partner.name][entityProperty] = defaultAccessLevel
    })
  })

  return defaultAccessLevels
}

interface EntityInstance {
  _acl?: Acl[]
}

interface GetInitialAccessLevelsParams {
  entityInstance?: EntityInstance
  partners?: GetUniNodes
  entityProperties: string[]
  isNewRecord: boolean
}

export function getInitialAccessLevels({
  entityInstance,
  partners,
  entityProperties,
  isNewRecord,
}: GetInitialAccessLevelsParams) {
  const initialAccessLevels = getDefaultAccessLevels({ partners, entityProperties, isNewRecord })

  if (!entityInstance || !entityInstance._acl) return initialAccessLevels

  const acl = entityInstance._acl

  acl.forEach((a) => {
    a.principal.nodes.forEach((n) => {
      const partner = n === '*' ? ALL_PARTNERS_STAR : n
      if (!a.path) {
        if (a.operations?.[0] === 'ALL') {
          initialAccessLevels.accessLevel[partner] = 'READ_WRITE'
        } else if (a.operations?.[0] === 'READ') {
          initialAccessLevels.accessLevel[partner] = 'READ'
        }
      } else {
        initialAccessLevels.accessLevel[partner] = 'CUSTOM'
        if (a.operations?.[0] === 'ALL') {
          initialAccessLevels.customAccessLevel[partner][a.path] = 'READ_WRITE'
        } else if (a.operations?.[0] === 'READ') {
          initialAccessLevels.customAccessLevel[partner][a.path] = 'READ'
        }
      }
    })
  })

  return initialAccessLevels
}
