import { TableColumnInput } from '@vendia/management-api-types'
import { FlowContext } from 'src/components/flows/types'
import { Switch } from 'src/components/switch'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'

import { ColumnSelector } from './join-column-selector'

export const JoinColumnSection = ({
  context,
  getTableColumns,
  returnLabel,
}: {
  context: FlowContext<LakehouseFlowStepValues>
  getTableColumns: (tableName: string) => TableColumnInput[]
  returnLabel: string
}) => {
  const form = context.form
  const product = form.useStore((s) => s.values.product)

  const handleColumnSelectionChange = (side: 'left' | 'right', selectedColumns: TableColumnInput[]) => {
    if (!product?.queryDefinition) return

    const currentTable = product.queryDefinition[`${side}Table`]
    const updatedTable = {
      ...currentTable,
      selectedColumns,
    }
    form.setFieldValue(`product.queryDefinition.${side}Table`, updatedTable)
  }

  const handleReturnAllColumnsChange = (checked: boolean) => {
    form.setFieldValue('product.queryDefinition.returnAllColumns', checked)

    if (checked) {
      if (product?.queryDefinition?.leftTable?.table) {
        const currentLeftTable = product.queryDefinition.leftTable
        form.setFieldValue('product.queryDefinition.leftTable', {
          ...currentLeftTable,
          selectedColumns: [],
        })
      }

      if (product?.queryDefinition?.rightTable?.table) {
        const currentRightTable = product.queryDefinition.rightTable
        form.setFieldValue('product.queryDefinition.rightTable', {
          ...currentRightTable,
          selectedColumns: [],
        })
      }
    }
  }

  return (
    <>
      <div className='flex items-start justify-between gap-8'>
        <div>
          <h2 className='text-dark text-lg font-bold'>Column Results</h2>
          <p className='text-light text-sm'>
            <span>Choose columns to include in results.</span>
          </p>
        </div>
        <div className='flex items-center gap-4 pt-4'>
          <span className={`text-light text-nowrap text-sm`}>{returnLabel}</span>
          <Switch
            checked={Boolean(product?.queryDefinition?.returnAllColumns)}
            onCheckedChange={handleReturnAllColumnsChange}
            disabled={!product?.queryDefinition?.leftTable?.table || !product?.queryDefinition?.rightTable?.table}
            data-testid='return-all-columns-toggle'
            useGradient={true}
          />
        </div>
      </div>

      {!product?.queryDefinition?.returnAllColumns && (
        <div className='mt-8 grid w-full grid-cols-[1fr,auto,1fr] gap-4'>
          <div className='min-w-0'>
            {product?.queryDefinition?.leftTable?.table && (
              <ColumnSelector
                side='left'
                tableName={product.queryDefinition?.leftTable?.table}
                availableColumns={getTableColumns(product.queryDefinition?.leftTable?.table)}
                selectedColumns={product.queryDefinition?.leftTable?.selectedColumns}
                onChange={(cols) => {
                  handleColumnSelectionChange('left', cols)
                }}
                disabled={!product?.queryDefinition?.leftTable?.table}
                form={form}
              />
            )}
          </div>
          <div className='w-6'></div>
          <div className='min-w-0'>
            {product?.queryDefinition?.rightTable?.table && (
              <ColumnSelector
                side='right'
                tableName={product.queryDefinition?.rightTable?.table}
                availableColumns={getTableColumns(product.queryDefinition?.rightTable?.table)}
                selectedColumns={product.queryDefinition?.rightTable?.selectedColumns}
                onChange={(cols) => {
                  handleColumnSelectionChange('right', cols)
                }}
                disabled={!product?.queryDefinition?.rightTable?.table}
                form={form}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}
