import { useForm } from '@tanstack/react-form'
import { useMutation } from '@tanstack/react-query'
import { LakehouseSyncScheduleEnum, LakehouseTableInput, Uni } from '@vendia/management-api-types'
import dayjs from 'dayjs'
import { useState } from 'react'
import Button from 'src/components/buttons/button'
import Card, { CardRule } from 'src/components/containers/card'
import ListboxField from 'src/components/fields/listbox.field'
import Alert from 'src/components/messages/alert'
import ConfirmationModal from 'src/components/modals/confirmation-modal'
import { ExecutionStatus, Job } from 'src/pages/uni-view/lakehouse/table-card'
import { useLakehouseJobs } from 'src/pages/uni-view/lakehouse/use-lakehouse-jobs'
import useApi from 'src/utils/hooks/use-api'
import { useGetLakehouseData } from 'src/utils/hooks/use-get-lakehouse-data'
import { getFriendlyJobStatus, getFriendlyScheduleName } from 'src/utils/lakehouse/products'
import notify from 'src/utils/notify'

export const SyncStatusCard = ({
  uni,
  nodeName,
  table,
}: {
  uni: Uni
  nodeName: string
  table: LakehouseTableInput
}) => {
  const [expanded, setExpanded] = useState(false)
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [showSyncModal, setShowSyncModal] = useState(false)
  const api = useApi()

  const lakehouseJobsQuery = useLakehouseJobs({ uniName: uni.name, nodeName: nodeName })
  const { refetch: refetchLakehouseJobs } = lakehouseJobsQuery
  const { refetch: refetchLakehouseData } = useGetLakehouseData()

  const jobs = lakehouseJobsQuery.data?.getLakehouseJobs?.jobs?.[table.key]

  const getUserFriendlyJobStatus = (status: ExecutionStatus): string => {
    return getFriendlyJobStatus[status]
  }

  const JobItem = ({ job }: { job: Job }) => {
    const source = job?.source

    return (
      <div className='flex items-center justify-between'>
        <div>
          {source.status === 'RUNNING' ? (
            <>
              <div className='text-base font-bold'>Started {dayjs(source.startTime).fromNow()}</div>
              <div className='text-xs font-normal'>{dayjs(source.startTime).format('MMMM D, YYYY h:mm A')}</div>
            </>
          ) : (
            <>
              <div className='text-base font-bold'>{dayjs(source.stopTime).fromNow()}</div>
              <div className='text-xs font-normal'>{dayjs(source.stopTime).format('MMMM D, YYYY h:mm A')}</div>
            </>
          )}
        </div>
        <div>
          <div
            className={`text-base font-bold ${
              source?.status === 'RUNNING'
                ? 'text-information-10'
                : source?.status === 'SUCCEEDED'
                  ? 'text-success-13'
                  : getUserFriendlyJobStatus(source?.status) === 'Failure'
                    ? 'text-error-8'
                    : ''
            }`}
          >
            {getUserFriendlyJobStatus(source?.status)}
          </div>
        </div>
      </div>
    )
  }

  const scheduleForm = useForm({
    defaultValues: {
      syncSchedule: table.syncSchedule,
    },
  })

  const updateLakehouseScheduleMutation = useMutation({
    mutationFn: ({
      uniName,
      nodeName,
      tableKey,
      syncSchedule,
    }: {
      uniName: string
      nodeName: string
      tableKey: string
      syncSchedule: LakehouseSyncScheduleEnum
    }) => api.updateLakehouseSchedule({ uniName, nodeName, tableKey, syncSchedule }),

    onError: (error) => notify.error(`Error updating refresh rate: ${error}`),
    onSuccess: (response) => {
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.updateLakehouseSchedule) {
        notify.error(`Error updating refresh rate for: ${table.name} `)
        return
      }

      notify.success(`Successfully updated refresh rate for: ${table.name}`)
      setShowScheduleModal(false)
      refetchLakehouseData()
    },
  })

  const lakehouseIngestionSFNMutation = useMutation({
    mutationFn: ({ uniName, nodeName, tableKey }: { uniName: string; nodeName: string; tableKey: string }) =>
      api.invokeLakehouseIngestionSFN({ uniName, nodeName, tableKey }),

    onError: (error) => notify.error(`Error syncing table: ${error}`),
    onSuccess: (response) => {
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.invokeLakehouseIngestionSFN) {
        notify.error('Error syncing table')
        return
      }
      notify.success(`Successfully initiated sync for: ${table.name}`)
      setShowSyncModal(false)
      refetchLakehouseJobs()
    },
  })

  return (
    <>
      <Card className='w-full' padding='none' overflow='visible'>
        <div>
          <div className='flex items-center justify-between p-6 text-sm font-bold'>
            <h4>Syncing</h4>
            <div className='flex gap-2'>
              <Button
                kind='icon'
                size='small'
                icon={'refresh'}
                label='Sync table'
                onClick={() => setShowSyncModal(true)}
                disabled={jobs?.some((job) => job.source.status === 'RUNNING')}
              />
              <Button
                kind='icon'
                size='small'
                icon={expanded ? 'chevron-up' : 'chevron-down'}
                label='Toggle status details'
                onClick={() => setExpanded(!expanded)}
              />
            </div>
          </div>
          {expanded && (
            <div>
              <CardRule />
              <div className='bg-uibg-0 p-6'>
                <div className='grid space-y-4'>
                  <div className='flex items-center justify-between'>
                    <div>
                      <div className='text-base font-bold'>
                        Frequency:{' '}
                        <span className='font-normal'>{getFriendlyScheduleName[table.syncSchedule].toLowerCase()}</span>
                      </div>
                    </div>
                    <Button
                      kind='secondary'
                      label='Update'
                      onClick={() => setShowScheduleModal(true)}
                      disabled={jobs?.some((job) => job.source.status === 'RUNNING')}
                    />
                  </div>
                  {!jobs ? (
                    <div className='text-gray-500'>Loading...</div>
                  ) : (
                    <ul className='space-y-4'>
                      {jobs.map((job, index) => (
                        <li key={index}>
                          <JobItem job={job} />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
      <ConfirmationModal
        isOpen={showScheduleModal}
        onClose={() => setShowScheduleModal(false)}
        title={`Syncing schedule`}
        onSubmit={() => {
          if (scheduleForm.state.values.syncSchedule === table.syncSchedule) {
            notify.error(
              `${table.name} is already set to refresh: ${getFriendlyScheduleName[table.syncSchedule].toLowerCase()}`,
            )
            return
          }
          updateLakehouseScheduleMutation.mutate({
            uniName: uni.name,
            nodeName: nodeName,
            tableKey: table.key,
            syncSchedule: scheduleForm.state.values.syncSchedule,
          })
        }}
        isSubmitting={updateLakehouseScheduleMutation.isPending}
        actionButtonType={`primary`}
        actionButtonText={`Update`}
        confirmationText={'Update'}
      >
        <ListboxField
          name='syncSchedule'
          label='Frequency (Refresh rate)'
          description='How often should this table be updated?'
          options={[
            { value: LakehouseSyncScheduleEnum.OnDemand, label: 'On-demand' },
            { value: LakehouseSyncScheduleEnum.Daily, label: 'Daily' },
            { value: LakehouseSyncScheduleEnum.Weekly, label: 'Weekly' },
            { value: LakehouseSyncScheduleEnum.Monthly, label: 'Monthly' },
          ]}
          form={scheduleForm}
        />
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={showSyncModal}
        onClose={() => setShowSyncModal(false)}
        title={`Confirm sync for ${table?.name}`}
        onSubmit={() =>
          lakehouseIngestionSFNMutation.mutate({ uniName: uni.name, nodeName: nodeName, tableKey: table.key })
        }
        isSubmitting={lakehouseIngestionSFNMutation.isPending}
        actionButtonType={`primary`}
        actionButtonText={`Sync`}
        confirmationText={'sync'}
      >
        <div className='grid gap-4'>
          <p>
            Immediately begin syncing the latest data from your configured source to <strong>{table.name}</strong>
          </p>
          <Alert className='text-sm italic text-gray-500'>
            Note: Once started, the sync process cannot be cancelled or undone.
          </Alert>
        </div>
      </ConfirmationModal>
    </>
  )
}
