import { LakehouseSyncScheduleEnum } from '@vendia/management-api-types'
import debug from 'debug'
import { useNavigate, useParams } from 'react-router'
import ListboxField from 'src/components/fields/listbox.field'
import TextField from 'src/components/fields/text.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import SharingRulesTable from 'src/components/tables/sharing-rules-table'
import { StepId } from 'src/pages/uni-create/config'
import { isValidTableName } from 'src/utils/form/validation'
import { useFirstFocus } from 'src/utils/hooks/use-first-focus'
import { useGetUni } from 'src/utils/hooks/use-get-uni'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'

const logger = debug('app:table-settings')

export const StepTableSettings: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const tableNameRef = useFirstFocus()
  const navigate = useNavigate()

  const flowType = context.form.useStore((s) => s.values.flowType)
  const existingVendiaTables = context.form.useStore((s) => s.values.existingVendiaTables)

  const getUniQuery = useGetUni()
  const uni = getUniQuery.data?.getUni
  const { id: uniName, node } = useParams()
  const nodes = uni?.nodes?.filter((n) => n.name !== node)

  const isValidNonExistingTableName = ({ value }: { value: string }) => {
    if (existingVendiaTables?.some((table) => table.name === value)) {
      return 'Table name already exists'
    }
    return isValidTableName({ value })
  }

  return (
    <StepWrapper>
      <StepContentHeader
        hasMarginY
        large
        centered
        title='Configure your new Vendia table'
        description={`Configure your new table's name, sync frequency, and access controls`}
      ></StepContentHeader>
      <ScrollableStepContent inset className='pt-8'>
        <div className='flex w-full max-w-4xl flex-col gap-4'>
          <TextField
            name='product.name'
            label='Name'
            description='Provide a SQL compliant name for your table'
            ref={tableNameRef}
            validators={lakehouseFlowFieldValidator({
              stepId: StepId.TableSettings,
              validator: isValidNonExistingTableName,
            })}
            useNestedLabel
            form={context.form}
          />
          <ListboxField
            name='product.syncSchedule'
            label='Frequency (Refresh rate)'
            description='How often should this table be updated?'
            options={[
              { value: LakehouseSyncScheduleEnum.OnDemand, label: 'On-demand' },
              { value: LakehouseSyncScheduleEnum.Daily, label: 'Daily' },
              { value: LakehouseSyncScheduleEnum.Weekly, label: 'Weekly' },
              { value: LakehouseSyncScheduleEnum.Monthly, label: 'Monthly' },
            ]}
            form={context.form}
            useNestedLabel
          />

          <div className='text-neutral-8 -mb-2 text-sm'>Which partners can access this table?</div>
          {uni && nodes && <SharingRulesTable form={context.form} partners={nodes} entityProperties={[]} hideCustom />}
        </div>
      </ScrollableStepContent>
      <context.form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting, state.isPristine]}>
        {([canSubmit, isSubmitting, isPristine]) => (
          <StepButtons
            context={context}
            hasPrevious={flowType === 'addTable'}
            hasCancel={true}
            onCancel={() => navigate(`/uni/${uniName}/${node}/data`)}
            isSubmitting={isSubmitting}
            nextBlocked={!canSubmit}
          />
        )}
      </context.form.Subscribe>
    </StepWrapper>
  )
}
