import { Uni, VendiaTableDetails } from '@vendia/management-api-types'
import { useState } from 'react'
import Button from 'src/components/buttons/button'
import Card, { CardRule } from 'src/components/containers/card'
import { BasicTable } from 'src/components/tables/basic-table'
import { formatBytes } from 'src/utils/format-bytes'
import { useGetVendiaTableDetails } from 'src/utils/hooks/use-get-vendia-table-details-query'

export const TableDetailsCard = ({ uni, nodeName, tableKey }: { uni: Uni; nodeName: string; tableKey: string }) => {
  const [cardExpanded, setCardExpanded] = useState(false)
  const [schemaVisible, setSchemaVisible] = useState(false)

  const vendiaTableDetailsQuery = useGetVendiaTableDetails({ uniName: uni.name, nodeName, tableKey })
  const vendiaTableDetails = vendiaTableDetailsQuery.data?.getVendiaTableDetails
  const tableSchemaColumnHeaders = Object.keys(vendiaTableDetails?.tableSchema[0] ?? {})
  const tableSchemaRows =
    vendiaTableDetails?.tableSchema.map((row) => {
      return tableSchemaColumnHeaders.map((header) => row[header])
    }) ?? []

  return (
    <>
      <Card className='w-full' padding='none'>
        <div>
          <div className='flex items-center justify-between p-6 text-sm font-bold'>
            <h4>Table Details</h4>
            <Button
              kind='icon'
              size='small'
              icon={cardExpanded ? 'chevron-up' : 'chevron-down'}
              label='Toggle table details'
              onClick={() => setCardExpanded(!cardExpanded)}
            />
          </div>
          {cardExpanded && (
            <>
              <CardRule />
              <div className='bg-uibg-0 p-6'>
                <div className='grid'>
                  {!vendiaTableDetails ? (
                    <div className='text-gray-500'>Loading...</div>
                  ) : (
                    <div className='flex flex-col gap-6'>
                      <div>
                        <strong className='mr-2'>Total Rows:</strong>
                        {vendiaTableDetails.rowCount || '0'}
                      </div>
                      <div>
                        <strong className='mr-2'>Total Data Size:</strong>
                        {formatBytes(parseInt(vendiaTableDetails.totalDataSize || '0'))}
                      </div>
                      <div>
                        <div className='flex items-center gap-2'>
                          <strong>Table Schema</strong>
                          <Button
                            kind='icon'
                            size='small'
                            icon={schemaVisible ? 'chevron-up' : 'chevron-down'}
                            label='Toggle table schema'
                            onClick={() => setSchemaVisible(!schemaVisible)}
                            iconSize={10}
                          />
                        </div>
                        {schemaVisible && (
                          <BasicTable
                            className='mt-2 max-h-96'
                            columnHeaders={tableSchemaColumnHeaders}
                            rows={tableSchemaRows}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Card>
    </>
  )
}
