import { Node, StatusEnum, Uni } from '@vendia/management-api-types'
import Card from 'src/components/containers/card'

const FallingStars = () => {
  const dots = Array(50).fill(null)
  return (
    <div className='relative size-96 overflow-hidden rounded-full'>
      <style>{`
        @keyframes fall {
          0% {
            transform: translateY(-20px);
            opacity: 0;
          }
          80% {
            transform: translateY(-10px);
            opacity: 1;
          }
          100% {
            transform: translateY(280px);
            opacity: 0;
          }
        }
        .animate-fall {
          animation: fall linear infinite;
        }
      `}</style>
      {dots.map((_, index) => (
        <div
          key={index}
          className={`animate-fall absolute size-3 -translate-y-5 rounded-full ${index % 2 === 0 ? 'bg-blue-200' : 'bg-violet-300'}`}
          style={{
            left: `${Math.random() * 100}%`,
            animationDuration: `${3 + Math.random() * 25}s`,
            animationDelay: `${-Math.random() * 10}s`,
          }}
        />
      ))}
    </div>
  )
}

export const UniPendingPlaceholder = ({ uni, node }: { uni?: Uni; node?: Node }) => {
  if (!uni) {
    return null
  }
  return (
    <div className='flex items-center justify-center p-16 pt-12'>
      <Card className='w-full' childrenWrapperClassName='flex justify-center'>
        <div className='max-w-3xl p-20'>
          <div className='relative mb-16 flex w-full justify-center'>
            <FallingStars />
            <img src='/images/rocket.svg' alt='Uni Illustration' className='absolute mt-16' />
          </div>
          {node ? (
            <p>
              The <strong>{node.name}</strong> node is currently{' '}
              {node.status === StatusEnum.Updating ? 'updating' : 'spinning up'} and should complete shortly.
            </p>
          ) : (
            <p>
              The <strong>{uni.name}</strong> application is currently{' '}
              {uni.status === StatusEnum.Updating ? 'updating' : 'spinning up'} and should complete shortly.
            </p>
          )}
          <br />
          <p>
            In the meantime, check out the&nbsp;
            <a
              href='https://docs.vendia.com'
              target='_blank'
              rel='noopener noreferrer'
              className='text-link font-bold no-underline'
            >
              docs
            </a>
            &nbsp;to learn more about what you can do with your new Uni.
          </p>
        </div>
      </Card>
    </div>
  )
}
