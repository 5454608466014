import { DataPolicyColumnEffect } from '@vendia/management-api-types'
import debug from 'debug'
import { useNavigate } from 'react-router'
import Card, { CardRule } from 'src/components/containers/card'
import MenuListboxField from 'src/components/fields/menu-listbox.field'
import RadioGroupField from 'src/components/fields/radio-group.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { EditColumnPolicies } from 'src/components/lakehouse/edit-column-policies'
import { useFirstFocus } from 'src/utils/hooks/use-first-focus'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'

const logger = debug('app:sharingPermissions')

export const StepCreateColumnSharingPermissions: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form
  const navigate = useNavigate()

  const { product, flowSteps, currStepIndex, currStep, nodeName, uniName } = form.useStore((state) => state.values)
  const buttonRef = useFirstFocus<HTMLButtonElement>()

  const columnOptions = [
    {
      label: 'All columns are excluded by default',
      description: 'You must provide rules to add columns to your table.',
      value: DataPolicyColumnEffect.Exclude,
    },
    {
      label: 'All columns are included by default',
      description: 'You can exclude or mask specific columns by adding rules.',
      value: DataPolicyColumnEffect.Allow,
    },
  ]

  logger('product', {
    product,
    flowSteps,
    currStepIndex,
    currStep,
  })

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Define column-level filtering rules'
        description='Configure fine-grained rules to share data securely'
      />
      <ScrollableStepContent inset className='pt-4'>
        <Card className='mx-2 h-full w-full max-w-7xl rounded-md' childrenWrapperClassName='h-full'>
          <div className='mb-4'>
            <div className='text-lg font-bold'>Column permissions</div>
            <div className='text-neutral-9 w-full text-xs'>
              Easily manage data access and ensure compliance with data privacy regulations by setting column-level
              permissions for both internal and external data sharing.
            </div>
          </div>
          <RadioGroupField
            name='product.defaultColumnPolicyEffect'
            options={columnOptions}
            form={form}
            dividerClassName='hidden'
          />
          <CardRule className='-mx-10 my-4' />
          <EditColumnPolicies context={context} policiesForm={context.form} fieldPath='product' />
        </Card>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.canSubmit, state.isPristine]}>
        {([canSubmit, isPristine]) => (
          <StepButtons
            context={context}
            hasCancel={true}
            onCancel={() => navigate(`/uni/${uniName}/${nodeName}/data`)}
            nextButtonRef={buttonRef}
            nextBlocked={!canSubmit || isPristine}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
