// @ts-strict-ignore
import { Dialog } from '@headlessui/react'
import { useForm } from '@tanstack/react-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import debug from 'debug'
import { useMemo, useState } from 'react'
import Button from 'src/components/buttons/button'
import Drawer from 'src/components/containers/drawer'
import Form from 'src/components/fields/form'
import RadioGroupField from 'src/components/fields/radio-group.field'
import TextField from 'src/components/fields/text.field'
import Tooltip from 'src/components/messages/tooltip'
import inputValidation from 'src/utils/form/input.validation'
import { isRequiredOnBlur } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { refetchOrg, useGetOrg } from 'src/utils/hooks/use-get-org'
import { refetchOrgDetails } from 'src/utils/hooks/use-get-org-details'
import notify from 'src/utils/notify'

import { CustomRoleBuilder } from './roles/custom-role-builder'
import { ReadOnlyRoleOverview } from './roles/read-only-role-overview'
import { RoleTemplateRadioGroup } from './roles/role-template-radio-group'
import { CUSTOM_ROLE_TEMPLATE_NAME, DEFAULT_ROLE_TEMPLATE_NAME, getRoleTemplates } from './roles/role-templates'
import { validateCapabilities } from './roles/validation-utils'

export const logger = debug('app:OrgInvite')

interface InviteResponse {
  inviteToOrg: {
    expiration: string
  }
  errors: {
    message: string
  }[]
}

const OrgInvite = () => {
  const getOrg = useGetOrg()
  const org = getOrg?.data?.getOrganization
  const hasNoApprovedDomains = org?.domains?.length === 0
  const orgDomains = org?.domains?.join(', ')
  const queryClient = useQueryClient()
  const api = useApi()
  const [showInvite, setShowInvite] = useState(false)
  const inviteMutation = useMutation({
    mutationFn: ({
      inviteeEmail,
      roleTemplateName,
      customRoleCapabilities,
    }: {
      inviteeEmail: string
      invitePermissionsMode: 'default' | 'custom'
      roleTemplateName: string
      customRoleCapabilities: string
    }) => {
      logger('SUBMIT', inviteeEmail, roleTemplateName)
      // Use server default role if the user selected the default role
      if (invitePermissionsMode === 'default') {
        return api.inviteToOrg({ userId: inviteeEmail, capabilities: null })
      }
      if (invitePermissionsMode === 'custom') {
        return api.inviteToOrg({ userId: inviteeEmail, capabilities: JSON.parse(customRoleCapabilities) })
      }
      // The preview role templates don't have the user's email address in the NameResources
      // Reconstruct now that we have it and match by name...
      const templatesWithInviteesEmail = getRoleTemplates({
        orgId: org!.orgId,
        orgDomains: org!.domains as string[],
      })
      const capabilities = templatesWithInviteesEmail.find((t) => t.name === roleTemplateName)?.capabilities
      return api.inviteToOrg({ userId: inviteeEmail, capabilities })
    },
  })

  const roleTemplates = useMemo(() => {
    if (!org?.orgId || !org?.domains) return []
    return getRoleTemplates({ orgId: org?.orgId, orgDomains: org?.domains })
  }, [org?.orgId, org?.domains])

  const form = useForm<any>({
    defaultValues: {
      inviteeEmail: '', // Need something here for typechecking
      roleTemplateName: DEFAULT_ROLE_TEMPLATE_NAME,
      invitePermissionsMode: 'default',
      customRoleCapabilities: JSON.stringify(
        [
          {
            action: 'UNI_GET',
            resources: [`UniResource(*.*.${org?.domains?.[0]})`],
          },
        ],
        null,
        2,
      ),
    },
    onSubmit: async ({ value }) => {
      logger('submit')
      if (invitePermissionsMode === 'custom') {
        if (selectedRoleTemplate?.name === CUSTOM_ROLE_TEMPLATE_NAME) {
          try {
            validateCapabilities(value.customRoleCapabilities)
          } catch (e) {
            console.warn('Unable to submit Org Invite', e)
            notify.error(e.message)
            return
          }
        }
      }

      try {
        const response = await inviteMutation.mutateAsync(value)
        if (response.errors) {
          notify.error(response.errors[0].message)
          return
        }
        close()
        refetchOrg({ queryClient })
        refetchOrgDetails({ queryClient })
      } catch (e) {
        console.error('Unable to invite to org', e)
        notify.error('Oops, something went wrong. Please try again or contact support.')
        close()
      }
    },
  })
  const [selectedRoleTemplateName, invitePermissionsMode] = form.useStore((state) => [
    state.values.roleTemplateName,
    state.values.invitePermissionsMode,
  ])
  const selectedRoleTemplate = roleTemplates.find((t) => t.name === selectedRoleTemplateName)

  const close = () => {
    logger('close')
    setShowInvite(false)
  }

  const open = () => {
    logger('open')
    form.reset()
    setShowInvite(true)
  }

  const permissionOptions = [
    {
      value: 'default',
      label: 'Default organization role',
      description: 'User will get the default role for your organization',
    },
    {
      value: 'custom',
      label: 'Custom access level',
      description: 'User will get a new role with custom permissions',
    },
  ]

  return (
    <>
      <Button kind='secondary' icon='add' iconSize={16} onClick={open} disabled={hasNoApprovedDomains}>
        Invite org member
      </Button>
      {hasNoApprovedDomains && (
        <Tooltip className='ml-2'>
          You&rsquo;ll be able to invite members to your Organization once you have an approved domain.
        </Tooltip>
      )}
      <Drawer open={showInvite === true} onClose={() => close()} className='w-screen max-w-7xl'>
        <Form form={form}>
          <div className='grid h-screen grid-rows-[auto_minmax(auto,1fr)_auto]'>
            <header className='border-neutral-3 bg-neutral-0 flex items-center gap-4 border-b p-6'>
              <Dialog.Title as='span' className='text-lg font-medium leading-6 text-gray-900'>
                <h2 className='h3 m-0'>Invite to organization</h2>
              </Dialog.Title>
            </header>
            <aside className='flex min-h-0 overflow-y-auto'>
              <div className='border-neutral-3 flex min-h-0 flex-1 flex-col gap-8 overflow-y-auto border-r p-8'>
                <div className='!mb-0'>
                  <TextField
                    label='New member email'
                    description='Email domain must match one of your Orgs owned domains'
                    name='inviteeEmail'
                    id='inviteeEmail'
                    form={form}
                    type='email'
                    validators={{
                      onBlur: ({ value }: any) => {
                        if (!value) {
                          return 'This field is required.'
                        }
                        if (!inputValidation.isEmail.pattern.test(value)) {
                          return inputValidation.isEmail.message
                        }
                        const emailDomain = value.split('@')[1]
                        if (!org?.domains?.includes(emailDomain)) {
                          return `Email domain must match one of your Orgs owned domains (i.e., ${orgDomains}).`
                        }
                      },
                    }}
                  />
                </div>

                <RadioGroupField
                  name='invitePermissionsMode'
                  label='New user role'
                  description='Select the role this user will have'
                  form={form}
                  options={permissionOptions}
                  validators={isRequiredOnBlur}
                />

                {invitePermissionsMode === 'custom' && (
                  <form.Field name='roleTemplateName'>
                    {(field) => (
                      <RoleTemplateRadioGroup
                        label='Access level'
                        labelDescription={`Select an option below. Leave "Default Access" selected if you're not sure.`}
                        name='roleTemplateName'
                        field={field}
                      />
                    )}
                  </form.Field>
                )}
              </div>
              <div className='hidden min-h-0 flex-1 flex-col gap-8 overflow-y-auto p-8 sm:flex'>
                {invitePermissionsMode === 'default' && org?.defaultRole && (
                  <>
                    <p className='mb-6 font-semibold'>Default role: {org?.defaultRole?.name}</p>
                    <ReadOnlyRoleOverview capabilities={org?.defaultRole?.capabilities} />
                  </>
                )}
                {invitePermissionsMode === 'custom' && (
                  <>
                    <div className={selectedRoleTemplate?.name === CUSTOM_ROLE_TEMPLATE_NAME ? '' : 'hidden'}>
                      <CustomRoleBuilder form={form} />
                    </div>
                    {selectedRoleTemplate?.name && selectedRoleTemplate?.name !== CUSTOM_ROLE_TEMPLATE_NAME && (
                      <div className=''>
                        <p className='mb-6 font-semibold'>{selectedRoleTemplate.name} permissions</p>
                        <ReadOnlyRoleOverview capabilities={selectedRoleTemplate.capabilities!} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </aside>
            <footer className='border-neutral-3 flex items-center justify-end gap-2 border-t p-6'>
              <Button kind='secondary' onClick={close}>
                Cancel
              </Button>
              <form.Subscribe selector={(state) => [state.isSubmitting]}>
                {([isSubmitting]) => (
                  <Button
                    type='submit'
                    kind='primary'
                    disabled={isSubmitting}
                    icon={isSubmitting ? 'refresh' : null}
                    iconProps={{
                      isSpinning: isSubmitting,
                      size: 15,
                    }}
                  >
                    Send invite
                  </Button>
                )}
              </form.Subscribe>
            </footer>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default OrgInvite
