import { DataPolicyColumnEffect } from '@vendia/management-api-types'
import clsx from 'clsx'
import { getPolicyEffectLabel } from 'src/utils/lakehouse/flows'
import { LakehouseTableForm } from 'src/utils/lakehouse/types'

export const ProductColumnPolicies = ({ table }: { table: LakehouseTableForm }) => {
  const { defaultColumnPolicyEffect, columnPolicies } = table
  const hasRules = columnPolicies.length > 0

  return (
    <div className={clsx('flex flex-col text-sm')}>
      <div className='mt-1 flex flex-col bg-white text-sm'>
        {!hasRules && <h6 className='text-l border-neutral-5 border bg-white p-4 font-semibold'>No rules</h6>}
        {hasRules &&
          columnPolicies
            // Don't include rules that match the default policy
            .filter((p) => p.effect !== defaultColumnPolicyEffect)
            .map((columnPolicy) => {
              return (
                <div className='border-neutral-5 border border-b-0 bg-white p-4 last:border-b' key={columnPolicy.name}>
                  When <strong>{columnPolicy.name}</strong> is present,{' '}
                  <strong>{getPolicyEffectLabel(columnPolicy.effect, defaultColumnPolicyEffect)?.toLowerCase()}</strong>
                  {columnPolicy.value ? (
                    <>
                      {' '}
                      <em className='font-semibold underline'>{columnPolicy.value}</em>
                    </>
                  ) : null}
                  {columnPolicy.value && columnPolicy.effect === DataPolicyColumnEffect.MaskExceptLast
                    ? ' characters'
                    : null}
                  .
                </div>
              )
            })}
      </div>
    </div>
  )
}
