import { getSafeUniNodeName } from '../lakehouse/share-apps.utils'

export type JoinType = 'INNER JOIN' | 'LEFT JOIN' | 'RIGHT JOIN' | 'FULL OUTER JOIN'

type JoinTableDefinition = {
  name?: string
  nodeName?: string
}

const DB_SUFFIX = '_db'

const getNodeDbName = (uniName?: string, nodeName?: string) => {
  return getSafeUniNodeName(uniName ?? '', nodeName ?? '') + DB_SUFFIX
}
export interface JoinDefinition {
  leftTable: JoinTableDefinition
  rightTable: JoinTableDefinition
  leftJoinKey: string[]
  rightJoinKey: string[]
  joinType?: JoinType
  columns?: string[]
  uniName?: string
}

export class SQLGenerator {
  static formatSQL(sql: string): string {
    const keywords = ['SELECT', 'FROM', 'WHERE', 'JOIN', 'LEFT', 'RIGHT', 'INNER', 'OUTER', 'ON', 'AND', 'OR']
    let formattedSQL = sql
    let indentLevel = 0

    // newlines before keywords
    keywords.forEach((keyword) => {
      formattedSQL = formattedSQL.replace(new RegExp(`\\s${keyword}\\s`, 'gi'), `\n${keyword} `)
    })

    // split into lines and handle indentation
    return formattedSQL
      .split('\n')
      .map((line) => {
        line = line.trim()
        if (line.startsWith(')')) indentLevel--
        const indent = '  '.repeat(Math.max(0, indentLevel))
        if (line.endsWith('(')) indentLevel++
        return indent + line
      })
      .join('\n')
      .trim()
  }

  static generateJoinSQL(def: JoinDefinition): string {
    if (!def.leftTable || !def.rightTable || !def.leftJoinKey?.length || !def.rightJoinKey?.length) {
      return ''
    }

    const joinType = def.joinType || 'INNER JOIN'
    const selectColumns = def.columns?.length ? def.columns.join(',\n  ') : '*'
    // generate on conditions for multiple keys
    const onConditions = def.leftJoinKey
      .map((leftKey, index) => {
        const rightKey = def.rightJoinKey[index]
        return `${def.leftTable.name}.${leftKey} = ${def.rightTable.name}.${rightKey}`
      })
      .join('\n  AND ')

    const sql = `
SELECT
  ${selectColumns}
FROM ${getNodeDbName(def.uniName, def.leftTable.nodeName)}.${def.leftTable.name}
${joinType} ${getNodeDbName(def.uniName, def.rightTable.nodeName)}.${def.rightTable.name}
  ON ${onConditions}`

    return this.formatSQL(sql)
  }

  static validateSQL(sql: string, requiredTables: string[]): string[] {
    const errors: string[] = []
    const sqlLower = sql.toLowerCase()

    if (!sql.trim()) {
      errors.push('Query cannot be empty')
    }
    if (!sqlLower.includes('select')) {
      errors.push('Query must include SELECT statement')
    }
    if (!sqlLower.includes('from')) {
      errors.push('Query must include FROM clause')
    }
    if (!sqlLower.includes('join')) {
      errors.push('Query must include a JOIN statement')
    }
    const missingTables = requiredTables.filter((table) => !sqlLower.includes(table.toLowerCase()))
    if (missingTables.length > 0) {
      errors.push(`Query must include all selected tables: ${missingTables.join(', ')}`)
    }
    return errors
  }
}

export function formatMonacoSQL(monaco: any, value: string): string {
  try {
    return SQLGenerator.formatSQL(value)
  } catch (error) {
    console.error('Error formatting SQL:', error)
    return value
  }
}
