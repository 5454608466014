import { useEffect, useState } from 'react'
import { FlowContext } from 'src/components/flows/types'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'

import { JoinColumnSection } from './join-column-section'
import { JoinConditionSelector } from './join-condition-selector'
import { JoinTableSelector } from './join-table-selector'

export const JoinConfigurator = ({ context }: { context: FlowContext<LakehouseFlowStepValues> }) => {
  const form = context.form
  const product = form.useStore((s) => s.values.product)
  const nodeTables = form.useStore((s) => s.values.existingVendiaTables) || []
  const queryDefinition = product?.queryDefinition

  const [columnCount, setColumnCount] = useState(0)
  const [returnAllColumnsLabel, setReturnAllColumnsLabel] = useState(`Return all columns (${columnCount})`)

  // update the column count and return all columns label
  useEffect(() => {
    let count = 0
    if (queryDefinition?.leftTable?.table) {
      const leftTableDef = nodeTables.find((t: any) => t.name === queryDefinition?.leftTable?.table)
      count += leftTableDef?.sourceTableDefinitions?.[0]?.columns?.length || 0
    }
    if (queryDefinition?.rightTable?.table) {
      const rightTableDef = nodeTables.find((t: any) => t.name === queryDefinition?.rightTable?.table)
      count += rightTableDef?.sourceTableDefinitions?.[0]?.columns?.length || 0
    }
    setColumnCount(count)
    setReturnAllColumnsLabel(`Return all columns (${count})`)
  }, [queryDefinition?.leftTable?.table, queryDefinition?.rightTable?.table, nodeTables])

  // The sourceTableDefinition.columns[].primaryKey is always false for some reason
  // but we keep track of it in the keyColumns array
  const getTableColumns = (tableName: string) => {
    const table = nodeTables.find((t: any) => t.name === tableName)
    if (!table || !table.sourceTableDefinitions?.[0]) return []
    const keyColumns = table.sourceTableDefinitions?.[0].keyColumns || []

    return table.sourceTableDefinitions[0].columns.map((col: any) => ({
      ...col,
      primaryKey: keyColumns.includes(col.name),
    }))
  }

  return (
    <div>
      <div>
        <div>
          <section className='mb-8 rounded-lg border border-slate-200 bg-slate-50 p-8 shadow-sm'>
            <JoinTableSelector context={context} />
          </section>

          <section className='mb-8 rounded-lg border border-slate-200 bg-slate-50 p-8 shadow-sm'>
            <JoinConditionSelector context={context} />
          </section>

          <section className='mb-8 rounded-lg border border-slate-200 bg-slate-50 p-8 shadow-sm'>
            <JoinColumnSection
              context={context}
              getTableColumns={getTableColumns}
              returnLabel={returnAllColumnsLabel}
            />
          </section>
        </div>
      </div>
    </div>
  )
}
