import { DeepKeys, DeepValue, Validator } from '@tanstack/react-form'
import { FlowModel, FlowStep } from 'src/components/flows/types'

import { createFieldValidator, FieldValidatorProps } from '../form/field-validators'

export type LakehouseFlowFieldValidatorProps<
  TParentData,
  TName extends DeepKeys<TParentData & FlowModel<TParentData>>,
  TFieldValidator extends
    | Validator<DeepValue<TParentData & FlowModel<TParentData>, TName>, unknown>
    | undefined = undefined,
  TFormValidator extends Validator<TParentData, unknown> | undefined = undefined,
  TData extends DeepValue<TParentData & FlowModel<TParentData>, TName> = DeepValue<
    TParentData & FlowModel<TParentData>,
    TName
  >,
> = FieldValidatorProps<
  TParentData & FlowModel<TParentData>,
  DeepKeys<TParentData & FlowModel<TParentData>>,
  TFieldValidator,
  TFormValidator,
  TData
> & {
  stepId: string
}

export const lakehouseFlowFieldValidator = <
  TParentData,
  TName extends DeepKeys<TParentData & FlowModel<TParentData>>,
  TFieldValidator extends
    | Validator<DeepValue<TParentData & FlowModel<TParentData>, TName>, unknown>
    | undefined = undefined,
  TFormValidator extends Validator<TParentData & FlowModel<TParentData>, unknown> | undefined = undefined,
  TData extends DeepValue<TParentData & FlowModel<TParentData>, TName> = DeepValue<
    TParentData & FlowModel<TParentData>,
    TName
  >,
>({
  stepId,
  validator,
  asyncValidator,
  listenTo,
  ignoreEvents,
}: LakehouseFlowFieldValidatorProps<TParentData, TName, TFieldValidator, TFormValidator, TData>) => {
  return createFieldValidator<
    TParentData & FlowModel<TParentData>,
    DeepKeys<TParentData & FlowModel<TParentData>>,
    TFieldValidator,
    TFormValidator,
    TData
  >(
    { validator, asyncValidator, listenTo, ignoreEvents },
    {
      // @ts-ignore // complicated nested types
      shouldValidate: (fieldApi: any) => {
        const flowSteps = fieldApi.form.state.values.flowSteps as FlowStep<TParentData>[]
        const currStepIndex = fieldApi.form.state.values.currStepIndex
        const flowIndex = flowSteps.findIndex((step: FlowStep<TParentData>) => step.id === stepId)
        return flowIndex <= currStepIndex
      },
    },
  )
}
