import { Node, StatusEnum, Uni } from '@vendia/management-api-types'

export const isUniOrNodePending = (uni?: Uni, node?: Node) => {
  if (!uni) return false
  const isUniPending = [
    StatusEnum.PendingRegistration,
    StatusEnum.Adding,
    StatusEnum.Deploying,
    StatusEnum.Updating,
    StatusEnum.Stopped,
  ].includes(uni?.status as StatusEnum)
  const isNodePending = node?.status === StatusEnum.PendingRegistration || node?.status === StatusEnum.Deploying
  return isUniPending || isNodePending
}
