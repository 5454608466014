import { useState } from 'react'

import Button from './components/buttons/button'
import Form from './components/fields/form'
import Icon from './components/icons/icon'
import UserIcon from './components/icons/user-icon'
import TextInput from './components/inputs/text.input'
import Loader from './components/loaders/page-loader'
import BasicModal from './components/modals/modal.basic'
import useGetCurrentVendiaUserQuery from './utils/hooks/use-current-vendia-user-query'

enum Status {
  Idle = 'idle',
  Activated = 'activated',
  VinnieMessageSent = 'vinne-message-sent',
  VinnieResponded = 'vinnie-responded',
}

interface Message {
  id: string
  content: string
  sender: 'vinnie' | 'user'
}

const VinnieMessage = ({ content }: { content: string }) => (
  <div className='flex items-start space-x-3'>
    <img
      alt='Chatbot Avatar'
      className='rounded-full'
      height={44}
      src='/images/vinnie.png'
      style={{
        aspectRatio: '40/40',
        objectFit: 'cover',
      }}
      width={44}
    />
    <div className='max-w-[70%] rounded-lg bg-gray-100 px-4 py-2'>
      <p className='text-sm'>{content}</p>
    </div>
  </div>
)
const UserMessage = ({ content, user }: { content: string; user: any }) => (
  <div className='flex items-center justify-end space-x-3'>
    <div className='max-w-[70%] rounded-lg bg-blue-500 px-4 py-2 text-white'>
      <p className='text-sm'>{content}</p>
    </div>
    <UserIcon user={user} />
  </div>
)
export default function Chatbot() {
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()
  const user = getCurrentVendiaUserQuery?.data?.getUser
  const [status, setStatus] = useState<Status>(Status.Idle)
  const [sessionId, setSessionId] = useState<string>()
  const [messages, setMessages] = useState<Message[]>([
    {
      id: '1',
      content: "Hello! I'm Vinnie, the Vendia Chatbot. How can I assist you today?",
      sender: 'vinnie',
    },
  ])

  const fetchVinnieResponse = async (message: string) => {
    const response = await fetch('https://6lrotcyfmsry6irdor4zwixjyi0einak.lambda-url.us-west-2.on.aws/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt: message, sessionId }),
    })
    return response.json()
  }

  const onSend = async (e: any) => {
    e.preventDefault()
    const message = e.target.message.value
    setMessages((currentMessages) => [
      ...currentMessages,
      { id: `${messages.length + 1}`, content: message, sender: 'user' },
    ])
    setStatus(Status.VinnieMessageSent)
    e.target.message.value = ''
    const response = await fetchVinnieResponse(message)
    const text = response.output.text
    setStatus(Status.VinnieResponded)
    setMessages((currentMessages) => [
      ...currentMessages,
      { id: `${messages.length + 1}`, content: text, sender: 'vinnie' },
    ])
    setSessionId(response.sessionId)
  }

  if (status === Status.Idle) {
    return (
      <div className='fixed bottom-8 right-8 shadow-2xl'>
        <Button kind='secondary' onClick={() => setStatus(Status.Activated)}>
          <Icon size={20} name='feedback' className='pr-2' /> Chat with Vinnie
        </Button>
      </div>
    )
  }

  return (
    <BasicModal
      className='sm:max-w-xl'
      title={
        <div className='flex items-center justify-between'>
          <span>Chat with Vinnie</span>
          <button
            onClick={() => {
              setStatus(Status.Idle)
              setMessages([
                {
                  id: '1',
                  content: "Hello! I'm Vinnie, the Vendia Chatbot. How can I assist you today?",
                  sender: 'vinnie',
                },
              ])
              setSessionId(undefined)
            }}
            className='text-red-500'
          >
            <Icon name='close' size={16} className='bottom-3' />
          </button>
        </div>
      }
      // @ts-ignore 🤷‍♂️ sigh
      isOpen={status !== Status.Idle}
      onClose={() => {
        setStatus(Status.Idle)
        setMessages([
          {
            id: '1',
            content: "Hello! I'm Vinnie, the Vendia Chatbot. How can I assist you today?",
            sender: 'vinnie',
          },
        ])
        setSessionId(undefined)
      }}
      buttons={
        <form className='flex flex-grow items-center space-x-2 rounded-b' onSubmit={onSend}>
          <input
            name='message'
            className='flex h-[44px] max-h-[44px] w-full flex-1 flex-col rounded-md border border-solid border-black p-3'
            placeholder='Type your message...'
            type='text'
          />
          <Button type='submit'>Send</Button>
        </form>
      }
    >
      <div className='flex-1 space-y-4 overflow-auto p-4'>
        {messages.map((message) => {
          if (message.sender === 'vinnie') {
            return <VinnieMessage key={message.id} content={message.content} />
          }
          return <UserMessage key={message.id} content={message.content} user={user} />
        })}
      </div>
      {status === Status.VinnieMessageSent && (
        <div className='px-2'>
          <Loader padding={false} />
        </div>
      )}
    </BasicModal>
  )
}
