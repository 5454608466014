import { VendiaSchema } from '@vendia/management-api-types'
import dayjs from 'dayjs'
import debug from 'debug'
import { VendiaUniqueKey } from 'src/types/schema'

const logger = debug('app:DataImporter')

type UnsupportedJsonSchemaType = 'object' | 'array'

export type SupportedJsonSchemaType = 'string' | 'number' | 'integer' | 'boolean' | 'object' | 'array'

export type SupportedStringFormats = 'date-time' | 'date' | 'email' | 'uri'

export type SupportedScalarType = string | number | boolean | Date

interface JsonSchemaAttribute extends Pick<VendiaSchema, 'description' | 'type' | 'format' | 'enum'> {
  name: string
  required: boolean
  type: SupportedJsonSchemaType | UnsupportedJsonSchemaType
  format: SupportedStringFormats
  unique: boolean
}

export const getAttributesForEntity = ({
  entity,
  schema,
}: {
  entity: string
  schema: VendiaSchema
}): JsonSchemaAttribute[] => {
  const entityDefinition = schema.properties[entity].items as VendiaSchema
  const attributes =
    entityDefinition && entityDefinition.properties
      ? Object.entries(entityDefinition.properties)?.map(([key, value]) => ({
          name: key,
          description: value.description,
          type: value.type as SupportedJsonSchemaType,
          format: value.format as SupportedStringFormats,
          enum: value.enum as string[],
        }))
      : []
  return attributes.map((attribute) => ({
    ...attribute,
    required: (entityDefinition.required as string[])?.includes(attribute.name),
    unique: (entityDefinition[VendiaUniqueKey] as string[])?.includes(attribute.name),
  }))
}

export const getTopLevelEntities = (schema: VendiaSchema) => {
  return Object.entries(schema.properties).map(([key, value]) => ({
    label: key,
    value: key,
    description: value.description,
  }))
}

export const jsonTypeToFriendlyLabel = (type: SupportedJsonSchemaType, format: SupportedStringFormats): string => {
  switch (type) {
    case 'string':
      if (format === 'date-time') {
        return 'Date & Time'
      } else if (format === 'date') {
        return 'Date'
      } else if (format === 'email') {
        return 'E-mail'
      } else if (format === 'uri') {
        return 'URI'
      }
      return 'Text'
    case 'number':
      return 'Number (decimal, e.g. 1.15)'
    case 'integer':
      return 'Number (whole, e.g. 1, 2, 3)'
    case 'boolean':
      return 'Boolean (e.g. true/false)'
  }

  return 'Text'
}

export const valueToFriendlyLabel = (
  value: string,
  expectedJsonSchemaType?: SupportedJsonSchemaType,
  expectedJsonSchemaFormat?: SupportedStringFormats,
): string => {
  // Check if the string value is a date
  if (expectedJsonSchemaType === 'string') {
    if (expectedJsonSchemaFormat === 'date-time') {
      if (dayjs(value).isValid()) {
        return 'Date & Time'
      }
    } else if (expectedJsonSchemaFormat === 'date') {
      if (dayjs(value).isValid()) {
        return 'Date'
      }
    } else if (expectedJsonSchemaFormat === 'email') {
      if (value.includes('@')) {
        return 'E-mail'
      }
    } else if (expectedJsonSchemaFormat === 'uri') {
      if (value.includes('http')) {
        return 'URI'
      }
    }
  } else if (expectedJsonSchemaType === 'number') {
    if (!isNaN(Number(value))) {
      return 'Number (decimal, e.g. 1.15)'
    }
  } else if (expectedJsonSchemaType === 'integer') {
    if (!isNaN(Number(value))) {
      return 'Number (whole, e.g. 1, 2, 3)'
    }
  } else if (expectedJsonSchemaType === 'boolean') {
    if (value?.toLowerCase() === 'true' || value?.toLowerCase() === 'false') {
      return 'Boolean (e.g. true/false)'
    }
  }
  return 'Text'
}

/**
 * transform nested objects to support the upload csv with nested objects
 * @param objString
 * @returns string
 */
export const transformObjString = (objString: string): string | number | boolean => {
  let jsonObject = JSON.parse(objString)

  function formatObject(obj: any): string | number | boolean {
    if (typeof obj === 'string') {
      return JSON.stringify(obj)
    } else if (typeof obj === 'number' || typeof obj === 'boolean') {
      return obj // Keep numbers and booleans as they are
    } else if (Array.isArray(obj)) {
      return `[${obj.map(formatObject).join(', ')}]` // Format arrays
    } else if (typeof obj === 'object' && obj !== null) {
      return `{${Object.entries(obj)
        .map(([k, v]) => `${k}:${formatObject(v)}`)
        .join(', ')}}`
    }
    return obj
  }

  return formatObject(jsonObject)
}

export const mapValueToSchemaType = (value: string, jsonSchemaType: SupportedJsonSchemaType): any => {
  switch (jsonSchemaType) {
    case 'string':
      return value
    case 'object':
      return value
    case 'array':
      return value
    case 'number':
      if (isNaN(Number(value))) {
        return value
      }
      return Number(value)
    case 'integer':
      if (isNaN(Number(value))) {
        return value
      }
      return Number(value)
    case 'boolean':
      return value?.toLowerCase() === 'true'
  }
}
