import { useQuery } from '@tanstack/react-query'
import useApi from 'src/utils/hooks/use-api'

import { JobsByDataProductKey } from './table-card'

export const useLakehouseJobs = ({
  uniName,
  nodeName,
  enabled,
}: {
  uniName: string
  nodeName: string
  enabled?: boolean
}) => {
  const api = useApi()
  return useQuery({
    queryKey: ['getLakehouseJobs', { uniName, nodeName }],
    queryFn: async () => {
      return api.getLakehouseJobs({ uniName, nodeName }) as Promise<{
        getLakehouseJobs: { jobs?: JobsByDataProductKey }
      }>
    },
    enabled,
  })
}
