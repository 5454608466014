import debug from 'debug'
import { useEffect, useRef, useState } from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import Button from './components/buttons/button'
import Icon from './components/icons/icon'
import UserIcon from './components/icons/user-icon'
import Loader from './components/loaders/page-loader'
import BasicModal from './components/modals/modal.basic'
import useGetCurrentVendiaUserQuery from './utils/hooks/use-current-vendia-user-query'

const logger = debug('app:chatbot')

enum Status {
  Idle = 'idle',
  Activated = 'activated',
  VinnieMessageSent = 'vinne-message-sent',
  VinnieResponded = 'vinnie-responded',
}

interface Message {
  id: string
  content: string
  sender: 'vinnie' | 'user'
}

const VinnieMessage = ({ content }: { content: string }) => (
  <div className='flex items-start space-x-3'>
    <img
      alt='Chatbot Avatar'
      className='rounded-full'
      height={44}
      src='/images/vinnie.png'
      style={{
        aspectRatio: '40/40',
        objectFit: 'cover',
      }}
      width={44}
    />
    <div className='min-w-[50%] max-w-[80%] rounded-lg bg-gray-100 px-4 py-2'>
      <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
    </div>
  </div>
)

const UserMessage = ({ content, user }: { content: string; user: any }) => (
  <div className='flex items-center justify-end space-x-3'>
    <div className='min-w-[50%] max-w-[80%] rounded-lg bg-blue-500 px-4 py-2 text-white'>
      <p className='whitespace-pre-line'>{content}</p>
    </div>
    <UserIcon user={user} />
  </div>
)

export function SqlChatbot() {
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()
  const user = getCurrentVendiaUserQuery?.data?.getUser
  const [status, setStatus] = useState<Status>(Status.Idle)
  const [sessionId, setSessionId] = useState<string>()
  const [messages, setMessages] = useState<Message[]>([
    {
      id: '1',
      content: "Hello! I'm Vinnie, the Vendia Chatbot. How can I assist you today?",
      sender: 'vinnie',
    },
  ])

  const messagesEndRef = useRef<HTMLDivElement>(null)

  // Reset session ID and messages every time the modal closes (on Statu.Idle)
  useEffect(() => {
    if (status === Status.Idle) {
      setSessionId(Math.random().toString(36).substring(7))
      logger('Session ID reset!')
      setMessages([
        {
          id: '1',
          content:
            "Hello! I'm Vinnie, the Vendia Chatbot. I can help you query and explore your data. How can I assist you today?",
          sender: 'vinnie',
        },
      ])
    }
  }, [status])

  useEffect(() => {
    // Scroll to the bottom of the chat window when a new message is added
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  useEffect(() => {
    // Log messages whenever vinnie finishes responding
    if (status === Status.VinnieResponded) {
      logger('messages:', messages)
    }
  }, [status])

  const fetchVinnieResponse = async (message: string) => {
    return fetch('https://levruji4ceisymkflafr5t5jja0bqvxk.lambda-url.us-east-1.on.aws/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt: message, sessionId }),
    })
  }

  const onSend = async (e: any) => {
    e.preventDefault()
    const message = e.target.message.value
    setMessages((currentMessages) => [
      ...currentMessages,
      { id: `${currentMessages.length + 1}`, content: message, sender: 'user' },
    ])
    setStatus(Status.VinnieMessageSent)
    e.target.message.value = ''
    const response = await fetchVinnieResponse(message)

    // Streaming response from Vinnie
    const decoder = new TextDecoder()
    const reader = response.body?.getReader()
    if (!reader) {
      logger('No reader found in response body')
      return
    }

    while (true) {
      const { done, value } = await reader.read()
      if (done) break
      // Treating this like a pseudo server-sent event stream for now, though not sure how it'll work if deployed
      //  to production behind API Gateway which doesn't really support server-sent events (but apparently you can kinda make it work)
      const text = decoder.decode(value)
      text.split('\n').forEach((line) => {
        if (line.startsWith('data:')) {
          const data = JSON.parse(line.replace('data: ', '')) as { message: string }
          setMessages((currentMessages) => {
            const lastMessage = currentMessages[currentMessages.length - 1]

            // IF last message was from User, then add Vinnie's response
            if (lastMessage.sender === 'user') {
              return [
                ...currentMessages,
                { id: `${currentMessages.length + 1}`, content: data.message, sender: 'vinnie' },
              ]
            }

            // ELSE append to last vinnie message
            const updatedMessages = currentMessages.slice(0, -1)
            const updatedMessage = { ...lastMessage, content: `${lastMessage.content}${data.message}` }
            return [...updatedMessages, updatedMessage]
          })
        }
      })
      // logger('Vinnie response:', text)
    }

    setStatus(Status.VinnieResponded)
  }

  if (status === Status.Idle) {
    return (
      <div className='fixed bottom-8 right-8 shadow-2xl'>
        <Button kind='secondary' onClick={() => setStatus(Status.Activated)}>
          <Icon size={20} name='feedback' className='pr-2' /> Chat with Vinnie
        </Button>
      </div>
    )
  }

  return (
    <BasicModal
      className='sm:max-w-lg md:max-w-xl lg:max-w-4xl xl:max-w-6xl'
      title={
        <div className='flex items-center justify-between'>
          <span>Chat with Vinnie</span>
        </div>
      }
      // @ts-ignore 🤷‍♂️ sigh
      isOpen={status !== Status.Idle}
      onClose={() => {
        setStatus(Status.Idle)
      }}
      buttons={
        <div className='flex w-full justify-between gap-24'>
          <Button
            kind='secondary'
            onClick={() => {
              setStatus(Status.Idle)
            }}
          >
            Close
          </Button>
          <form className='flex flex-grow items-center space-x-2 rounded-b' onSubmit={onSend}>
            <input
              name='message'
              className='flex h-[44px] max-h-[44px] w-full flex-1 flex-col rounded-md border border-solid border-black p-3'
              placeholder='Type your message...'
              type='text'
            />
            <Button type='submit' className='w-32'>
              Send
            </Button>
          </form>
        </div>
      }
    >
      <style>
        {`
/* MARKDOWN TABLE STYLING */

:root {
  --color-canvas-default: #ffffff;
  --color-canvas-subtle: #f6f8fa;
  --color-border-default: #d0d7de;
  --color-border-muted: hsla(210, 18%, 87%, 1);
  font-size: 1em;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  max-width: 100%;
  width: 100%;
  overflow: auto;
  display: block;
}

tr {
  background-color: var(--color-canvas-default);
  border-top: 1px solid var(--color-border-muted);
}

tr:nth-child(2n) {
  background-color: var(--color-canvas-subtle);
}

td,
th {
  padding: 6px 13px;
  border: 1px solid var(--color-border-default);
  /* truncate super long text cols */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  font-weight: 600;
}

table img {
  background-color: transparent;
}

/* MARKDOWN LIST STYLING */
ol {
  list-style-type: decimal;
  margin-left: 1em;
}

ul {
  list-style-type: disc;
  margin-left: 1em;
} 

/* add some margin between all block elements */
p, blockquote, ul, ol, dl, pre, h1, h2, h3, h4, h5, h6 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

/* basic styles for all headers */
h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  margin-top: 0.75em;
}

h1:first-of-type,
h2:first-of-type,
h3:first-of-type,
h4:first-of-type,
h5:first-of-type,
h6:first-of-type {
  margin-top: 0;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.25em;
}

h4 {
  font-size: 1.125em;
} 
  `}
      </style>
      <div className='flex-1 space-y-4 overflow-auto p-4'>
        {messages.map((message) => {
          if (message.sender === 'vinnie') {
            return <VinnieMessage key={message.id} content={message.content} />
          }
          return <UserMessage key={message.id} content={message.content} user={user} />
        })}
        <div ref={messagesEndRef} />
      </div>
      {status === Status.VinnieMessageSent && (
        <div className='px-2'>
          <Loader padding={false} />
        </div>
      )}
    </BasicModal>
  )
}
