import { TableColumnInput } from '@vendia/management-api-types'
import { useEffect, useState } from 'react'
import Button from 'src/components/buttons/button'
import ListboxField from 'src/components/fields/listbox.field'
import { FlowContext } from 'src/components/flows/types'
import Icon from 'src/components/icons/icon'
import { JoinCondition, LakehouseFlowStepValues } from 'src/utils/lakehouse/types'

export const JoinConditionSelector = ({ context }: { context: FlowContext<LakehouseFlowStepValues> }) => {
  const form = context.form
  const product = form.useStore((s) => s.values.product)
  const nodeTables = form.useStore((s) => s.values.existingVendiaTables) || []

  // The sourceTableDefinition.columns array stores false for primaryKey for some reason
  // but keeps track of it in the keyColumns array
  const getTableColumns = (tableName: string) => {
    const table = nodeTables.find((t: any) => t.name === tableName)
    if (!table || !table.sourceTableDefinitions?.[0]) return []
    const keyColumns = table.sourceTableDefinitions?.[0].keyColumns || []

    return table.sourceTableDefinitions[0].columns.map((col: any) => ({
      ...col,
      primaryKey: keyColumns.includes(col.name),
    }))
  }

  const addCondition = () => {
    if (!product?.queryDefinition) return
    const conditions = product?.queryDefinition?.joinConditions || []
    let newCondition: JoinCondition = {
      leftField: {
        name: '',
        nullable: true,
        primaryKey: false,
        type: '',
        uniqueKey: false,
      },
      rightField: {
        name: '',
        nullable: true,
        primaryKey: false,
        type: '',
        uniqueKey: false,
      },
    }
    conditions.push(newCondition)
    form.setFieldValue('product.queryDefinition.joinConditions', conditions)
  }

  const removeCondition = (index: number) => {
    if (!product?.queryDefinition) return

    const currentConditions = product?.queryDefinition?.joinConditions || []
    const newConditions = [...currentConditions]
    newConditions.splice(index, 1)
    form.setFieldValue('product.queryDefinition.joinConditions', newConditions)
  }

  const handleConditionChange = (index: number, side: 'left' | 'right', value: TableColumnInput) => {
    if (!product?.queryDefinition) return

    // Conditions must exist and the index must be valid. Condition creation should
    // be handled by the addCondition button.
    const conditions = [...(product?.queryDefinition?.joinConditions || [])]

    if (conditions.length < index + 1) {
      return
    }
    const updatedCondition = conditions[index]

    if (side === 'left') {
      updatedCondition.leftField = value
    } else if (side === 'right') {
      updatedCondition.rightField = value
    }

    conditions[index] = updatedCondition
    form.setFieldValue('product.queryDefinition.joinConditions', conditions)
  }

  return (
    <>
      <h2 className='text-dark text-lg font-bold'>Join Conditions</h2>
      <div className='text-light mb-6 text-sm'>Define how rows are matched using primary keys or foreign keys.</div>

      {/* Tables header */}
      {product?.queryDefinition?.joinConditions && product?.queryDefinition?.joinConditions.length > 0 && (
        <div className='mb-3 grid w-full grid-cols-[1fr,auto,1fr] gap-4'>
          <div className='min-w-0'>
            <div className='text-dark truncate text-sm font-bold' title={product?.queryDefinition?.leftTable?.table}>
              {product?.queryDefinition?.leftTable?.table}
            </div>
          </div>
          <div className='w-6'></div>
          <div className='min-w-0'>
            <div className='text-dark truncate text-sm font-bold' title={product?.queryDefinition?.rightTable?.table}>
              {product?.queryDefinition?.rightTable?.table}
            </div>
          </div>
        </div>
      )}

      {/* Condition Rows */}
      {product?.queryDefinition?.joinConditions?.map(
        (condition: { leftField: TableColumnInput; rightField: TableColumnInput }, index: number) => (
          <div key={index} className='grid grid-cols-[1fr,auto,1fr] items-start gap-4 pb-2'>
            <div>
              <ListboxField
                name={`product.queryDefinition?.joinConditions[${index}].leftField.name`}
                label='Column'
                options={getTableColumns(product?.queryDefinition?.leftTable?.table || '').map(
                  (col: TableColumnInput) => ({
                    label: (
                      <span className='flex items-center gap-2'>
                        {col.name}
                        {col.primaryKey && <Icon name='key' size='15px' className='text-amber-500' />}
                      </span>
                    ),
                    value: col.name,
                  }),
                )}
                defaultValue={condition.leftField?.name || ''}
                data-testid={`join-condition-left-field-${index}`}
                useNestedLabel
                wrapperClassName='w-full'
                form={form}
                onChange={(value) => {
                  if (value) {
                    const columnObj = getTableColumns(product?.queryDefinition?.leftTable?.table || '').find(
                      (col) => col.name === value,
                    )
                    if (columnObj) {
                      handleConditionChange(index, 'left', columnObj)
                    }
                  }
                }}
              />
            </div>

            <div className='flex h-12 w-6 items-center justify-center'>
              <span className='text-dark text-lg font-bold'>=</span>
            </div>

            <div className='flex'>
              <ListboxField
                name={`product.queryDefinition?.joinConditions[${index}].rightField.name`}
                label='Column'
                options={getTableColumns(product?.queryDefinition?.rightTable?.table || '').map(
                  (col: TableColumnInput) => ({
                    label: (
                      <span className='flex items-center gap-2'>
                        {col.name}
                        {col.primaryKey && <Icon name='key' size='15px' className='text-amber-500' />}
                      </span>
                    ),
                    value: col.name,
                  }),
                )}
                defaultValue={condition.rightField?.name || ''}
                data-testid={`join-condition-right-field-${index}`}
                useNestedLabel
                wrapperClassName='w-full'
                form={form}
                onChange={(value) => {
                  if (value) {
                    const columnObj = getTableColumns(product?.queryDefinition?.rightTable?.table || '').find(
                      (col) => col.name === value,
                    )
                    if (columnObj) {
                      handleConditionChange(index, 'right', columnObj)
                    }
                  }
                }}
              />

              <Button kind='icon' onClick={() => removeCondition(index)} data-testid={`remove-condition-${index}`}>
                <Icon name='x' size='20px' />
              </Button>
            </div>
          </div>
        ),
      )}

      {/* Add condition button */}
      <div className='mt-2'>
        <Button
          kind='secondary'
          onClick={addCondition}
          disabled={!product?.queryDefinition?.leftTable?.table || !product?.queryDefinition?.rightTable?.table}
          data-testid='add-condition-button'
        >
          <Icon name='plus' size='24px' className='mr-2' />
          Add Condition
        </Button>
      </div>
    </>
  )
}
