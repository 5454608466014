import debug from 'debug'
import { createContext, Dispatch, Reducer, useContext, useEffect, useReducer } from 'react'
import {
  Dataset,
  JsonSchema,
  SchemaPath,
  SchemaType,
  VendiaAclsKey,
  VendiaIndexesKey,
  VendiaSchema,
  VendiaUniqueKey,
} from 'src/types/schema'
import { assert } from 'src/utils/assert'
import { validateSchema } from 'src/utils/validate-schema'

import { supportedStringFormatOptions } from './field-modal'
import { Status } from './status'
import { getEmptySchema, getExistingIndexKey, getObjectAtPath, getSchemaKeyFromName } from './utils'

const logger = debug('components:schema-designer:context')

export interface SchemaDesignerState {
  status: Status
  /* Schema version that is being edited */
  schema: VendiaSchema | null
  /* The original schema fetched from the backend */
  uniSchema: VendiaSchema | null
  /* The schema that is being edited in the JSON editor */
  tempJsonEditorSchema: string | null
  showJsonEditor: boolean
  readOnlyMode: boolean
  evolveSchemaMode: boolean
  dataModelMode: boolean
  selectedEntityKey: string | null
  selectedFieldKey: string | null
  selectedFieldParentPath: SchemaPath | null
  selectedDemoTemplate: string | null
  dataset: Dataset | null
  schemaEvolutionMode: string | null
  allowIndexRemoval?: boolean
}

export interface SchemaDesignerField {
  title: string
  type: SchemaType
  description?: string
  stringFormat?: string
  minLength?: number
  maxLength?: number
  min?: number
  max?: number
  pattern?: string
  enumValues?: string
  required: boolean
  indexed?: boolean
  isUnique?: boolean
  arrayItemType?: SchemaType
  arrayOfArraysItemType?: SchemaType
  previousFieldKey?: string
}

export interface SchemaDesignerContextType {
  designerState: SchemaDesignerState
  setDesignerState: Dispatch<Partial<SchemaDesignerState>>
  // Derived state for convenience, DRYness
  status: SchemaDesignerState['status']
  schema: SchemaDesignerState['schema']
  uniSchema: SchemaDesignerState['uniSchema']
  tempJsonEditorSchema: SchemaDesignerState['tempJsonEditorSchema']
  showJsonEditor: SchemaDesignerState['showJsonEditor']
  selectedEntityKey: SchemaDesignerState['selectedEntityKey']
  selectedFieldKey: SchemaDesignerState['selectedFieldKey']
  selectedFieldParentPath: SchemaDesignerState['selectedFieldParentPath']
  selectedDemoTemplate: SchemaDesignerState['selectedDemoTemplate']
  readOnlyMode: SchemaDesignerState['readOnlyMode']
  evolveSchemaMode: SchemaDesignerState['evolveSchemaMode']
  dataModelMode: SchemaDesignerState['dataModelMode']
  schemaEvolutionMode: SchemaDesignerState['schemaEvolutionMode']
  allowIndexRemoval: SchemaDesignerState['allowIndexRemoval']
  entityKeys: string[]
  selectedEntity: JsonSchema | null
  selectedEntityFieldKeys: string[]
  noEntitiesCreated: boolean
  isUnsupportedEntityType: boolean
  // Actions
  upsertEntity: ({
    title,
    description,
    previousEntityKey,
  }: {
    title: string
    description: string
    previousEntityKey?: string
  }) => void

  upsertField: ({
    title,
    description,
    type,
    stringFormat,
    minLength,
    maxLength,
    min,
    max,
    pattern,
    enumValues,
    arrayItemType,
    arrayOfArraysItemType,
    required,
    indexed,
    isUnique,
    previousFieldKey,
  }: SchemaDesignerField) => void
  removeEntity: (entityKey: string) => void
  removeField: (fieldKey: string, fieldParentPath: SchemaPath) => void
}

export const SchemaDesignerContext = createContext<SchemaDesignerContextType>({} as SchemaDesignerContextType)

type SchemaDesignerProviderProps = {
  readOnlyMode?: boolean
  evolveSchemaMode?: boolean
  dataModelMode?: boolean
  selectedEntityKey?: string
  children: React.ReactNode
}

export const SchemaDesignerProvider = ({
  children,
  selectedEntityKey: initialSelectedEntityKey,
  readOnlyMode: initialReadOnlyMode,
  evolveSchemaMode: initialEvolveSchemaMode,
  dataModelMode: initialDataModelMode,
}: SchemaDesignerProviderProps) => {
  const [designerState, setDesignerState] = useReducer<Reducer<SchemaDesignerState, Partial<SchemaDesignerState>>>(
    (state, newState) => {
      logger('setDesignerState:', { newState, state })
      const mergedState = { ...state, ...newState }

      // If entities exist, make sure one is selected/active
      const entityKeys = Object.keys(mergedState?.schema?.properties ?? {})
      if (entityKeys.length > 0 && !mergedState.selectedEntityKey) {
        mergedState.selectedEntityKey = entityKeys[0]
      }
      // Finally, update the state
      return mergedState
    },
    {
      status: Status.IDLE,
      schema: null,
      uniSchema: null,
      tempJsonEditorSchema: null,
      showJsonEditor: false,
      readOnlyMode: initialReadOnlyMode ?? false,
      evolveSchemaMode: initialEvolveSchemaMode ?? false,
      dataModelMode: initialDataModelMode ?? false,
      selectedEntityKey: initialSelectedEntityKey ?? null,
      selectedFieldKey: null,
      selectedFieldParentPath: null,
      selectedDemoTemplate: 'Schema Designer',
      dataset: null,
      schemaEvolutionMode: null,
      allowIndexRemoval: false,
    },
  )

  const {
    status,
    schema,
    uniSchema,
    tempJsonEditorSchema,
    showJsonEditor,
    readOnlyMode,
    evolveSchemaMode,
    schemaEvolutionMode,
    allowIndexRemoval,
    dataModelMode,
    selectedEntityKey,
    selectedFieldKey,
    selectedFieldParentPath,
    selectedDemoTemplate,
  } = designerState
  const entityKeys = Object.keys(schema?.properties ?? {})
  const selectedEntity = selectedEntityKey ? schema?.properties?.[selectedEntityKey] ?? null : null
  const selectedEntityFieldKeys = Object.keys(selectedEntity?.items?.properties ?? {})
  const noEntitiesCreated = entityKeys?.length === 0

  logger('context selectedEntity', { selectedEntity, properties: schema?.properties, schema, selectedEntityKey })

  let isUnsupportedEntityType = false
  // If not an array OR is an array but not of objects, must use JSON editor (for now)
  if (selectedEntity?.type !== 'array' || selectedEntity?.items?.type !== 'object') {
    isUnsupportedEntityType = true
  }

  const upsertEntity: SchemaDesignerContextType['upsertEntity'] = ({ title, description, previousEntityKey }) => {
    assert(schema, 'Schema is not defined')
    // "Editing" here (when previousEntityKey is passed in) is just title/description
    //  change, so keep remaining properties
    const entity = previousEntityKey
      ? {
          ...schema.properties[previousEntityKey],
          description,
          title,
        }
      : {
          type: 'array',
          items: {
            type: 'object',
            properties: {},
            required: [],
            [VendiaUniqueKey]: [],
          },
          description,
          title,
        }

    const entityKey = getSchemaKeyFromName({ name: title, capFirstLetter: true })

    // Convert properties to array and replace edited entity and rebuild object
    //  to keep the same tab order in UI when changing entity names
    const entityEntries = Object.entries(schema.properties)
    if (previousEntityKey) {
      const entityIndex = entityEntries.findIndex(([key]) => key === previousEntityKey)
      entityEntries[entityIndex] = [entityKey, entity]
    } else {
      entityEntries.push([entityKey, entity])
    }
    schema.properties = Object.fromEntries(entityEntries)

    // Enable ACLs for new entity
    schema[VendiaAclsKey] = schema[VendiaAclsKey] ?? {}
    schema[VendiaAclsKey][`${entityKey}-acls`] = { type: entityKey }

    // If editing and name changed, remove previous key from ACLs
    if (previousEntityKey && previousEntityKey !== entityKey) {
      logger('renamed, removing previousEntityKey', previousEntityKey)
      delete schema[VendiaAclsKey][`${previousEntityKey}-acls`]
    }

    logger('schema', schema)
    setDesignerState({ schema, selectedEntityKey: entityKey })
  }

  const removeEntity: SchemaDesignerContextType['removeEntity'] = (entityKey) => {
    assert(schema, 'Schema is not defined')
    delete schema.properties[entityKey]

    schema[VendiaAclsKey] = schema[VendiaAclsKey] ?? {}
    delete schema[VendiaAclsKey][`${entityKey}-acls`]
    setDesignerState({ schema, selectedEntityKey: null })
  }

  const upsertField: SchemaDesignerContextType['upsertField'] = ({
    title,
    previousFieldKey,
    description,
    type,
    stringFormat,
    minLength,
    maxLength,
    min,
    max,
    pattern,
    enumValues,
    arrayItemType,
    arrayOfArraysItemType,
    required,
    isUnique,
    indexed,
  }) => {
    assert(schema, 'Schema is not defined')
    assert(selectedFieldParentPath, 'selectedFieldParentPath should always be defined when upserting a field')
    assert(selectedEntityKey, 'selectedEntityKey should always be defined when upserting a field')

    const newField: JsonSchema = {
      title: title,
      description: description ?? undefined /* remove null */,
      type: type,
      items:
        type === 'array'
          ? {
              type: arrayItemType,
              items:
                // Can create 2D arrays of scalars, but nothing deeper or more complex than that
                arrayItemType === 'array'
                  ? {
                      type: arrayOfArraysItemType,
                    }
                  : undefined,
              properties: arrayItemType === 'object' ? {} : undefined,
              required: arrayItemType === 'object' ? [] : undefined,
            }
          : undefined,
      properties: type === 'object' ? {} : undefined,
      required: type === 'object' ? [] : undefined,
    }
    const fieldKey = getSchemaKeyFromName({ name: title })

    const parentObject = getObjectAtPath({ schema, path: selectedFieldParentPath })
    const previousField = previousFieldKey ? parentObject.properties[previousFieldKey] : null

    // If editing, update anything designer supports editing and copy the rest
    const field = previousField ? { ...previousField, ...newField } : newField

    // Convert properties to array and replace edited entity and rebuild object
    //  to keep the same tab order in UI when changing entity names
    const fieldEntries = Object.entries(parentObject.properties)
    if (previousFieldKey) {
      const fieldIndex = fieldEntries.findIndex(([key]) => key === previousFieldKey)
      fieldEntries[fieldIndex] = [fieldKey, field]
    } else {
      fieldEntries.push([fieldKey, field])
    }
    parentObject.properties = Object.fromEntries(fieldEntries)

    // ---- Handle string formats, "pattern" (for custom regex), "enum" ----
    if (type === 'string') {
      // For actual formats, update the value
      if (stringFormat && supportedStringFormatOptions.includes(stringFormat)) {
        field.format = stringFormat
        delete field.pattern
        delete field.enum
      }
      // If they chose 'regex', there will be a "pattern" input/value, use it
      if (stringFormat === 'regex' && pattern !== undefined) {
        field.pattern = pattern
        delete field.format
        delete field.enum
      }
      // If they chose 'enum', there will be a "enum" input/value, use it
      if (stringFormat === 'enum' && enumValues !== undefined) {
        field.enum = enumValues.split(',').map((value) => value.trim())
        delete field.format
        delete field.pattern
      }
      // If explicitly choosing 'none' remove format and pattern
      if (stringFormat === 'none') {
        delete field.format
        delete field.pattern
        delete field.enum
      }
      // min/max length can be combined with any of the above
      if (minLength != null && !Number.isNaN(minLength)) {
        field.minLength = minLength
      } else {
        delete field.minLength
      }
      if (maxLength != null && !Number.isNaN(maxLength)) {
        field.maxLength = maxLength
      } else {
        delete field.maxLength
      }
    } else {
      // If not a string, remove string-only stuff
      delete field.format
      delete field.pattern
      delete field.minLength
      delete field.maxLength
      // Don't delete enum as it can be used for non-string values and user may have specified via JSON
    }

    // ---- Handle numbers ----
    if (type === 'number' || type === 'integer') {
      logger('number type', type, min, max)
      if (min != null && !Number.isNaN(min)) {
        field.minimum = min
      } else {
        delete field.minimum
      }
      if (max != null && !Number.isNaN(max)) {
        field.maximum = max
      } else {
        delete field.maximum
      }
    } else {
      // If not a number, remove number-only stuff
      delete field.minimum
      delete field.maximum
      delete field.exclusiveMinimum
      delete field.exclusiveMaximum
    }

    // ---- Handle "required" ----
    let requiredArr = Array.isArray(parentObject.required) ? parentObject.required : []
    if (required && !requiredArr.includes(fieldKey)) {
      requiredArr.push(fieldKey)
    }
    if (!required && requiredArr.includes(fieldKey)) {
      requiredArr = requiredArr.filter((requiredName: string) => requiredName !== fieldKey)
    }

    // ---- Handle unique keys ----
    let uniqueKeyArr = Array.isArray(parentObject[VendiaUniqueKey]) ? parentObject[VendiaUniqueKey] : []
    if (isUnique && !uniqueKeyArr.includes(fieldKey)) {
      uniqueKeyArr.push(fieldKey)
    }
    if (!isUnique && uniqueKeyArr.includes(fieldKey)) {
      uniqueKeyArr = uniqueKeyArr.filter((keyName: string) => keyName !== fieldKey)
    }

    // ---- Handle indexes ----
    schema[VendiaIndexesKey] = schema[VendiaIndexesKey] ?? {}
    // Have to iterate and check for existing indexes under different keys
    //  because user may have specified index outside of schema designer
    const existingIndexKey = getExistingIndexKey({ schema, fieldKey, entityKey: selectedEntityKey })
    if (indexed && !existingIndexKey) {
      schema[VendiaIndexesKey][`${selectedEntityKey}-${fieldKey}-index`] = [
        {
          type: selectedEntityKey,
          property: fieldKey,
        },
      ]
    }
    if (!indexed && existingIndexKey) {
      delete schema[VendiaIndexesKey][existingIndexKey]
    }

    // --- Handle renaming ---
    // If editing and user changed name, remove previous name from schema
    if (previousFieldKey && previousFieldKey !== fieldKey) {
      logger('renamed, removing previousFieldKey', previousFieldKey)
      // Remove an index if it exists
      const existingIndexKeyForPreviousFieldKey = getExistingIndexKey({
        schema,
        fieldKey: previousFieldKey,
        entityKey: selectedEntityKey,
      })
      if (existingIndexKeyForPreviousFieldKey) {
        delete schema[VendiaIndexesKey][existingIndexKeyForPreviousFieldKey]
      }
      // Remove the field from parent object definition
      delete parentObject.properties[previousFieldKey]
      // Remove the field from parent object's required array and unique array
      requiredArr = requiredArr.filter((name: string) => name !== previousFieldKey)
      uniqueKeyArr = uniqueKeyArr.filter((name: string) => name !== previousFieldKey)
    }

    parentObject.required = requiredArr
    parentObject[VendiaUniqueKey] = uniqueKeyArr
    setDesignerState({ schema })
  }

  const removeField: SchemaDesignerContextType['removeField'] = (fieldKey, fieldParentPath) => {
    assert(schema, 'Schema is not defined')
    const parentObject = getObjectAtPath({ schema, path: fieldParentPath })
    delete parentObject.properties[fieldKey]
    parentObject.required = Array.isArray(parentObject.required)
      ? parentObject.required.filter((name: string) => name !== fieldKey)
      : parentObject.required
    parentObject[VendiaUniqueKey] = Array.isArray(parentObject[VendiaUniqueKey])
      ? parentObject[VendiaUniqueKey].filter((name: string) => name !== fieldKey)
      : parentObject[VendiaUniqueKey]
    setDesignerState({ schema })
  }

  useEffect(() => {
    async function initializeDesignerUseEffect() {
      logger('initializeDesignerUseEffect', { uniSchema })
      const updatedState: Partial<SchemaDesignerState> = {
        selectedDemoTemplate: 'Schema Designer',
      }

      if (uniSchema) {
        logger('Setting schema from getUni query')
        const originalSchema = JSON.stringify(uniSchema)
        updatedState.tempJsonEditorSchema = originalSchema

        const [error, validSchema] = await validateSchema(originalSchema)
        if (error) {
          // Guarantee we always default to the JSON editor if there's an invalid schema
          logger('Error validating schema', error)
          updatedState.showJsonEditor = true
        } else {
          // Only set schema if it's valid, else it remains null
          // Will be checked again when user tries to switch from JSON editor to designer
          updatedState.schema = validSchema
        }
      } else {
        updatedState.tempJsonEditorSchema = JSON.stringify(getEmptySchema(), null, 2)
        updatedState.schema = getEmptySchema()
        updatedState.uniSchema = getEmptySchema()
      }

      setDesignerState(updatedState)
    }
    logger('sync schema in useEffect', { schema, tempJsonEditorSchema, uniSchema })
    initializeDesignerUseEffect()
  }, [uniSchema])

  return (
    <SchemaDesignerContext.Provider
      value={{
        designerState,
        setDesignerState,
        status,
        schema,
        uniSchema,
        tempJsonEditorSchema,
        showJsonEditor,
        readOnlyMode,
        evolveSchemaMode,
        schemaEvolutionMode,
        allowIndexRemoval,
        dataModelMode,
        selectedEntityKey,
        selectedFieldKey,
        selectedFieldParentPath,
        entityKeys,
        selectedEntity,
        selectedEntityFieldKeys,
        selectedDemoTemplate,
        noEntitiesCreated,
        isUnsupportedEntityType,
        upsertEntity,
        upsertField,
        removeEntity,
        removeField,
      }}
    >
      {children}
    </SchemaDesignerContext.Provider>
  )
}

export const useSchemaDesignerMode = (modes?: {
  readOnlyMode?: boolean
  evolveSchemaMode?: boolean
  dataModelMode?: boolean
  showJsonEditor?: boolean
}) => {
  const { setDesignerState } = useContext(SchemaDesignerContext)

  const schemaDesignerMode: typeof modes = {
    readOnlyMode: modes?.readOnlyMode ?? false,
    evolveSchemaMode: modes?.evolveSchemaMode ?? false,
    dataModelMode: modes?.dataModelMode ?? false,
  }
  if (typeof modes?.showJsonEditor === 'boolean') {
    schemaDesignerMode.showJsonEditor = modes.showJsonEditor
  }

  useEffect(() => {
    setDesignerState(schemaDesignerMode)
  }, [])
}
