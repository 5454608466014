import { useMutation } from '@tanstack/react-query'
import {
  LakehouseClouderaConnectionInput,
  LakehouseConnectionTypeEnum,
  LakehouseSnowflakeConnectionInput,
} from '@vendia/management-api-types'
import debug from 'debug'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import Card, { CardRule } from 'src/components/containers/card'
import CheckboxField from 'src/components/fields/checkbox.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { getEmptySchema } from 'src/components/schema-designer/utils'
import useApi from 'src/utils/hooks/use-api'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import notify from 'src/utils/notify'

import { StepId } from '../config'

const logger = debug('app:selectTable')

export const StepSelectTable: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form
  const api = useApi()
  const navigate = useNavigate()

  const { connectionId, nodeName, source, uniName, selectedTables } = form.useStore((s) => s.values)
  const [hasInteracted, setHasInteracted] = useState(false)

  const selectAll = () => {
    // All tables to object with true values
    const tablesObject = source.availableTables.reduce(
      (acc, table) => {
        acc[table] = true
        return acc
      },
      {} as Record<string, boolean>,
    )
    form.setFieldValue(`selectedTables`, tablesObject)
  }
  const selectNone = () => {
    form.setFieldValue(`selectedTables`, {})
  }

  const getTableSchemaMutation = useMutation<any, Error>({
    mutationFn: async () => {
      const args = {
        tableName: source.selectedTable,
        uniName: uniName!,
        nodeName: nodeName!,
        ...(connectionId && { connectionId }),
      }
      if (source.connection === LakehouseConnectionTypeEnum.Snowflake) {
        return api.getLakehouseTableSchema({
          ...args,
          snowflakeConnection: source.snowflake as LakehouseSnowflakeConnectionInput,
        })
      } else if (source.connection === LakehouseConnectionTypeEnum.Cloudera) {
        return api.getLakehouseTableSchema({
          ...args,
          clouderaConnection: source.cloudera as LakehouseClouderaConnectionInput,
        })
      }
    },
    onError: (error: Error) => notify.error(`Error fetching table schema: ${error}`),
    onSuccess: (response: any) => {
      logger('response', response)
      if (response.message) {
        notify.error(`Error fetching table schema: ${response.message}`)
        return
      }
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      form.setFieldValue('selectedTableColumns', response.getLakehouseTableSchema)
      form.setFieldValue('schema', JSON.stringify(getEmptySchema()))
      context.goToStep(StepId.TableSettings)
    },
  })

  const selectedTablesList = Object.entries(selectedTables)
    .filter(([_, value]) => value)
    .map(([key]) => key)

  return (
    <StepWrapper>
      <StepContentHeader centered large title='Select source tables' description={'Select the table(s) to import.'} />
      <ScrollableStepContent inset>
        <Card className='min-h-64 w-full max-w-4xl rounded-md' contentExpandVertical>
          <div className='flex w-full flex-grow flex-col gap-4'>
            <div className='w-full text-lg font-bold'>
              <span className='capitalize'>{source.connection}</span> tables
            </div>
            <CardRule className='-mx-10 mb-3' />
            <div className='flex items-center gap-8 text-xs'>
              <div className='flex gap-2'>
                <span>Select</span>
                <button
                  type='button'
                  onClick={selectAll}
                  className='disabled:text-light text-link p-0 text-xs hover:underline'
                >
                  all
                </button>
                <button
                  type='button'
                  onClick={selectNone}
                  className='disabled:text-light text-link p-0 text-xs hover:underline'
                >
                  none
                </button>
              </div>
              <div className='text-light text-right text-xs'>
                {selectedTablesList.length} of {source?.availableTables?.length} selected
              </div>
            </div>
            <div className='flex flex-col gap-1'>
              {source?.availableTables?.map((table) => (
                <CheckboxField
                  name={`selectedTables.${table}`}
                  key={table}
                  label={table}
                  form={form}
                  onChange={() => setHasInteracted(true)}
                />
              ))}
            </div>
            {source?.availableTables?.length === 0 && (
              <div>No tables available for this connection. Please check your connection settings.</div>
            )}
            {hasInteracted && Object.values(selectedTables).every((table) => !table) ? (
              <div className='text-red-600'>Please select at least one table to continue.</div>
            ) : null}
          </div>
        </Card>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.canSubmit]}>
        {([canSubmit]) => (
          <StepButtons
            context={context}
            isSubmitting={getTableSchemaMutation.isPending}
            hasCancel={true}
            onCancel={() => navigate('../', { relative: 'path' })}
            nextBlocked={
              Object.values(selectedTables).some((table) => table)
                ? getTableSchemaMutation.isPending || !canSubmit
                : true
            }
            onNext={() => {
              // Go straight to review step when more than one table is selected
              if (selectedTablesList.length > 1) {
                context.goToStep(StepId.ReviewMultipleTables)
                return
              }

              // Get current value to compare and see if user changed the table
              const previousTable = form.getFieldValue('source.selectedTable')
              const selectedTable = selectedTablesList[0]
              if (previousTable && previousTable !== selectedTable) {
                logger('Selected table changed, resetting form values that depend on columns')
                form.setFieldValue('product.columnPolicies', [])
                form.setFieldValue('product.rowPolicies', [])
                form.setFieldValue('selectedTableColumns', [])
                form.setFieldValue('lastUpdatedColumn', '')
                form.setFieldValue('lastUpdatedColumnFormat', '')
                form.setFieldValue('keyColumns', [])
              }
              form.setFieldValue('source.selectedTable', selectedTable)
              getTableSchemaMutation.mutate()
            }}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}

//  TODO: Testing out handling this sort of thing automatically without prompting the user, can add back if needed
// const handleTableChange = async (newTable?: string, previousTable?: string) => {
//   if (previousTable && previousTable !== newTable) {
//     const confirm = window.confirm(
//       'Changing the table will reset the Sharing permissions you have already configured. Are you sure you want to continue?',
//     )
//     if (!confirm) {
//       return
//     }
//     form.setFieldValue('selectedTableColumns', [])
//   }
// }
