import { useMutation, UseQueryResult } from '@tanstack/react-query'
import {
  LakehouseConnectionInput,
  LakehouseTableInput,
  LakehouseTableStatusEnum,
  Uni,
} from '@vendia/management-api-types'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Button from 'src/components/buttons/button'
import VendiaMenuButton from 'src/components/buttons/menu-button'
import Card from 'src/components/containers/card'
import Icon from 'src/components/icons/icon'
import Alert from 'src/components/messages/alert'
import HoverableTooltip from 'src/components/messages/hoverable-tooltip'
import ConfirmationModal from 'src/components/modals/confirmation-modal'
import { Table } from 'src/types/types'
import useApi from 'src/utils/hooks/use-api'
import { useGetLakehouseData } from 'src/utils/hooks/use-get-lakehouse-data'
import notify from 'src/utils/notify'

export type ExecutionStatus = 'RUNNING' | 'SUCCEEDED' | 'FAILED' | 'TIMED_OUT' | 'ABORTED' | 'PENDING_REDRIVE'

const failureStatuses = ['FAILED', 'TIMED_OUT', 'ABORTED'] as ExecutionStatus[]

export interface Job {
  source: JobStatus
}

// Copy/pasted from API
export interface JobStatus {
  status: ExecutionStatus
  startTime: string
  stopTime: string
  error?: string
  errorType?: string
}

// organize executions by data product key
export interface JobsByDataProductKey {
  [key: string]: {
    source: JobStatus
  }[]
}

export const TableCard = ({
  uni,
  nodeName,
  ownerNodeName,
  table,
  connection,
  isOwnedTable,
  setShareDataProduct,
  lakehouseJobsQueryResult,
  isTableReady,
}: {
  uni: Uni
  nodeName: string
  ownerNodeName: string
  table: Table
  connection?: LakehouseConnectionInput
  tableIndex: number
  isOwnedTable: boolean
  setShareDataProduct: (name: string) => void
  lakehouseJobsQueryResult: UseQueryResult<{ getLakehouseJobs: { jobs?: JobsByDataProductKey } }, unknown>
  isTableReady: boolean
}) => {
  const [showSyncModal, setShowSyncModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const jobs = lakehouseJobsQueryResult.data?.getLakehouseJobs?.jobs?.[table.key]
  const sourceTable = table.sourceTableDefinitions[0]
  const api = useApi()
  const { refetch } = useGetLakehouseData()

  const { refetch: refetchLakehouseJobs } = lakehouseJobsQueryResult

  const lakehouseIngestionSFNMutation = useMutation({
    mutationFn: ({ uniName, nodeName, tableKey }: { uniName: string; nodeName: string; tableKey: string }) =>
      api.invokeLakehouseIngestionSFN({ uniName, nodeName, tableKey }),

    onError: (error) => notify.error(`Error syncing table: ${error}`),
    onSuccess: (response) => {
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.invokeLakehouseIngestionSFN) {
        notify.error('Error syncing table')
        return
      }
      notify.success(`Successfully initiated sync for: ${table.name}`)
      setShowSyncModal(false)
      refetchLakehouseJobs()
    },
  })

  const deleteLakehouseTableMutation = useMutation({
    mutationFn: ({ uniName, nodeName, vendiaTableId }: { uniName: string; nodeName: string; vendiaTableId: string }) =>
      api.deleteLakehouseTable({ input: { uniName, nodeName, vendiaTableId } }),

    onError: (error) => notify.error(`Error deleting table: ${error}`),
    onSuccess: (response) => {
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.deleteLakehouseTable) {
        notify.error('Error deleting table')
        return
      }

      refetch()
      setShowDeleteModal(false)
      notify.success(`Successfully deleted table: ${table.name}`)
    },
  })

  const isIncomplete = table.status === LakehouseTableStatusEnum.Incomplete

  // Make spacing a little nicer for demos
  const tableNameWidthClasses = 'w-48 xl:w-72 2xl:w-96'
  const sourceTableNameWidthClasses = 'w-36 xl:w-56 2xl:w-72'

  return (
    <Card key={table.key} className='w-full' padding='none' childrenWrapperClassName={isIncomplete ? '!bg-uibg-4' : ''}>
      <div className='flex w-full justify-between gap-6 p-4 px-6'>
        <div className='flex flex-grow flex-col gap-4'>
          <div className='flex gap-8'>
            <div className='grid place-items-center justify-items-start'>
              <div className='flex items-center gap-4'>
                <Icon name={'table'} size={32} className={'text-purple-900'} />
                {isTableReady ? (
                  <Link
                    to={`../data/tables/${table.name}`}
                    className={clsx('text-link truncate text-lg font-bold', tableNameWidthClasses)}
                  >
                    {table.name}
                  </Link>
                ) : (
                  <div className={clsx('text-light truncate text-lg font-bold', tableNameWidthClasses)}>
                    {table.name}
                  </div>
                )}
              </div>
            </div>
            <div className='flex flex-col gap-2 pr-4'>
              <div className='text-light flex items-center justify-between text-xs'>Table Source</div>
              {isOwnedTable && (
                <>
                  {connection?.type === 'SNOWFLAKE' && (
                    <div className='flex items-center gap-2'>
                      <img src='/images/vendor/snowflake.png' alt='Snowflake logo' className='size-9' />
                      <div className='flex flex-col gap-0.5'>
                        <div className='text-sm font-bold'>Snowflake</div>
                        <div
                          className={clsx(
                            'text-neutral-8 cursor-default truncate text-xs',
                            sourceTableNameWidthClasses,
                          )}
                          title={sourceTable.tableName}
                        >
                          {sourceTable.tableName}
                        </div>
                      </div>
                    </div>
                  )}
                  {connection?.type === 'CLOUDERA' && (
                    <div className='flex items-center gap-2'>
                      <img src='/images/vendor/cloudera.png' alt='Cloudera logo' className='size-9' />
                      <div className='flex flex-col gap-0.5'>
                        <div className='text-sm font-bold'>Cloudera</div>
                        <div
                          className={clsx(
                            'text-neutral-8 cursor-default truncate text-xs',
                            sourceTableNameWidthClasses,
                          )}
                          title={sourceTable.tableName}
                        >
                          {sourceTable.tableName}
                        </div>
                      </div>
                    </div>
                  )}
                  {table?.sourceConnectionType === 'VENDIA' && (
                    <div className='flex items-center gap-2'>
                      <img src='/logo-symbol.svg' alt='Vendia logo' className='size-9' />
                      <div className='flex flex-col gap-0.5'>
                        <div className='text-sm font-bold'>Vendia</div>
                        <div className={clsx('text-neutral-8 truncate text-xs', sourceTableNameWidthClasses)}>
                          {table?.joinQuery && table.joinQuery.from.length > 0 && (
                            <div className='flex flex-nowrap items-center space-x-1'>
                              <Link
                                to={`../data/tables/${table.joinQuery.from[0].table}`}
                                className={'text-light truncate'}
                                title={table.joinQuery.from[0].table}
                              >
                                {table.joinQuery.from[0].table}
                              </Link>
                              {table.joinQuery.from.length > 1 && (
                                <>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='14'
                                    height='14'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='text-neutral-8 flex-shrink-0'
                                  >
                                    <circle cx='8' cy='12' r='7' />
                                    <circle cx='16' cy='12' r='7' />
                                  </svg>
                                  <Link
                                    to={`../data/tables/${table.joinQuery.from[1].table}`}
                                    className={'text-light truncate'}
                                    title={table.joinQuery.from[1].table}
                                  >
                                    {table.joinQuery.from[1].table}
                                  </Link>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {!isOwnedTable && (
                <div className='flex items-center gap-2'>
                  <img src='/logo-symbol.svg' alt='Vendia logo' className='size-9' />
                  <div className='flex flex-col gap-0.5'>
                    <div className='text-sm font-bold'>Vendia Node</div>
                    <div className={clsx('text-neutral-8 truncate text-xs', sourceTableNameWidthClasses)}>
                      {ownerNodeName}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isOwnedTable && !isIncomplete ? (
              <div className='flex min-w-48 flex-col gap-2'>
                <div className='text-light flex items-center justify-between text-xs'>Sync Status</div>
                <DataProductSyncStatus
                  product={table}
                  jobs={jobs}
                  isTableReady={isTableReady}
                  lakehouseJobsQueryResult={lakehouseJobsQueryResult}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className='flex items-center gap-2'>
          {isIncomplete ? (
            <Button kind='secondary' label='Complete setup' to={`../data/update-table/${table.key}`} />
          ) : (
            <>
              <Button
                kind='icon'
                size='small'
                icon={'refresh'}
                label='Sync table'
                onClick={() => setShowSyncModal(true)}
                disabled={!isTableReady || jobs?.[0]?.source.status === 'RUNNING' || !isOwnedTable}
              />
              <Button
                kind='icon'
                size='small'
                icon={'share'}
                label='Share table'
                onClick={() => {
                  setShareDataProduct(table.name)
                }}
                disabled={!isTableReady || !isOwnedTable}
              />
            </>
          )}
          <div className='pl-3'>
            <VendiaMenuButton
              menuItems={[
                <Button
                  key={'delete'}
                  kind='danger'
                  onClick={() => {
                    // Quick low-effort hack TODO: implement this properly
                    setShowDeleteModal(true)
                  }}
                  className='w-full !justify-start'
                  disabled={!isOwnedTable}
                >
                  Delete table
                </Button>,
              ]}
            >
              <Icon name='ellipsis' size='xs' aria-label='Open Actions' />
            </VendiaMenuButton>
          </div>
        </div>
      </div>

      <ConfirmationModal // Sync modal
        isOpen={showSyncModal}
        onClose={() => setShowSyncModal(false)}
        title={`Confirm sync for ${table?.name}`}
        onSubmit={() =>
          lakehouseIngestionSFNMutation.mutate({ uniName: uni.name, nodeName: nodeName, tableKey: table.key })
        }
        isSubmitting={lakehouseIngestionSFNMutation.isPending}
        actionButtonType={`primary`}
        actionButtonText={`Sync`}
        confirmationText={'sync'}
      >
        <div className='grid gap-4'>
          <p>
            Immediately begin syncing the latest data from your configured source to <strong>{table.name}</strong>
          </p>
          <Alert className='text-light text-sm italic'>
            Note: Once started, the sync process cannot be cancelled or undone.
          </Alert>
        </div>
      </ConfirmationModal>
      <ConfirmationModal // Delete modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={`Confirm deletion of ${table?.name}`}
        onSubmit={() =>
          deleteLakehouseTableMutation.mutate({
            uniName: uni.name,
            nodeName: nodeName,
            vendiaTableId: table._id,
          })
        }
        isSubmitting={deleteLakehouseTableMutation.isPending}
        actionButtonText={`Delete`}
        confirmationText={table.name}
        fullConfirmationPrompt={`Type ${table.name} to confirm`}
        fullConfirmationPlaceholder={`Type ${table.name} to confirm`}
      >
        <div className='grid gap-4'>
          <p className='text-sm text-gray-500'>Are you sure you want to delete {table.name}?</p>
          <p className='text-sm text-gray-500'>This action cannot be undone.</p>
        </div>
      </ConfirmationModal>
    </Card>
  )
}

const DataProductSyncStatus = ({
  jobs,
  isTableReady,
  lakehouseJobsQueryResult,
}: {
  product: LakehouseTableInput
  jobs?: JobsByDataProductKey[string]
  isTableReady: boolean
  lakehouseJobsQueryResult: UseQueryResult<{ getLakehouseJobs: { jobs?: JobsByDataProductKey } }, unknown>
}) => {
  const lastSync = jobs?.[0]

  const isLastSyncRunning = lastSync?.source.status === 'RUNNING'

  const isLastSyncSuccess = !isLastSyncRunning && lastSync?.source.status === 'SUCCEEDED'

  let lastSuccessfulSync = lastSync
  if (!isLastSyncSuccess) {
    lastSuccessfulSync = jobs?.find((job) => {
      return job.source.status === 'SUCCEEDED'
    })
  }

  const lastSuccessfulSyncTime = lastSuccessfulSync ? lastSuccessfulSync.source.stopTime : null

  const syncfailureTime = lastSync && !isLastSyncSuccess ? lastSync.source.stopTime : null

  return (
    <div className='text-dark flex flex-col gap-1 text-sm'>
      {lakehouseJobsQueryResult.isLoading ? (
        <>
          <div className='flex gap-1'>
            <div>Last sync: </div>
            <div className='text-light'>loading...</div>
          </div>
          <div className='flex gap-1'>
            <span>Last sync status: </span>
            <span className='text-light'>loading...</span>
          </div>
        </>
      ) : (
        <>
          {isTableReady ? (
            <div className='flex gap-1'>
              <div>Last sync: </div>
              {lastSuccessfulSyncTime ? (
                <HoverableTooltip
                  align='start'
                  content={
                    <span className='font-semibold'>
                      Sync succeeded on {dayjs(lastSuccessfulSyncTime).toDate().toLocaleString()}
                    </span>
                  }
                  side='bottom'
                  sideOffset={10}
                  isModern
                >
                  <span className='font-semibold'>{dayjs(lastSuccessfulSyncTime).fromNow()}</span>
                </HoverableTooltip>
              ) : (
                <div className='font-semibold'>Table not synced yet</div>
              )}
            </div>
          ) : (
            'Creating...'
          )}
          {lastSync && isLastSyncSuccess ? (
            <div className='text-success-13 font-semibold'>
              <span>Last sync status: </span>
              <span className='text-success-12 font-bold'>Success</span>
            </div>
          ) : null}
          {lastSync && isLastSyncRunning ? (
            <HoverableTooltip
              align='start'
              content={
                <div className='flex max-w-xl flex-col gap-1'>
                  <div className='mb-2 font-semibold'>Sync progress</div>
                  <div className='flex justify-between gap-8'>
                    <div>Syncing latest data from source:</div>
                    {lastSync?.source.status === 'RUNNING' ? (
                      <span className='text-information-10 font-semibold'>Syncing...</span>
                    ) : (
                      <span className='text-success-13 font-semibold'>Complete!</span>
                    )}
                  </div>
                </div>
              }
              side='bottom'
              sideOffset={10}
              isModern
            >
              <div className='text-information-13 animate-pulse font-semibold'>
                <span>Last sync status: </span>
                <span className='text-information-12 font-bold'>Syncing...</span>
              </div>
            </HoverableTooltip>
          ) : null}
          {lastSync && !isLastSyncRunning && !isLastSyncSuccess ? (
            <HoverableTooltip
              align='start'
              content={
                <div className='flex max-w-xl flex-col gap-1 text-wrap'>
                  {syncfailureTime ? (
                    <div className='mb-2 font-semibold'>Sync failed {dayjs(syncfailureTime).fromNow()}</div>
                  ) : null}
                  {lastSync?.source.error && (
                    <div>
                      {/* we could parse errors from source.error and show certain messages if any prove to be useful */}
                      An error occurred during our attempt to pull from your data source.
                    </div>
                  )}
                </div>
              }
              side='bottom'
              sideOffset={10}
              isModern
            >
              <div className='text-error-8'>
                <span>Last sync status: </span>
                {lastSync?.source.error && <span className='text-error-8 font-bold'>Error</span>}
              </div>
            </HoverableTooltip>
          ) : null}
        </>
      )}
    </div>
  )
}
