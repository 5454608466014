import { LakehouseTableInput, LakehouseTableStatusEnum } from '@vendia/management-api-types'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import CheckboxGroupField from 'src/components/fields/checkbox-group.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { StepId } from 'src/pages/uni-create/config'
import { isRequiredValidator } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'

export const StepSelectVendiaTables: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const { existingVendiaTables, product, nodeName, uniName } = context.form.useStore((state) => state.values)
  const api = useApi()
  const [isFetchingTableInfo, setIsFetchingTableInfo] = useState(false)
  const navigate = useNavigate()

  const buildDescribeQuery = (table: LakehouseTableInput) => {
    return `SELECT
      column_name,
      data_type
    FROM
      information_schema.columns
    WHERE
      table_name = '${table.name}'`
  }

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Select Vendia tables'
        description={'Select one or more Vendia tables as a source for the new table'}
      />
      <ScrollableStepContent inset className='pt-8'>
        <div className='flex w-full max-w-4xl flex-col gap-4'>
          <CheckboxGroupField
            name='product.selectedVendiaTables'
            options={
              existingVendiaTables
                ? existingVendiaTables
                    .filter((table) => table.status === LakehouseTableStatusEnum.Ready || table.status === undefined)
                    .map((table) => ({ label: table.name, value: table }))
                : []
            }
            validators={lakehouseFlowFieldValidator({
              stepId: StepId.SelectVendiaTables,
              validator: isRequiredValidator(),
            })}
            form={context.form}
          />
        </div>
      </ScrollableStepContent>
      <context.form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting, state.isPristine]}>
        {([canSubmit, isSubmitting, isPristine]) => (
          <StepButtons
            context={context}
            nextBlocked={!canSubmit || isPristine}
            isSubmitting={isFetchingTableInfo}
            hasCancel={true}
            onCancel={() => navigate('../', { relative: 'path' })}
            onNext={async () => {
              let validation = await context.validateFlowState()
              if (!validation.isValid) {
                return
              }
              setIsFetchingTableInfo(true)
              const selectedJoinTables = []
              for (const table of (product.selectedVendiaTables ?? []) as LakehouseTableInput[]) {
                const query = buildDescribeQuery(table)
                const response = await api.fetchLakehouseData({
                  uniName: uniName!,
                  nodeName: nodeName!,
                  sqlText: query,
                })
                selectedJoinTables.push({
                  tableName: table.name,
                  columnsInfo: response?.fetchLakehouseData?.results,
                })
              }
              context.form.setFieldValue('joinTables', selectedJoinTables)
              setIsFetchingTableInfo(false)

              if (product.selectedVendiaTables && product.selectedVendiaTables.length > 1) {
                context.goToStep(StepId.JoinVendiaTables)
              } else {
                // TODO this won't work yet, we haven't really talked about the case where
                // a user is selecting a single Vendia table as a source for a new Vendia table
                context.goToStep(StepId.TableSettings)
              }
            }}
          />
        )}
      </context.form.Subscribe>
    </StepWrapper>
  )
}
