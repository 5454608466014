import { LakehouseTableStatusEnum, ShareAppStatus, Uni } from '@vendia/management-api-types'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import debug from 'debug'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Chatbot from 'src/chatbot'
import { SqlChatbot } from 'src/chatbot-sql'
import Button from 'src/components/buttons/button'
import Card from 'src/components/containers/card'
import Loader from 'src/components/loaders/page-loader'
import EmptyState from 'src/components/messages/empty-state'
import PageHeader from 'src/components/page-header'
import StatusPill from 'src/components/pills/status-pill'
import { useDevSettings } from 'src/pages/developer-settings.page'
import useFeatureToggle, { Features } from 'src/utils/hooks/use-feature-toggle'
import { useGetLakehouseConnections } from 'src/utils/hooks/use-get-lakehouse-connections'
import { useGetLakehouseData } from 'src/utils/hooks/use-get-lakehouse-data'
import { useGetUni } from 'src/utils/hooks/use-get-uni'
import { useInterval } from 'src/utils/hooks/use-interval'

import { ShareTableModal } from './share-data-product-modal'
import { TableCard } from './table-card'
import { useLakehouseJobs } from './use-lakehouse-jobs'

const logger = debug('app:lakehouse-overview')

dayjs.extend(relativeTime)

enum Status {
  Idle,
  ShareModalOpen,
}

export const PageLakehouseOverview = () => {
  const { data: uniData } = useGetUni()
  const uni = uniData?.getUni as Uni
  const { node: nodeName } = useParams()

  // Enable AI for certain demo unis
  const { isEnabled: isVinnieEnabled, isLoading: isFeatureFlagLoading } = useFeatureToggle(Features.VINNIE)
  const { devSettings } = useDevSettings()
  const isAiEnabledUni = devSettings?.aiEnabledUnis?.includes(uni?.name) && isVinnieEnabled && !isFeatureFlagLoading
  const isAiWithSqlEnabledUni =
    devSettings?.aiWithSqlEnabledUnis?.includes(uni?.name) && isVinnieEnabled && !isFeatureFlagLoading

  const { data } = useGetLakehouseData()
  const shareAppConfig = data?.currNodeConfig
  const shareApps = data?.rawShareApps ?? []
  const currNodeShareApp = data?.currNodeShareApp
  const tables = data?.tables ?? []
  const refetchShareApps = data?.refetchShareApps
  const useGetLakehouseConnectionsResponse = useGetLakehouseConnections()
  const connections = useGetLakehouseConnectionsResponse.data?.connections ?? []

  const [selectedTableToShare, setSelectedTableToShare] = useState<string | null>(null)

  // If any table exists, the base Share App has deployed, so the page doesn't need to be disabled
  const isShareAppDeploying =
    currNodeShareApp &&
    [ShareAppStatus.Pending, ShareAppStatus.Deploying].includes(currNodeShareApp.shareAppStatus) &&
    tables.length === 0

  const isTableCreating = tables.some((table) => table.status === LakehouseTableStatusEnum.Creating)

  const isShareAppError = currNodeShareApp?.shareAppStatus === ShareAppStatus.Error

  const lakehouseJobsQuery = useLakehouseJobs({
    uniName: uni.name,
    nodeName: nodeName!,
    enabled: shareApps.length > 0 && !isShareAppDeploying,
  })

  // While deploying, poll for updates to we can refresh the UI
  useInterval(() => {
    if (
      (currNodeShareApp &&
        [ShareAppStatus.Pending, ShareAppStatus.Deploying].includes(currNodeShareApp.shareAppStatus)) ||
      isTableCreating
    ) {
      logger('Polling for share app updates...')
      refetchShareApps?.()
    }
  }, 30 * 1000)

  // Refetch lakehouse jobs when share apps are done deploying or they'll continue to display "loading..."
  const prevAnyShareAppsDeploying = useRef(isShareAppDeploying)
  useEffect(() => {
    if (prevAnyShareAppsDeploying.current && !isShareAppDeploying) {
      logger('Refetching lakehouse jobs...')
      lakehouseJobsQuery.refetch()
    }
    prevAnyShareAppsDeploying.current = isShareAppDeploying
  }, [isShareAppDeploying])

  const isLakehouseReady = uni?.status === 'RUNNING' && !isShareAppDeploying

  const [status, setStatus] = useState(Status.Idle)

  const openShareTableModal = (name: string) => {
    setSelectedTableToShare(name)
    setStatus(Status.ShareModalOpen)
  }

  const closeShareTableModal = () => {
    setSelectedTableToShare(null)
    setStatus(Status.Idle)
  }

  let statusPill =
    uni?.status && uni.status !== 'RUNNING' ? <StatusPill status={uni.status} data-testid='uni-status' /> : null

  if (!statusPill && (isShareAppDeploying || isShareAppError)) {
    statusPill = (
      <StatusPill status={isShareAppError ? ShareAppStatus.Error : ShareAppStatus.Deploying} data-testid='uni-status' />
    )
  }

  if (!uni || !shareAppConfig) {
    return (
      <div className='grid place-items-center'>
        <Loader />
      </div>
    )
  }

  return (
    <>
      <div className='flex flex-grow flex-col gap-4'>
        <PageHeader
          title={'Tables'}
          titleAfter={statusPill}
          actions={
            <>
              <Button
                className='mr-2'
                kind='secondary'
                icon='sidenav-settings-gear'
                iconSize={14}
                to='../data/connections'
                disabled={!isLakehouseReady}
              >
                Connections
              </Button>
              {tables.length ? (
                <Button
                  icon='plus-m'
                  iconSize={14}
                  to='../data/add-table'
                  disabled={!isLakehouseReady}
                  className='mr-2'
                >
                  Add Table
                </Button>
              ) : null}
              {/* Disable settings link for now because there's just uni name */}
              {/* <Button
                kind='secondary'
                icon='sidenav-settings-gear'
                iconSize={14}
                to='../data/settings'
                disabled={!isLakehouseReady}
              >
                Settings
              </Button> */}
            </>
          }
          testid='uni-name-header'
        />
        <div className='flex p-6'>
          {tables.length ? (
            <div className='flex w-full flex-col gap-4'>
              {tables.map((table, index) => (
                <TableCard
                  key={table.key}
                  uni={uni}
                  nodeName={nodeName!}
                  ownerNodeName={table._owner}
                  table={table}
                  tableIndex={index}
                  connection={connections.find((connection) => connection.id === table.sourceConnectionId)}
                  isOwnedTable={table._owner === nodeName}
                  setShareDataProduct={openShareTableModal}
                  lakehouseJobsQueryResult={lakehouseJobsQuery}
                  isTableReady={table.status === 'READY' || !table.status}
                />
              ))}
            </div>
          ) : (
            <Card isTable>
              <EmptyState
                title='Create tables'
                description='Import tables from multiple sources to securely collaborate, share, and generate insights across data platforms.'
                imageSource='/images/empty-tables.png'
                actions={
                  <>
                    <Button to={'../data/add-table'} disabled={!isLakehouseReady}>
                      Create table
                    </Button>
                    <Button
                      kind='secondary'
                      href={'https://docs.vendia.com/platform/analytical/overview/'}
                      target='_blank'
                    >
                      Documentation
                    </Button>
                  </>
                }
              />
            </Card>
          )}
        </div>
      </div>
      <ShareTableModal
        uni={uni}
        nodeName={nodeName!}
        isOpen={status === Status.ShareModalOpen}
        onClose={closeShareTableModal}
        selectedTable={selectedTableToShare!}
        tables={tables}
      />
      {isAiEnabledUni ? <Chatbot /> : null}
      {isAiWithSqlEnabledUni ? <SqlChatbot /> : null}
    </>
  )
}
