import { Description, Label, Radio } from '@headlessui/react'
import clsx from 'clsx'

import { CardRule } from '../containers/card'

export type RadioOptionProps = {
  option: {
    value: any
    label: string | JSX.Element
    description?: string | JSX.Element
  }
  index?: number
  hasErrors?: boolean
  dividerClassName?: string
  labelClassName?: string
}

function RadioOption({
  option,
  index = 0,
  hasErrors = false,
  dividerClassName = undefined,
  labelClassName = undefined,
}: RadioOptionProps) {
  return (
    <Radio key={JSON.stringify(option.value)} value={option.value} className='w-full'>
      {({ checked }) => {
        return (
          <div className='flex flex-col gap-4'>
            {index > 0 && <CardRule className={clsx(!dividerClassName && '-mx-10', dividerClassName)} />}
            <Label className='flex cursor-pointer items-center gap-2'>
              <div className='mr-2'>
                {checked ? (
                  <div className='ring-brand size-5 rounded-full ring-8 ring-inset' />
                ) : (
                  <div
                    className={clsx(
                      'size-5 rounded-full ring-1 ring-inset',
                      hasErrors ? 'ring-error-8' : 'ring-slate-400',
                    )}
                  />
                )}
              </div>
              <div>
                <div className={labelClassName || 'text-sm font-semibold'}>{option.label}</div>
                {option?.description && (
                  <Description className={'text-light text-sm'}>{option.description}</Description>
                )}
              </div>
            </Label>
          </div>
        )
      }}
    </Radio>
  )
}

export default RadioOption
