import { useForm } from '@tanstack/react-form'
import { useMutation } from '@tanstack/react-query'
import { Uni } from '@vendia/management-api-types'
import Button from 'src/components/buttons/button'
import Form from 'src/components/fields/form'
import ListboxField from 'src/components/fields/listbox.field'
import TextField from 'src/components/fields/text.field'
import TextAreaField from 'src/components/fields/text-area.field'
import Icon from 'src/components/icons/icon'
import BasicModal from 'src/components/modals/modal.basic'
import { Table } from 'src/types/types'
import { createOnBlurValidator, isRequiredOnBlur, TABLE_NAME_REGEX } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import notify from 'src/utils/notify'

export const ShareTableModal = ({
  uni,
  nodeName,
  isOpen,
  onClose,
  tables,
  selectedTable,
  onlyAllowDefaultProduct = false,
}: {
  uni: Uni
  nodeName: string
  isOpen: boolean
  onClose: () => void
  tables: Table[]
  selectedTable: string
  onlyAllowDefaultProduct?: boolean
}) => {
  const api = useApi()
  const shareDataProduct = useMutation({
    mutationFn: async (values: { tableName: string; distributionName: string; inviteeEmail: string }) => {
      const { tableName, distributionName, inviteeEmail } = values

      const syncTableToDistributionResponse = await api.syncTableToDistribution({
        uniName: uni.name,
        nodeName,
        distributionName,
        tableName,
      })
      const offerDistributionAccessAccessResponse = await api.offerDistributionAccess({
        uniName: uni.name,
        nodeName,
        distributionName,
        offeredEmail: inviteeEmail,
      })
      return { syncTableToDistributionResponse, offerDistributionAccessAccessResponse }
    },
    onSuccess: (responses) => {
      const { syncTableToDistributionResponse, offerDistributionAccessAccessResponse } = responses

      if (syncTableToDistributionResponse.errors) {
        notify.error(`Failed to sync table: ${syncTableToDistributionResponse?.errors?.[0]?.message}`)
        return
      }

      if (offerDistributionAccessAccessResponse.errors) {
        notify.error(`Failed to offer access: ${offerDistributionAccessAccessResponse?.errors?.[0]?.message}`)
        return
      }

      sharingForm.reset()
      onClose()
      notify.success('Invite sent successfully!')
    },
    onError: (error) => {
      console.error('Error sharing data product:', error)
      notify.error('Failed to share data product.')
    },
  })

  type SharingFormValues = {
    table: string
    dataProductName: string
    inviteeEmail: string
    format: string
  }

  const sharingForm = useForm<SharingFormValues>({
    onSubmit: async ({ value }) => {
      await shareDataProduct.mutateAsync({
        tableName: value.table,
        distributionName: value.dataProductName,
        inviteeEmail: value.inviteeEmail,
      })
      sharingForm.reset()
    },
    defaultValues: {
      table: selectedTable,
      dataProductName: '',
      inviteeEmail: '',
      format: 'Iceberg',
    },
  })

  return (
    <BasicModal
      className='!max-w-2xl lg:!max-w-3xl'
      title='Share table as Data Product'
      isOpen={isOpen}
      onClose={() => {
        sharingForm.reset()
        onClose()
      }}
      buttons={
        <div className='flex gap-4'>
          <Button kind='secondary' onClick={onClose}>
            Cancel
          </Button>
          <sharingForm.Subscribe selector={(state) => state.isSubmitting}>
            {(isSubmitting) => {
              return (
                <Button type='submit' form='sharingForm' data-testid='invite-submit-button' disabled={isSubmitting}>
                  {isSubmitting && <Icon isSpinning name='refresh' size={'xxs'} className='mr-1' />}
                  Send invite
                </Button>
              )
            }}
          </sharingForm.Subscribe>
        </div>
      }
    >
      <Form<SharingFormValues> className='flex-1 space-y-4 overflow-auto p-4' form={sharingForm} id='sharingForm'>
        <div className='flex flex-col gap-3'>
          {onlyAllowDefaultProduct ? null : (
            <ListboxField
              form={sharingForm}
              name='table'
              className='min-w-72'
              label='Select a table'
              options={tables.map((table) => ({
                value: table.name,
                label: table.name,
              }))}
              validators={isRequiredOnBlur}
            />
          )}
          <TextField
            form={sharingForm}
            name='inviteeEmail'
            label='Invitee email'
            description='Enter the email of the user you want to share this data product with.'
            validators={isRequiredOnBlur}
          />
          <TextField
            form={sharingForm}
            name='dataProductName'
            label='Data product name'
            description='Provide a name for the data product.'
            validators={createOnBlurValidator(
              {
                pattern: TABLE_NAME_REGEX, // TODO: Decide a different regex for data product name
                message:
                  'Data product name must start with a lowercase letter and contain only lowercase letters, numbers, and underscores',
              },
              false,
            )}
          />
          <TextAreaField
            form={sharingForm}
            name='description'
            label='Data product description'
            description='Provide a brief description of the data product.'
          />
          <ListboxField
            form={sharingForm}
            name='format'
            className='min-w-72'
            label='Data product format'
            description='Select the format of the data product.'
            options={[
              { value: 'Iceberg', label: 'Iceberg' },
              { value: 'Delta Lake', label: 'Delta Lake' },
              { value: 'CSV', label: 'CSV' },
            ]}
            validators={isRequiredOnBlur}
          />
        </div>
      </Form>
    </BasicModal>
  )
}
