import { useEffect, useState } from 'react'
import ListboxField from 'src/components/fields/listbox.field'
import { FlowContext } from 'src/components/flows/types'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'

export const JoinTableSelector = ({ context }: { context: FlowContext<LakehouseFlowStepValues> }) => {
  const form = context.form
  const product = form.useStore((s) => s.values.product)
  const availableTables = product?.selectedVendiaTables?.map((table: any) => table.name) || []
  const JOIN_TYPES = ['LEFT JOIN', 'RIGHT JOIN', 'INNER JOIN', 'FULL OUTER JOIN'] // , 'LEFT OUTER JOIN', 'RIGHT OUTER JOIN']
  const [joinTypeDescription, setJoinTypeDescription] = useState('')

  const getJoinTypeDescription = (joinType: string) => {
    switch (joinType) {
      case 'LEFT JOIN':
        return `LEFT JOIN: Returns all rows from ${product?.queryDefinition?.leftTable?.table || 'left table'} and matching rows from ${product?.queryDefinition?.rightTable?.table || 'right table'}. If no match is found, NULL values are returned for ${product?.queryDefinition?.rightTable?.table || 'right table'} columns.`
      case 'RIGHT JOIN':
        return `RIGHT JOIN: Returns all rows from ${product?.queryDefinition?.rightTable?.table || 'right table'} and matching rows from ${product?.queryDefinition?.leftTable?.table || 'left table'}. If no match is found, NULL values are returned for ${product?.queryDefinition?.leftTable?.table || 'left table'} columns.`
      case 'INNER JOIN':
        return `INNER JOIN: Returns only matching rows from both tables that match the conditions below.`
      case 'FULL OUTER JOIN':
        return `FULL OUTER JOIN: Returns all rows from both tables. If there is no match, NULL values are returned for the missing side.`
      // case 'LEFT OUTER JOIN':
      //   return `LEFT OUTER JOIN: Returns all rows from ${product?.queryDefinition?.leftTable?.table || 'left table'} and matching rows from ${product?.queryDefinition?.rightTable?.table || 'right table'}. If no match is found, NULL values are returned for ${product?.queryDefinition?.rightTable?.table || 'right table'} columns.`
      // case 'RIGHT OUTER JOIN':
      //   return `RIGHT OUTER JOIN: Returns all rows from ${product?.queryDefinition?.rightTable?.table || 'right table'} and matching rows from ${product?.queryDefinition?.leftTable?.table || 'left table'}. If no match is found, NULL values are returned for ${product?.queryDefinition?.leftTable?.table || 'left table'} columns.`
      default:
        return `Select a join type to see its description.`
    }
  }

  useEffect(() => {
    const currentJoinType = product?.queryDefinition?.joinType || 'LEFT JOIN'
    setJoinTypeDescription(getJoinTypeDescription(currentJoinType))
  }, [
    product?.queryDefinition?.joinType,
    product?.queryDefinition?.leftTable?.table,
    product?.queryDefinition?.rightTable?.table,
  ])

  const handleTableSelection = (side: 'left' | 'right', value: string) => {
    const tableDefinition = {
      nodeName: product.selectedVendiaTables?.find((table: any) => table.name === value)?.vendiaTableOwner,
      selectedColumns: [],
      allColumns: product.selectedVendiaTables?.find((table: any) => table.name === value)?.sourceTableDefinitions[0]
        .columns,
      table: value,
    }

    form.setFieldValue(`product.queryDefinition.${side}Table`, tableDefinition)
  }

  return (
    <>
      <h2 className='text-dark mb-3 text-lg font-bold'>Join Tables</h2>
      <div className='grid grid-cols-[1fr,auto,1fr] gap-4'>
        <div>
          <ListboxField
            name='leftTableSelector'
            label='Left Table'
            options={availableTables.map((name: any) => ({ label: name, value: name }))}
            value={product?.queryDefinition?.leftTable?.table}
            wrapperClassName='w-full'
            form={form}
            onChange={(value) => {
              if (value) {
                handleTableSelection('left', value)
              }
            }}
          />
        </div>
        <div className='w-32'>
          <ListboxField
            name='product.queryDefinition.joinType'
            label='Join Type'
            options={JOIN_TYPES.map((type) => ({ label: type, value: type }))}
            defaultValue={JOIN_TYPES[0]}
            form={form}
            wrapperClassName='w-full'
            data-testid='join-type-select'
          />
        </div>
        <div>
          <ListboxField
            name='rightTableSelector'
            label='Right Table'
            options={availableTables.map((name: any) => ({ label: name, value: name }))}
            value={product?.queryDefinition?.rightTable?.table}
            wrapperClassName='w-full'
            form={form}
            onChange={(value) => {
              if (value) {
                handleTableSelection('right', value)
              }
            }}
          />
        </div>
      </div>

      <p className='text-light mt-4 text-sm leading-relaxed'>{joinTypeDescription}</p>
    </>
  )
}
