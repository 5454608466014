import useFeatureToggle, { Features } from 'src/utils/hooks/use-feature-toggle'
import { POSTFIX, useDocumentTitle } from 'src/utils/hooks/use-title'

import StandardFlow from './flow-standard/standard.flow'

export default function UniCreate() {
  useDocumentTitle(`Create Uni - ${POSTFIX}`)

  const { isLoading } = useFeatureToggle(Features.DATA_DISTRO)

  if (isLoading) {
    return null
  }

  return <StandardFlow />
}

/* WIP, finish after react-router 6.4 upgrade and move to "data APIs" (createBrowserRouter) */
/* AND/OR serialize and save state to localStorage so we can restore where they left off */
/* 
<BasicModal
  title='Are you sure you want to leave?'
  isOpen={blocker.state === 'blocked'}
  onClose={() => {
    blocker.reset()
  }}
  buttons={
    <div className='flex gap-2'>
      <Button kind={'secondary'} onClick={() => blocker.reset()}>
        Cancel
      </Button>
      <Button
        kind={'primary'}
        onClick={() => {
          blocker.proceed()
        }}
      >
        Yes, I'd like to leave
      </Button>
    </div>
  }
>
  <p>You have unsaved changes. Are you sure you want to leave?</p>
</BasicModal> 
*/
