import { useForm } from '@tanstack/react-form'
import { LakehouseTableInput, Uni } from '@vendia/management-api-types'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Button from 'src/components/buttons/button'
import Card, { CardRule } from 'src/components/containers/card'
import Form from 'src/components/fields/form'
import MonacoEditorField from 'src/components/fields/monaco-editor.field'
import Icon from 'src/components/icons/icon'
import { SharingPoliciesCard } from 'src/components/lakehouse/tables/sharing-policies-card'
import { SyncStatusCard } from 'src/components/lakehouse/tables/sync-status-card'
import { TableDetailsCard } from 'src/components/lakehouse/tables/table-details-card'
import PageLoader from 'src/components/loaders/page-loader'
import PageHeader from 'src/components/page-header'
import { BasicTable } from 'src/components/tables/basic-table'
import { LakehouseDistributionAccess, LakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { Table } from 'src/types/types'
import { isRequiredOnBlur } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { useGetLakehouseData } from 'src/utils/hooks/use-get-lakehouse-data'
import { useGetUni } from 'src/utils/hooks/use-get-uni'

import { ShareTableModal } from './share-data-product-modal'

const PROD_DEMO_UNIS = ['crm-airlines-demo-nnkqmj.unis.vendia.com']

enum State {
  INITIALIZED,
  FETCHING_DATA,
  DATA_FETCH_SUCCESSFUL,
  DATA_FETCH_FAILED,
}

// Both SQL safe and lowercase (for glue database/table names)
function getSafeUniNodeName(uniName: string, nodeName: string) {
  return `${uniName}_${nodeName}`.replace(/-/g, '_').replace(/\./g, '_').toLowerCase()
}

export const PageLakehouseTable = () => {
  const { data: uniData } = useGetUni()
  const uni = uniData?.getUni as Uni | undefined
  const { table: tableName } = useParams<{ table: string; node: string }>()
  const { data: lakehouseData } = useGetLakehouseData()
  const shareAppConfig = lakehouseData?.currNodeConfig
  const tables = lakehouseData?.tables ?? []
  const table = tables.find((p) => p.name === tableName)

  if (!uni || !shareAppConfig || !table) {
    return <PageLoader />
  }

  return <PageLakehouseTableContent uni={uni} shareAppConfig={shareAppConfig} table={table} />
}

const PageLakehouseTableContent = ({
  uni,
  table,
  shareAppConfig,
}: {
  uni: Uni
  table: Table
  shareAppConfig: LakehouseShareAppConfig
}) => {
  const { table: tableName, node: nodeName } = useParams<{ table: string; node: string }>()

  type LakehouseData = {
    [key: string]: any
  }
  const [data, setData] = useState<LakehouseData[]>([])
  const api = useApi()
  const [state, setState] = useState(State.INITIALIZED)
  const [expanded, setExpanded] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  // Database name should match the _owner_ node of the table
  const databaseName = `${getSafeUniNodeName(uni.name, table._owner)}_db`

  const buildSqlQuery = (table: LakehouseTableInput) => {
    return `SELECT * FROM ${databaseName}.${table.name} limit 10;`
  }

  // Hack to query table owner node rather than replicated table on THIS node
  //  just for the prod demo uni that is older and replication is broken
  const nodeToQuery = PROD_DEMO_UNIS.includes(uni.name) ? table._owner : nodeName

  const form = useForm<any>({
    defaultValues: {
      warehouse_sql_editor: buildSqlQuery(table),
    },
    onSubmit: async ({ value }: any) => {
      setState(State.FETCHING_DATA)

      const response = await api.fetchLakehouseData({
        uniName: uni.name,
        nodeName: nodeToQuery!,
        sqlText: value.warehouse_sql_editor?.trim(),
      })
      if (response.errors?.length) {
        setErrorMessage(response.errors[0].message)
        setState(State.DATA_FETCH_FAILED)
        return
      }
      setData(response.fetchLakehouseData.results)
      setState(State.DATA_FETCH_SUCCESSFUL)
    },
  })

  const columnHeaders = Object.keys(data?.[0] ?? {})
  const rows =
    data?.map((row) => {
      // map over headers to ensure order and handle missing values
      return columnHeaders.map((header) => row[header])
    }) ?? []

  useEffect(() => {
    form.handleSubmit()
  }, [])

  return (
    <div className='flex flex-grow flex-col gap-4'>
      <PageHeader
        title={`${tableName}`}
        aboveTitle={
          <Link to='../' className='text-link -mt-3 mb-6 flex items-center text-xs font-bold'>
            <Icon name='chevron-left' className={'mr-2'} size={16} />
            Return to dashboard
          </Link>
        }
        testid='product-name-header'
        image={<img src='/images/data-product.svg' alt='Data set illustration' className='size-10' />}
      />
      <div className='flex gap-6 p-6'>
        <div className='w-2/3'>
          <Card className='w-full' padding='none' overflow='visible'>
            <Form form={form}>
              <div>
                <div className='flex items-center justify-between p-6 text-sm font-bold'>
                  <h2>Query your data with SQL</h2>
                  <div className='flex gap-2'>
                    <Button kind='icon' size='small' icon='play' label='Run sql query' type='submit' />
                    <Button
                      kind='icon'
                      size='small'
                      icon={expanded ? 'chevron-up' : 'chevron-down'}
                      label='Toggle query details'
                      onClick={() => setExpanded(!expanded)}
                    />
                  </div>
                </div>
                {expanded && (
                  <div>
                    <MonacoEditorField
                      form={form}
                      language='sql'
                      name='warehouse_sql_editor'
                      validators={isRequiredOnBlur}
                      minHeight={150}
                      options={{
                        lineNumbers: 'on' as any,
                        padding: { top: 20 },
                      }}
                      theme='vs-dark'
                      className='overflow-hidden'
                      wrapperClassName='!mb-0'
                    />
                  </div>
                )}
              </div>
            </Form>
          </Card>
          <Card className='mt-6 w-full' padding='none'>
            <div className='flex flex-col gap-4'>
              {state === State.INITIALIZED && <div className='p-6'>Run a query to see results</div>}
              {state === State.FETCHING_DATA && (
                <div className='flex min-h-48 items-center justify-center text-center'>
                  <PageLoader />
                </div>
              )}
              {state === State.DATA_FETCH_SUCCESSFUL && (
                <div>
                  {rows.length === 0 ? (
                    <div className='border-uiBg-3 bg-uibg-0 flex min-h-48 items-center justify-center border'>
                      No data found
                    </div>
                  ) : (
                    <BasicTable columnHeaders={columnHeaders} rows={rows} data-testid='query-results-table' />
                  )}
                </div>
              )}
              {state === State.DATA_FETCH_FAILED && <div className='p-6'>{errorMessage}</div>}
            </div>
          </Card>
        </div>
        <div className='w-1/3 space-y-6'>
          <SyncStatusCard uni={uni} nodeName={nodeName!} table={table} />
          <SharingPoliciesCard table={table} />
          <TableDetailsCard uni={uni} nodeName={nodeToQuery!} tableKey={table.key} />
          {/* TODO: hiding this until we get back on enabling distribution */}
          {/* <InvitesCard uni={uni} nodeName={nodeName!} product={table} shareAppConfig={shareAppConfig} /> */}
        </div>
      </div>
    </div>
  )
}

const InvitesCard = ({
  uni,
  nodeName,
  product,
  shareAppConfig,
}: {
  uni: Uni
  nodeName: string
  product: LakehouseTableInput
  shareAppConfig: LakehouseShareAppConfig
}) => {
  const [expanded, setExpanded] = useState(false)
  const [showShareTableModal, setShowShareTableModal] = useState(false)

  const api = useApi()

  const invites = shareAppConfig?.distributions?.flatMap((d) => d.distributionAccesses) ?? []

  const RenderInviteStatus = (invite: LakehouseDistributionAccess) => {
    const days = dayjs(invite.inviteTokenTtl).diff(dayjs(), 'day')
    const hours = dayjs(invite.inviteTokenTtl).diff(dayjs(), 'hour') % 24
    const minutes = dayjs(invite.inviteTokenTtl).diff(dayjs(), 'minute') % 60

    const isExpired = dayjs(invite.inviteTokenTtl).isBefore(dayjs())
    const timeLeft = days > 0 ? `${days} days left` : hours > 0 ? `${hours} hours left` : `${minutes} minutes left`

    // TODO: Invite status no longer exists - could use accessGrant within a distribution to determine if an invite has been accepted
    // switch (invite.status) {
    //   case 'PENDING_CONNECTION':
    //     if (isExpired) {
    //       return (
    //         <div
    //           className='text-link cursor-pointer text-base font-bold hover:text-[#0b2e56]'
    //           onClick={() => setShowShareTableModal(true)}
    //         >
    //           Resend
    //         </div>
    //       )
    //     }
    //     return <div className='text-base font-bold text-gray-600'>{timeLeft}</div>
    //   case 'CONNECTION_REQUESTED':
    //     return <div className='text-base font-bold text-gray-600'>Connection requested</div>
    //   case 'CONNECTED':
    //     return <div className={`text-success-13 text-base font-bold`}>Accepted</div>
    //   case 'CONNECTION_FAILED':
    //     return <div className='text-error-8 text-base font-bold'>Failed</div>
    //   default:
    //     return <div></div>
    // }
    return <div></div>
  }

  const InviteItem = ({ invite }: { invite: LakehouseDistributionAccess }) => {
    return (
      <div className='flex items-center justify-between gap-3 text-nowrap'>
        <div className='max-w-72 truncate text-base font-bold'>{invite.id}</div>
        <div>{RenderInviteStatus(invite)}</div>
      </div>
    )
  }

  return (
    <>
      <Card className='w-full' padding='none' overflow='visible'>
        <div className='flex flex-col gap-4'>
          <div className='flex items-center justify-between p-6 text-sm font-bold'>
            <h4>Invites</h4>
            <div className='flex gap-2'>
              <Button
                kind='icon'
                size='small'
                icon={'share'}
                label='Share table'
                onClick={() => {
                  setShowShareTableModal(true)
                }}
              />
              <Button
                kind='icon'
                size='small'
                icon={expanded ? 'chevron-up' : 'chevron-down'}
                label='Toggle details'
                onClick={() => setExpanded(!expanded)}
              />
            </div>
          </div>
          {expanded && (
            <div>
              <CardRule />
              <div className='bg-uibg-0 p-6'>
                <div className='grid'>
                  {!invites ? (
                    <div>No invites found</div>
                  ) : (
                    <ul className='space-y-4'>
                      {invites.map((invite, index) => (
                        <li key={index}>
                          <InviteItem invite={invite} />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </>
  )
}
