import { LakehouseConnectionTypeEnum } from '@vendia/management-api-types'
import debug from 'debug'
import { useNavigate } from 'react-router'
import Card from 'src/components/containers/card'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'

const logger = debug('app:reviewMultiTable')

export const StepReviewMultipleTables: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form
  const navigate = useNavigate()

  const { source, selectedTables } = form.useStore((s) => s.values)
  const tablesToList = Object.entries(selectedTables)
    .filter(([_, value]) => value)
    .map(([key]) => key)

  logger('selectedTable', selectedTables)

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Review tables'
        description={'Take a moment to verify the tables to import.'}
      />
      <ScrollableStepContent inset>
        <Card
          title={'Tables'}
          subTitle='You can customize the names, permissions, and data filters for these tables after clicking “Finish”'
          className='min-h-64 w-full max-w-4xl rounded-md'
          contentExpandVertical
        >
          <div className='flex w-full flex-grow flex-col gap-4'>
            {tablesToList.map((table, index) => (
              <div key={index} className='flex items-center gap-4 text-lg font-bold'>
                {source.connection === LakehouseConnectionTypeEnum.Snowflake && (
                  <img src='/images/vendor/snowflake.png' alt='Snowflake logo' className='size-8' />
                )}
                {source.connection === LakehouseConnectionTypeEnum.Cloudera && (
                  <img src='/images/vendor/cloudera.png' alt='Cloudera logo' className='size-8' />
                )}
                <span>{table}</span>
              </div>
            ))}
          </div>
        </Card>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.isSubmitting]}>
        {([isSubmitting]) => (
          <StepButtons
            context={context}
            isLastStep
            isSubmitting={isSubmitting}
            hasCancel={true}
            onCancel={() => navigate('../', { relative: 'path' })}
            onNext={context.handleSubmit}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
