import clsx from 'clsx'
import { RelativeRoutingType } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import Icon from 'src/components/icons/icon'

export interface Link {
  name: string | JSX.Element
  path: string
  description?: string
  testid?: string
  relative?: RelativeRoutingType
  disabled?: boolean
}

interface Props {
  basePath?: string
  backLink?: Link
  links?: Link[]
  isActiveLooseMatch?: boolean
}

export default function SideNav({ basePath = '', links, backLink, isActiveLooseMatch }: Props) {
  const location = useLocation()

  // const { id: uniName, node: nodeName } = useParams()
  if (basePath && !basePath.endsWith('/')) {
    throw new Error('basePath should include a trailing slash')
  }
  const enabledClasses = 'cursor-pointer hover:bg-tint hover:text-brand hover:opacity-90 text-black'
  const baseLinkClasses = clsx('flex items-center p-2 pl-10 text-center text-sm font-semibold')
  const backLinkClasses = clsx(baseLinkClasses, enabledClasses)
  const linkListItems = links?.map((link, i) => {
    const to = `${basePath}${link.path}`
    // Normalize trailing slash and compare to find active path
    const currentPath = location.pathname.replace(/\/+$/, '')
    const currentLinkPath = to.replace(/\/+$/, '')
    let isActive = currentPath.endsWith(currentLinkPath)
    if (isActiveLooseMatch) {
      isActive = currentPath.includes(currentLinkPath)
    }
    const linkClasses = clsx(
      baseLinkClasses,
      isActive && `bg-tint text-brand font-bold`,
      !link.disabled && enabledClasses,
      link.disabled && 'text-light cursor-not-allowed',
    )
    const linkInner = (
      <li className={linkClasses} data-testid={link.testid ?? 'side-nav-item'} key={i}>
        {typeof link.name === 'string' ? <span className='truncate'>{link.name}</span> : link.name}
      </li>
    )
    if (link.disabled) {
      return linkInner
    }
    return (
      <Link to={to} aria-label={link.description} key={link.path ?? 'no-path-provided?'}>
        {linkInner}
      </Link>
    )
  })
  return (
    <div className={'w-52 flex-none bg-white pt-8'}>
      <ul className='flex flex-col gap-4'>
        {backLink && (
          <Link
            key={backLink.path}
            to={`${basePath}${backLink.path}`}
            aria-label={backLink.description}
            relative={backLink.relative}
          >
            <li className={backLinkClasses} data-testid={backLink.testid ?? 'side-nav-item'}>
              <Icon name='chevron-left' size='s' className='!absolute left-2' />
              {backLink.name}
            </li>
          </Link>
        )}
        {linkListItems}
      </ul>
    </div>
  )
}
