import { LakehouseSyncScheduleEnum } from '@vendia/management-api-types'
import { ExecutionStatus } from 'src/pages/uni-view/lakehouse/table-card'

import { AWS_REGIONS } from '../csp/regions'
import { getSafeUniNodeName } from './share-apps.utils'

export function formatListAsPhrase(items: string[] = []): string {
  switch (items.length) {
    case 0:
      return ''
    case 1:
      return items[0]
    case 2:
      return `${items[0]} and ${items[1]}`
    default:
      return `${items.slice(0, -1).join(', ')}, and ${items[items.length - 1]}`
  }
}

export function getPrettyProductRegions(productValues: any): string {
  const productRegions: string[] = productValues?.regions
  const prettyRegions = productRegions?.map((region: string) => AWS_REGIONS.find((r) => r.value === region)!.label)
  return formatListAsPhrase(prettyRegions)
}

export const getIcebergTableName = (uniName: string, nodeName: string, tableKey: string): string => {
  const prefix = 'iceberg'
  const safeUniNodeName = getSafeUniNodeName(uniName, nodeName)
  return `${prefix}_${safeUniNodeName}_${tableKey}`.toLowerCase()
}

export const getFriendlyJobStatus: Record<ExecutionStatus, string> = {
  RUNNING: 'Running',
  SUCCEEDED: 'Success',
  FAILED: 'Failure',
  TIMED_OUT: 'Failure',
  ABORTED: 'Failure',
  PENDING_REDRIVE: 'Failure',
}

export const getFriendlyScheduleName: Record<LakehouseSyncScheduleEnum, string> = {
  [LakehouseSyncScheduleEnum.OnDemand]: 'On-demand',
  [LakehouseSyncScheduleEnum.Daily]: 'Daily',
  [LakehouseSyncScheduleEnum.Weekly]: 'Weekly',
  [LakehouseSyncScheduleEnum.Monthly]: 'Monthly',
}
