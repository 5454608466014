import { useQuery } from '@tanstack/react-query'
import { DataPolicyColumnEffect, LakehouseConnectionTypeEnum } from '@vendia/management-api-types'
import clsx from 'clsx'
import debug from 'debug'
import { useNavigate } from 'react-router'
import Card, { CardRule } from 'src/components/containers/card'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { ProductPolicies } from 'src/components/lakehouse/product-policies'
import PageLoader from 'src/components/loaders/page-loader'
import { BasicTable } from 'src/components/tables/basic-table'
import useApi from 'src/utils/hooks/use-api'
import { LakehouseFlowStepValues, LakehouseTableForm } from 'src/utils/lakehouse/types'

import { lakehouseRegionOptions, snowflakeToAwsRegionMap } from '../utils'

const logger = debug('app:reviewSharingPermissions')

export const StepReviewSharingPermissions: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const api = useApi()
  const navigate = useNavigate()
  const form = context.form

  const {
    source,
    product,
    uniName,
    nodeName,
    selectedTableColumns,
    flowSteps,
    currStepIndex,
    currStep,
    connectionId,
    joinTableKey,
    keyColumns,
  } = form.useStore((state) => state.values)
  const { columnPolicies, defaultColumnPolicyEffect } = product
  const allExcluded =
    (columnPolicies.length === selectedTableColumns.length &&
      columnPolicies?.every((p) => p.effect === DataPolicyColumnEffect.Exclude)) ||
    (columnPolicies.length === 0 && defaultColumnPolicyEffect === DataPolicyColumnEffect.Exclude)

  const { data, isLoading } = useQuery({
    queryKey: ['fetchLakehouseDataPreview', { source, selectedTableColumns, product, joinTableKey }],
    queryFn: async () => {
      const fetchDataInput = {
        connectionId,
        joinTableKey,
        uniName: uniName!,
        nodeName: nodeName!,
        clouderaConnection: source.cloudera ? source.cloudera : undefined,
        snowflakeConnection: source.snowflake
          ? {
              ...source.snowflake,
              role: source.snowflake?.role || '',
              schema: source.snowflake?.schema || '',
            }
          : undefined,
        sourceTableDefinition: {
          columns: selectedTableColumns,
          tableName: source.selectedTable,
          keyColumns: keyColumns,
        },
        tableDefinition: {
          columnPolicies: product.columnPolicies,
          defaultColumnPolicyEffect: product.defaultColumnPolicyEffect,
          rowPolicies: product.rowPolicies,
        },
        joinQuery: product.joinSql,
      }
      const response = await api.fetchLakehouseDataPreview(fetchDataInput)
      let rawRows: any[] = response?.fetchLakehouseDataPreview ?? []
      const columnHeaders = Object.keys(rawRows?.[0] ?? {})
      const rows =
        rawRows?.map((row: { [key: string]: any }) => {
          // map over headers to ensure order and handle missing values
          return columnHeaders.map((header) => {
            return row[header] && typeof row[header] === 'object' ? JSON.stringify(row[header]) : row[header]
          })
        }) ?? []
      return {
        columnHeaders,
        rows,
      }
    },
    enabled: !allExcluded,
  })

  const { columnHeaders, rows } = data ?? { columnHeaders: [], rows: [] }

  logger('state', {
    source,
    product,
    selectedTableColumns,
    flowSteps,
    currStepIndex,
    currStep,
  })

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Review table settings'
        description='Take a moment to verify the filtering rules and table preview'
      />
      <ScrollableStepContent inset>
        <div className='w-full'>
          <div className='flex w-full gap-6 p-6'>
            {source ? (
              <div className='w-1/3 min-w-[450px] max-w-[600px]'>
                <Card className='mb-6 w-full'>
                  <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-2 pb-2'>
                      <div className='flex flex-col gap-4'>
                        <h5 className='text-neutral-9 text-xs font-bold'>Data source</h5>
                        {source.connection === LakehouseConnectionTypeEnum.Snowflake && (
                          <div className='flex items-center gap-4 truncate'>
                            <img src='/images/vendor/snowflake.png' alt='Snowflake logo' className='size-10' />
                            <div className='flex flex-col gap-0.5'>
                              <div className='font-bold'>Snowflake</div>
                              <div className='text-neutral-8 text-sm'>{product.syncSchedule.replace(/_/g, '-')}</div>
                              <div className='text-neutral-8 text-sm'>
                                {snowflakeToAwsRegionMap[source.sourceRegion]}
                              </div>
                              <div className='text-neutral-8 text-sm'>{source.selectedTable}</div>
                            </div>
                          </div>
                        )}
                        {source.connection === LakehouseConnectionTypeEnum.Cloudera && (
                          <div className='flex items-center gap-4'>
                            <img src='/images/vendor/cloudera.png' alt='Cloudera logo' className='size-10' />
                            <div className='flex flex-col gap-0.5'>
                              <div className='font-bold'>Cloudera</div>
                              <div className='text-neutral-8 text-xs'>{source.selectedTable}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
                <DataProductCard table={product} />
              </div>
            ) : null}
            <div className={clsx('flex w-2/3 min-w-0 flex-col gap-4', source ? 'flex-1' : 'w-full')}>
              <Card className='w-full' padding='none'>
                {isLoading ? (
                  <PageLoader />
                ) : rows.length === 0 || allExcluded ? (
                  <div className='bg-red-100 p-8 text-red-600'>
                    <div className='h4'>Empty Table</div>
                    <div className='text-base'>
                      It looks like there would be no data in this table. Please review filtering rules.
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='p-6'>
                      <div className='flex flex-col gap-1'>
                        <div className='text-lg font-bold'>Table Preview</div>
                        <div className='text-neutral-10 text-sm'>
                          Here is a sample of data from your selected table with the chosen data filtering rules. To
                          make changes, return to the previous steps and update the filtering rules.
                        </div>
                      </div>
                    </div>
                    <BasicTable columnHeaders={columnHeaders} rows={rows} />
                  </>
                )}
              </Card>
            </div>
          </div>
        </div>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.isSubmitting]}>
        {([isSubmitting]) => (
          <StepButtons
            context={context}
            isLastStep
            isSubmitting={isSubmitting}
            hasCancel={true}
            onCancel={() => navigate(`/uni/${uniName}/${nodeName}/data`)}
            onNext={context.handleSubmit}
            nextBlocked={isLoading || rows.length === 0} // Prevent app creation if table would be empty
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}

const DataProductCard = ({ table }: { table: LakehouseTableForm }) => {
  return (
    <Card className='w-full' padding='none'>
      <div className='flex w-full flex-col justify-between gap-6 p-6'>
        <div className='flex flex-grow flex-col gap-4'>
          <div className='flex items-center gap-6'>
            <img src='/images/data-product.svg' alt='Data set illustration' className='size-10' />
            <div className='flex flex-col gap-1'>
              <div className='text-lg font-bold'>{table.name}</div>
            </div>
          </div>
        </div>
      </div>
      <CardRule />
      <ProductPolicies table={table} />
    </Card>
  )
}
