import { useMutation } from '@tanstack/react-query'
import { LakehouseClouderaConnectionInput, LakehouseConnectionTypeEnum } from '@vendia/management-api-types'
import debug from 'debug'
import { useNavigate } from 'react-router'
import TextField from 'src/components/fields/text.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { createOnChangeValidator, isRequiredValidator } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { useFirstFocus } from 'src/utils/hooks/use-first-focus'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'
import notify from 'src/utils/notify'

import { StepId } from '../config'

const logger = debug('app:Cloudera')

export const StepConnectCloudera: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form
  const api = useApi()
  const navigate = useNavigate()
  const nameRef = useFirstFocus()

  const { uniName, nodeName } = form.useStore((s) => s.values)
  const connection = form.useStore((s) => s.values.source?.cloudera)
  const connectionType = form.useStore((s) => s.values.source?.connection)

  const getTablesMutation = useMutation({
    mutationFn: () =>
      api.getLakehouseTables({
        uniName: uniName!,
        nodeName: nodeName!,
        connectionInput: {
          type: LakehouseConnectionTypeEnum.Cloudera,
          clouderaConnection: {
            ...(connection as LakehouseClouderaConnectionInput),
          },
        },
      }),
    onError: (error) => notify.error(`Error testing Cloudera connection: ${error}`),
    onSuccess: (response) => {
      logger('cloudera connection response', response)
      if (!response.getLakehouseTables?.tables?.length || response.errors) {
        notify.error(response.errors ? response.errors[0].message : 'No tables found with provided inputs')
        return
      }
      form.setFieldValue('source.availableTables', response.getLakehouseTables.tables as string[])
      form.setFieldValue('source.sourceRegion', 'us-east-1')
      context.goToStep(StepId.SelectTable)
    },
  })

  const name = 'source.cloudera'
  const validateJdbcUrlDatabase = (): boolean => {
    if (!connection) return false
    const jdbcUrl = connection.jdbcUrl
    const databaseField = connection.database
    const regex = /jdbc:hive2:\/\/[^\/:]+(:\d+)?(\/([^;]*))?/i // 3 parts: prefix, host + optional port, then the optional database name
    const match = jdbcUrl.match(regex)
    const jdbcDatabase = match && match[3] ? match[3] : 'default' // if there is no database listed in the jdbc, assume default
    return jdbcDatabase.toLowerCase() === databaseField.toLowerCase()
  }

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Connect to Cloudera'
        description={'Let’s authenticate your Cloudera account'}
      />
      <ScrollableStepContent inset>
        <div className='mt-4 w-full max-w-4xl'>
          <div className='flex w-full flex-col gap-4'>
            <TextField
              ref={nameRef}
              name={`connectionName`}
              label='Name'
              description='Easily identify and reuse this connection to ingest additional tables.'
              form={form}
              useNestedLabel
              maxLength={64}
              validators={createOnChangeValidator(
                {
                  pattern: /^(?!\s*$).{1,64}$/,
                  message: 'The connection name must be between 1 and 64 characters long.',
                },
                true,
              )}
            />
            <TextField
              label='JDBC URL'
              description='The Hive JDBC URL for your Cloudera CDP warehouse'
              name={`${name}.jdbcUrl`}
              type='text'
              form={form}
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectCloudera,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connectionType === LakehouseConnectionTypeEnum.Cloudera),
              })}
              useNestedLabel
            />
            <TextField
              label='Atlas URL'
              description='The Atlas URL for your Cloudera CDP warehouse'
              name={`${name}.atlasUrl`}
              type='text'
              form={form}
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectCloudera,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connectionType === LakehouseConnectionTypeEnum.Cloudera),
              })}
              useNestedLabel
            />
            <TextField
              label='Username'
              description='The username for the Cloudera account you want to connect with'
              name={`${name}.username`}
              type='text'
              form={form}
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectCloudera,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connectionType === LakehouseConnectionTypeEnum.Cloudera),
              })}
              useNestedLabel
            />
            <TextField
              label='Password'
              description='The password for the Cloudera account you want to connect with'
              name={`${name}.password`}
              type='password'
              form={form}
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectCloudera,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connectionType === LakehouseConnectionTypeEnum.Cloudera),
              })}
              useNestedLabel
            />
            <TextField
              label='Database'
              description='The database containing the tables you want to share'
              name={`${name}.database`}
              type='text'
              form={form}
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectCloudera,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connectionType === LakehouseConnectionTypeEnum.Cloudera),
              })}
              useNestedLabel
            />
          </div>
        </div>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.canSubmit, state.isPristine]}>
        {([canSubmit, isPristine]) => (
          <StepButtons
            context={context}
            isSubmitting={getTablesMutation.isPending}
            nextBlocked={getTablesMutation.isPending || !canSubmit || isPristine}
            hasCancel={true}
            onCancel={() => navigate('../', { relative: 'path' })}
            onNext={() => {
              if (!validateJdbcUrlDatabase()) {
                notify.error('Database in JDBC URL must match the database value')
                return
              }
              getTablesMutation.mutate()
            }}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
