import { useQuery } from '@tanstack/react-query'
import useApi from 'src/utils/hooks/use-api'

export const useGetVendiaTableDetails = ({
  uniName,
  nodeName,
  tableKey,
}: {
  uniName: string
  nodeName: string
  tableKey: string
}) => {
  const api = useApi()
  return useQuery({
    queryKey: ['getVendiaTableDetails', { uniName, nodeName, tableKey }],
    queryFn: async () => {
      return api.getVendiaTableDetails({ uniName, nodeName, tableKey }) as Promise<{
        getVendiaTableDetails: { rowCount: string; totalDataSize: string; tableSchema: any[] }
      }>
    },
  })
}
