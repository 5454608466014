import { useQuery } from '@tanstack/react-query'
import { LakehouseConnectionInput } from '@vendia/management-api-types'
import { useParams } from 'react-router'
import { useRecoilValue } from 'recoil'
import useApi from 'src/utils/hooks/use-api'

import { selectedRoleState } from '../state'

export const useGetLakehouseConnections = () => {
  const { id: uniName, node: nodeName } = useParams()
  const api = useApi()
  const selectedRoleName = useRecoilValue(selectedRoleState).name

  return useQuery({
    queryKey: ['listLakehouseConnections', { nodeName, uniName }],
    queryFn: async () => {
      const { listLakehouseConnections, errors } = await api.listLakehouseConnections({
        input: {
          nodeName,
          uniName,
        },
      })
      if (errors) {
        console.error(`Could not list Lakehouse connections: ${errors[0].message}`)
        return
      }
      return listLakehouseConnections as { connections: LakehouseConnectionInput[] }
    },
    enabled: Boolean(api) && Boolean(uniName) && Boolean(nodeName) && Boolean(selectedRoleName),
    staleTime: 1000 * 30,
  })
}
