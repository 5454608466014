import Editor, { useMonaco } from '@monaco-editor/react'
import { useMutation } from '@tanstack/react-query'
import { LakehouseTableInput, TableColumnInput } from '@vendia/management-api-types'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import Button from 'src/components/buttons/button'
import Card from 'src/components/containers/card'
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from 'src/components/containers/resizable'
import Tabs from 'src/components/containers/tabs'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { JoinConfigurator } from 'src/components/lakehouse/sql/join-configurator'
import PageLoader from 'src/components/loaders/page-loader'
import { BasicTable } from 'src/components/tables/basic-table'
import { StepId } from 'src/pages/uni-create/config'
import useApi from 'src/utils/hooks/use-api'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { SQLGenerator } from 'src/utils/sql/query'

const TABLE_COLUMN_NAME_DELMITER = '__'
const createColumnAlias = (tableName: string = '', columnName: string = '') =>
  `${tableName}${TABLE_COLUMN_NAME_DELMITER}${columnName}`

export const StepJoinVendiaTables: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const navigate = useNavigate()

  const { uniName, nodeName, product } = context.form.useStore((state) => state.values)
  const selectedTables = product.selectedVendiaTables || []
  const [columns, setColumns] = useState<string[]>([])
  const [rows, setRows] = useState<any[][]>([])
  const [activeTab, setActiveTab] = useState<'sql' | 'visual'>('visual')
  const monaco = useMonaco()
  const api = useApi()
  const expectedTableNames = selectedTables.map((table) => table.name)
  const [validationError, setValidationError] = useState<string>('')
  const [isResultsPanelOpen, setIsResultsPanelOpen] = useState<boolean>(false)

  const editorRef = useRef<any>(null)
  const [isEditorReady, setIsEditorReady] = useState(false)

  const processColumnsForForm = (): TableColumnInput[] => {
    const leftTableDef = selectedTables.find((table) => table.name === product.queryDefinition?.leftTable?.table)
    const rightTableDef = selectedTables.find((table) => table.name === product.queryDefinition?.rightTable?.table)

    const getColumns = (tableDef?: LakehouseTableInput, selectedColumns?: TableColumnInput[]) =>
      product.queryDefinition?.returnAllColumns ? tableDef?.sourceTableDefinitions[0].columns : selectedColumns

    const leftTableColumns = getColumns(leftTableDef, product.queryDefinition?.leftTable?.selectedColumns)
    const rightTableColumns = getColumns(rightTableDef, product.queryDefinition?.rightTable?.selectedColumns)

    const selectedTableColumns: TableColumnInput[] = []

    const addColumns = (columns?: TableColumnInput[], tableName?: string) => {
      columns?.forEach((column) =>
        selectedTableColumns.push({
          ...column,
          sourceColumnName: `${tableName}.${column.name}`,
          name: createColumnAlias(tableName, column.name),
        }),
      )
    }

    addColumns(leftTableColumns, leftTableDef?.name)
    addColumns(rightTableColumns, rightTableDef?.name)

    return selectedTableColumns
  }

  const validateQuery = async (query?: string): Promise<string> => {
    try {
      if (!query?.trim()) {
        return 'Query cannot be empty'
      }

      const errors = SQLGenerator.validateSQL(query, expectedTableNames)
      return errors.length > 0 ? errors[0] : ''
    } catch (error) {
      return `Query validation error: ${error instanceof Error ? error.message : 'Unknown error'}`
    }
  }

  const fetchData = useMutation({
    mutationFn: async () =>
      api.fetchLakehouseDataPreview({
        uniName: uniName!,
        nodeName: nodeName!,
        joinTableKey: selectedTables[0].key,
        joinQuery: product.joinSql,
      }),
    onSuccess: (response: any) => {
      if (response.errors) {
        throw new Error(response.errors[0].message)
      }
      const results = response.fetchLakehouseDataPreview
      if (!results || results.length === 0) {
        setColumns([])
        setRows([])
        return
      }
      const columns = Object.keys(results[0])
      setColumns(columns)
      setRows(results?.map((row: any) => columns.map((header) => row[header])) ?? [])
      setIsResultsPanelOpen(true)
    },
  })

  useEffect(() => {
    const handleWindowResize = () => {
      if (editorRef.current && isEditorReady) {
        editorRef.current.layout()
      }
    }

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [isEditorReady])

  useEffect(() => {
    // Here's where we'll check if the sql editor is dirty and prompt the user
    // if they want to overwrite the sql with the current visual state
    if (activeTab === 'visual') {
      const queryDef = product.queryDefinition
      const leftTable = queryDef?.leftTable
      const rightTable = queryDef?.rightTable

      const validConditions = (queryDef?.joinConditions || []).filter(
        (condition) => condition?.leftField?.name && condition?.rightField?.name,
      )

      const leftJoinKeys = validConditions.map((condition) => condition.leftField)
      const rightJoinKeys = validConditions.map((condition) => condition.rightField)
      const joinType = queryDef?.joinType || 'INNER JOIN'
      const returnAllColumns = queryDef?.returnAllColumns !== false
      let columns: string[] | undefined = undefined

      if (leftTable && rightTable && leftJoinKeys.length > 0 && rightJoinKeys.length > 0) {
        if (!returnAllColumns) {
          const leftTableColumns = queryDef?.leftTable?.selectedColumns || []
          const rightTableColumns = queryDef?.rightTable?.selectedColumns || []

          if (leftTableColumns.length > 0 || rightTableColumns.length > 0) {
            columns = [
              ...leftTableColumns.map(
                (col) => `${leftTable.table}.${col.name} AS ${createColumnAlias(leftTable.table, col.name)}`,
              ),
              ...rightTableColumns.map(
                (col) => `${rightTable.table}.${col.name} AS ${createColumnAlias(rightTable.table, col.name)}`,
              ),
            ]
          }
        } else {
          columns = [
            ...(queryDef?.leftTable?.allColumns?.map(
              (col) => `${leftTable.table}.${col.name} AS ${createColumnAlias(leftTable.table, col.name)}`,
            ) || []),
            ...(queryDef?.rightTable?.allColumns?.map(
              (col) => `${rightTable.table}.${col.name} AS ${createColumnAlias(rightTable.table, col.name)}`,
            ) || []),
          ]
        }
        const sql = SQLGenerator.generateJoinSQL({
          leftTable: { name: leftTable.table, nodeName: leftTable.nodeName },
          rightTable: { name: rightTable.table, nodeName: rightTable.nodeName },
          leftJoinKey: leftJoinKeys.map((key) => key.name),
          rightJoinKey: rightJoinKeys.map((key) => key.name),
          joinType,
          columns,
          uniName,
        })
        context.form.setFieldValue('product.joinSql', sql)
      }
    }
  }, [
    activeTab,
    product.queryDefinition?.leftTable?.table,
    product.queryDefinition?.leftTable?.selectedColumns,
    product.queryDefinition?.rightTable?.table,
    product.queryDefinition?.rightTable?.selectedColumns,
    product.queryDefinition?.joinConditions,
    product.queryDefinition?.joinType,
    product.queryDefinition?.returnAllColumns,
  ])

  return (
    <>
      <StepWrapper>
        <StepContentHeader
          centered
          large
          title='Create a Join Query'
          description='Write a SQL query to join your selected Vendia tables'
        />
        <ScrollableStepContent inset>
          <div className='flex h-full w-full flex-col px-4'>
            {/* Tabs */}
            <div className='flex flex-row items-center justify-between gap-2 px-4 pb-4 text-sm font-bold'>
              <Tabs.Container borderless>
                <Tabs.Button isActive={activeTab === 'visual'} onClick={() => setActiveTab('visual')}>
                  Designer
                </Tabs.Button>
                <Tabs.Button isActive={activeTab === 'sql'} onClick={() => setActiveTab('sql')}>
                  SQL
                </Tabs.Button>
              </Tabs.Container>
              <div className='flex items-center gap-2'>
                <Button
                  kind='primary'
                  disabled={!product.joinSql}
                  onClick={async () => {
                    setIsResultsPanelOpen(true)
                    try {
                      setValidationError('')
                      setColumns([])
                      setRows([])
                      const validationResult = await validateQuery(product.joinSql)
                      if (validationResult) {
                        if (activeTab === 'sql' && monaco) {
                          const model = monaco.editor.getModels()[0]
                          monaco.editor.setModelMarkers(model, 'owner', [
                            {
                              startLineNumber: 1,
                              startColumn: 1,
                              endLineNumber: 1,
                              endColumn: 1,
                              message: validationResult,
                              severity: monaco.MarkerSeverity.Error,
                            },
                          ])
                        } else {
                          setValidationError(validationResult)
                        }
                        return
                      }
                      fetchData.mutate()
                    } catch (error) {
                      console.error('Error executing query:', error)
                      setValidationError(error instanceof Error ? error.message : 'An unexpected error occurred')
                    }
                  }}
                >
                  Run Query
                </Button>
              </div>
            </div>

            <ResizablePanelGroup direction='vertical' className='h-full w-full'>
              <ResizablePanel
                defaultSize={isResultsPanelOpen ? 75 : 100}
                minSize={isResultsPanelOpen ? 15 : 100}
                className='px-4 pb-3 pt-1'
              >
                <Card className={`h-full w-full rounded-xl`} padding='none' contentExpandVertical>
                  {activeTab === 'sql' && (
                    <div className='h-full max-h-[calc(75vh)] w-full'>
                      <Editor
                        width={'100%'}
                        height={`100%`}
                        defaultLanguage='sql'
                        defaultValue={product.joinSql || ''}
                        theme='vs-dark'
                        className='round-lg overflow-hidden'
                        options={{
                          minimap: {
                            enabled: false,
                          },
                          padding: {
                            top: 20,
                          },
                        }}
                      />
                    </div>
                  )}
                  {activeTab === 'visual' && (
                    <div className='mx-auto w-full max-w-5xl p-12'>
                      <JoinConfigurator context={context} />
                      {validationError && (
                        <div className='text-red-60w0 mt-4 rounded-md border border-red-200 bg-red-50 p-4'>
                          <div className='mb-1 font-semibold'>Query Error:</div>
                          <div>{validationError}</div>
                        </div>
                      )}
                    </div>
                  )}
                </Card>
              </ResizablePanel>

              {isResultsPanelOpen && (
                <>
                  <ResizableHandle withHandle />

                  {/* Bottom panel */}
                  <ResizablePanel defaultSize={25} minSize={15} className='px-4 pb-3 pt-3'>
                    <Card className='h-full w-full overflow-hidden rounded-xl' padding='none' contentExpandVertical>
                      <div className='flex h-full flex-col gap-4'>
                        {fetchData.isIdle && <div className='p-6'>Run a query to see results</div>}
                        {fetchData.isPending && (
                          <div className='flex min-h-48 items-center justify-center'>
                            <PageLoader />
                          </div>
                        )}
                        {fetchData.isSuccess && (
                          <div className='h-full'>
                            {fetchData.data.fetchLakehouseDataPreview.length === 0 ? (
                              <div className='border-uiBg-3 bg-uibg-0 flex min-h-48 items-center justify-center border'>
                                No data found
                              </div>
                            ) : (
                              <BasicTable columnHeaders={columns} rows={rows} />
                            )}
                          </div>
                        )}
                        {fetchData.isError && (
                          <div className='p-6'>
                            <div className='text-red-600'>
                              <div className='mb-1 font-semibold'>Error executing query:</div>
                              <div>{fetchData.error.message}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Card>
                  </ResizablePanel>
                </>
              )}
            </ResizablePanelGroup>
          </div>
        </ScrollableStepContent>
        <context.form.Subscribe selector={(state) => [state.canSubmit]}>
          {([canSubmit]) => (
            <StepButtons
              context={context}
              nextBlocked={!canSubmit || !fetchData.isSuccess || !columns.length}
              hasCancel={true}
              onCancel={() => navigate('../', { relative: 'path' })}
              onNext={() => {
                context.form.setFieldValue('selectedTableColumns', processColumnsForForm())
                context.form.setFieldValue('joinTableKey', selectedTables[0].key)
                context.goToStep(StepId.TableSettings)
              }}
            />
          )}
        </context.form.Subscribe>
      </StepWrapper>
    </>
  )
}
