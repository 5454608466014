import { isNonEmptyArray } from '@tanstack/react-form'
import { parseSqlAst } from '@vendia/management-api-common'
import {
  DataPolicyColumnEffect,
  LakehouseConnectionInput,
  LakehouseConnectionTypeEnum,
  LakehouseSyncScheduleEnum,
  LakehouseTableInput,
  Uni,
} from '@vendia/management-api-types'
import debug from 'debug'
import { useNavigate, useParams } from 'react-router'
import MultiStepFlow from 'src/components/flows/multi-step-flow'
import Loader from 'src/components/loaders/page-loader'
import { ALL_PARTNERS_STAR } from 'src/components/tables/sharing-rules-table.utils'
import { StepId } from 'src/pages/uni-create/config'
import { StepChooseSource } from 'src/pages/uni-create/flow-lakehouse/choose-source.step'
import { StepConnectCloudera } from 'src/pages/uni-create/flow-lakehouse/connect-cloudera.step'
import { StepConnectSnowflake } from 'src/pages/uni-create/flow-lakehouse/connect-snowflake.step'
import { StepCreateColumnSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/create-column-sharing-permissions.step'
import { StepCreateRowSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/create-row-sharing-permissions.step'
import { StepJoinVendiaTables } from 'src/pages/uni-create/flow-lakehouse/join-vendia-tables.step'
import { StepReviewMultipleTables } from 'src/pages/uni-create/flow-lakehouse/review-multi-table.step'
import { StepReviewSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/review-sharing-permissions.step'
import { StepSelectConnection } from 'src/pages/uni-create/flow-lakehouse/select-connection.step'
import { StepSelectTable } from 'src/pages/uni-create/flow-lakehouse/select-table.step'
import { StepSelectUpdateColumns } from 'src/pages/uni-create/flow-lakehouse/select-update-column.step'
import { StepSelectVendiaTables } from 'src/pages/uni-create/flow-lakehouse/select-vendia-tables.step'
import { StepTableSettings } from 'src/pages/uni-create/flow-lakehouse/table-settings.step'
import { generateTableAclInputFromAccessLevel } from 'src/utils/csv-uploader/graphql.utils'
import useApi from 'src/utils/hooks/use-api'
import { useGetLakehouseConnections } from 'src/utils/hooks/use-get-lakehouse-connections'
import { useGetLakehouseData } from 'src/utils/hooks/use-get-lakehouse-data'
import { useGetShareApps } from 'src/utils/hooks/use-get-share-apps'
import { useGetUni } from 'src/utils/hooks/use-get-uni'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import notify from 'src/utils/notify'
import { generateShortId } from 'src/utils/short-id'

const logger = debug('app:createVendiaTableFlow')

export const CreateVendiaTableFlow = () => {
  const { data: uniData } = useGetUni()
  const uni = uniData?.getUni as Uni
  const { node: nodeName } = useParams()
  const { data } = useGetLakehouseData()
  const shareAppConfig = data?.currNodeConfig
  const useGetLakehouseConnectionsResponse = useGetLakehouseConnections()

  const navigate = useNavigate()
  const { refetch: refetchShareApps } = useGetShareApps({ uniName: uni?.name })
  const api = useApi()

  const existingTables = data?.tables ?? []

  const addConnection = async (
    connectionName: string,
    connectionId: string,
    source: LakehouseFlowStepValues['source'],
  ) => {
    const connection: LakehouseConnectionInput = {
      id: connectionId,
      name: connectionName,
      type: source.connection!,
      [source.connection === LakehouseConnectionTypeEnum.Snowflake ? 'snowflakeConnection' : 'clouderaConnection']:
        source[source.connection === LakehouseConnectionTypeEnum.Snowflake ? 'snowflake' : 'cloudera'],
    }
    return await api.addLakehouseConnection({
      input: {
        uniName: uni.name,
        nodeName: nodeName,
        connection,
      },
    })
  }

  const addTable = async ({ value }: { value: LakehouseFlowStepValues }) => {
    const { product: table, source, accessLevel } = value
    const existingIds = existingTables.map((product) => product.key)
    const shortId = generateShortId({ existingIds })
    existingIds.push(shortId)
    const newTableInput: LakehouseTableInput = {
      key: shortId,
      name: table.name,
      syncSchedule: table.syncSchedule,
      tableDefinition: {
        columnPolicies: table.columnPolicies,
        rowPolicies: table.rowPolicies,
        defaultColumnPolicyEffect: table.defaultColumnPolicyEffect,
      },
      sourceConnectionId: value.connectionId ?? '',
      sourceConnectionType: source.connection!,
      sourceTableDefinitions: [
        {
          tableName: source.selectedTable,
          columns: value.selectedTableColumns,
          lastUpdatedColumn: value.lastUpdatedColumn,
          lastUpdatedColumnFormat: value.lastUpdatedColumnFormat,
          keyColumns: value.keyColumns,
        },
      ],
      joinQuery: table.joinSql ? parseSqlAst(table.joinSql) : undefined,
    }

    const aclInput = generateTableAclInputFromAccessLevel(accessLevel)

    const response = await api.addLakehouseTable({
      input: {
        uniName: uni.name,
        nodeName: nodeName!,
        table: newTableInput,
        aclInput,
      },
    })

    if (response.errors) {
      notify.error(`Error adding table: ${response.errors.map((error) => error.message).join(', ')}`)
    } else {
      notify.success('Table added successfully!')
    }
  }

  const addIncompleteTables = async ({
    selectedTablesList,
    value,
  }: {
    value: LakehouseFlowStepValues
    selectedTablesList: string[]
  }) => {
    const { product: table } = value
    // Create incomplete tables and fire request for each one
    const existingIds = existingTables.map((product) => product.key)
    const tables = selectedTablesList.map((tableName) => {
      const shortId = generateShortId({ existingIds })
      existingIds.push(shortId)
      const tableInput: LakehouseTableInput = {
        key: shortId,
        // Have to lowercase OUR copy of the name, but keep original name in the source table definitions
        // We want to let the user rename this when they complete the table setup...
        // TODO: we'll need a check to make sure table name can only be changed when status is INCOMPLETE
        name: tableName.toLowerCase(),
        syncSchedule: table.syncSchedule,
        tableDefinition: {
          columnPolicies: [],
          rowPolicies: [],
          defaultColumnPolicyEffect: table.defaultColumnPolicyEffect,
        },
        sourceConnectionId: value.connectionId ?? '',
        sourceConnectionType: value.source.connection!,
        sourceTableDefinitions: [
          {
            tableName: tableName,
            columns: [],
            keyColumns: [],
          },
        ],
      }
      return tableInput
    })
    const response = await api.addLakehouseIncompleteTables({
      input: {
        uniName: uni.name,
        nodeName: nodeName!,
        tables,
      },
    })
    if (response.errors) {
      notify.error(`Error adding tables: ${response.errors.map((error) => error.message).join(', ')}`)
    } else if (response.addLakehouseIncompleteTables?.errorTables.length > 0) {
      notify.error(`Error adding one or more tables: ${response.addLakehouseIncompleteTables.errorTables.join(', ')}`)
    } else {
      notify.success(
        'Tables added successfully! You may now configure data filtering, permissions, and sync settings for each table individually.',
      )
    }
  }

  if (!uni || !shareAppConfig) {
    return (
      <div className='grid place-items-center'>
        <Loader />
      </div>
    )
  }

  return (
    <MultiStepFlow<LakehouseFlowStepValues>
      initialFlowState={{
        flowType: 'addTable',
        accessLevel: {
          // Adding a key for each partner node name in uni.nodes defaulting to 'READ' for now
          [ALL_PARTNERS_STAR]: 'READ',
          ...(uni?.nodes
            ?.filter((node) => node.name !== nodeName)
            .reduce((acc, node) => ({ ...acc, [node.name]: 'READ' }), {}) ?? {}),
        },
        uniName: uni.name,
        nodeName: nodeName,
        // TODO this field is still named 'product' for now, but it really should be "table" or "target" for something more generic
        product: {
          name: '',
          columnPolicies: [],
          rowPolicies: [],
          defaultColumnPolicyEffect: DataPolicyColumnEffect.Exclude,
          syncSchedule: LakehouseSyncScheduleEnum.OnDemand,
          selectedVendiaTables: [],
          queryDefinition: {
            leftTable: {
              table: '',
              selectedColumns: [],
            },
            rightTable: {
              table: '',
              selectedColumns: [],
            },
            joinConditions: [],
            joinType: 'INNER JOIN',
            returnAllColumns: true,
          },
        },
        selectedTables: {},
        source: {
          connection: undefined,
          availableTables: [],
          selectedTable: '',
          sourceRegion: '',
        },
        // Start with an empty space entry so the first dropdown is rendered
        keyColumns: [''],
        lastUpdatedColumn: '',
        lastUpdatedColumnFormat: '',
        useIncrementalUpdates: true,
        existingVendiaTables: existingTables,
        existingConfig: shareAppConfig,
        connectionName: '',
        connectionId: '',
      }}
      flowSteps={[
        {
          id: StepId.ChooseSource,
          StepComponent: StepChooseSource,
        },
        {
          id: StepId.ConnectSnowflake,
          StepComponent: StepConnectSnowflake,
          showInStepNav: false,
        },
        {
          id: StepId.ConnectCloudera,
          StepComponent: StepConnectCloudera,
          showInStepNav: false,
        },
        {
          id: StepId.SelectConnection,
          StepComponent: StepSelectConnection,
          showInStepNav: false,
        },
        {
          id: StepId.SelectTable,
          StepComponent: StepSelectTable,
          showInStepNav: false,
        },
        {
          id: StepId.SelectVendiaTables,
          StepComponent: StepSelectVendiaTables,
          showInStepNav: false,
        },
        {
          id: StepId.JoinVendiaTables,
          StepComponent: StepJoinVendiaTables,
          showInStepNav: false,
        },
        {
          id: StepId.TableSettings,
          StepComponent: StepTableSettings,
        },
        {
          id: StepId.SelectUpdateColumns,
          StepComponent: StepSelectUpdateColumns,
        },
        {
          id: StepId.ColumnSharingPermissions,
          StepComponent: StepCreateColumnSharingPermissions,
        },
        {
          id: StepId.RowSharingPermissions,
          StepComponent: StepCreateRowSharingPermissions,
        },
        {
          id: StepId.SharingPermissions,
          StepComponent: StepReviewSharingPermissions,
        },
        {
          id: StepId.ReviewMultipleTables,
          StepComponent: StepReviewMultipleTables,
        },
      ]}
      onSubmit={async ({ value }) => {
        const { product, source, selectedTables } = value
        if (!value.connectionId && !isNonEmptyArray(product.selectedVendiaTables)) {
          // If there is no connectionId and no selected existing tables, the user is creating a new connection.
          const connections = useGetLakehouseConnectionsResponse.data?.connections ?? []
          value.connectionId = generateShortId({
            existingIds: connections.map((c) => c.id).filter((id): id is string => id !== undefined) ?? [],
          })
          const addConnectionResponse = await addConnection(value.connectionName.trim(), value.connectionId, source)
          if (addConnectionResponse.errors) {
            notify.error(addConnectionResponse.errors[0].message)
            return
          }
        }

        const selectedTablesList = Object.entries(selectedTables)
          .filter(([_, value]) => value)
          .map(([key]) => key)

        if (selectedTablesList.length > 1) {
          await addIncompleteTables({ selectedTablesList, value })
        } else {
          await addTable({ value })
        }

        refetchShareApps()
        navigate('../', { relative: 'path' })
      }}
    />
  )
}
