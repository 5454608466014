import clsx from 'clsx'
import debug from 'debug'
import { useCallback } from 'react'

import Card from '../containers/card'
import { FormInputProps } from '../fields/types'
import CheckboxInput from './checkbox.input'

const logger = debug('app:CheckboxGroup')

export type CheckboxGroupInputProps = FormInputProps & {
  onChange?: (value: any) => void
  options: {
    value: any
    label: string | JSX.Element
    description?: string | JSX.Element
  }[]
  value?: string[]
}

function CheckboxGroupInput({
  label,
  description,
  value,
  hasErrors,
  options,
  className,
  ...rest
}: CheckboxGroupInputProps) {
  // Prevent setting defaultValue before the form is registered
  if (value === undefined) {
    return null
  }

  const handleChange = useCallback(
    (checked: boolean, optionValue: any) => {
      if (checked) {
        rest.onChange?.([...(value || []), optionValue])
      } else {
        rest.onChange?.(value?.filter((v) => v !== optionValue))
      }
    },
    [value, rest.onChange],
  )

  const isChecked = useCallback(
    (optionValue: any) => {
      // logger('isChecked', { optionValue, value })
      return value?.includes(optionValue)
    },
    [value],
  )

  return (
    <div className={clsx('flex flex-col gap-1', className)}>
      {(label || description) && (
        <div>
          {label && (
            <div className='flex flex-col gap-1'>
              <div className='text-sm font-semibold'>{label}</div>
            </div>
          )}
          {description && <p className='text-light text-xs'>{description}</p>}
        </div>
      )}
      <Card padding='none' className='rounded-md'>
        <div className={'flex flex-col'}>
          {options.map((option, index) => {
            return (
              <div
                key={JSON.stringify(option.value)}
                className={clsx('w-full border-t p-2', index === 0 && '!border-t-0')}
              >
                <div className={`flex items-center gap-3`}>
                  <CheckboxInput
                    {...rest}
                    checked={isChecked(option.value)}
                    onChange={handleChange}
                    value={option.value}
                    label={option.label}
                    description={option.description}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </Card>
    </div>
  )
}

export default CheckboxGroupInput
