import clsx from 'clsx'
import { getOperatorLabel } from 'src/utils/lakehouse/flows'
import { LakehouseTableForm } from 'src/utils/lakehouse/types'

export const ProductRowPolicies = ({ table }: { table: LakehouseTableForm }) => {
  const hasRules = table.rowPolicies.length > 0

  return (
    <div className={clsx('flex flex-col text-sm', hasRules && 'bg-white')}>
      {!hasRules && (
        <span className='ml-2'>
          All rows default to be <strong>included</strong>
        </span>
      )}
      <div className='mt-1 flex flex-col bg-white text-sm'>
        {!hasRules && <h6 className='text-l border-neutral-5 border bg-white p-4 font-semibold'>No rules</h6>}
        {hasRules &&
          table.rowPolicies?.map((rowPolicy) => (
            <div className='border-neutral-5 border border-b-0 bg-white p-4 last:border-b' key={rowPolicy.name}>
              <strong>Filter data,</strong>
              {' where '}
              <strong>{rowPolicy.name}</strong>
              {' is '}
              <strong>{getOperatorLabel(rowPolicy.operator!)?.toLowerCase()}</strong> {rowPolicy.value}.
            </div>
          ))}
      </div>
    </div>
  )
}
