import clsx from 'clsx'
import { FocusEvent, ForwardedRef, forwardRef, ReactElement, RefObject, useState } from 'react'

import { FormInputProps } from '../fields/types'
import { useFocusOnError } from './use-focus-on-error'

export type NumberInputProps = FormInputProps & {
  leftOfInput?: ReactElement
  rightOfInput?: ReactElement
  onChange?: (v: number) => void
  value?: number
}

const NumberInput = (
  {
    name,
    className,
    disabled,
    label,
    leftOfInput,
    rightOfInput,
    placeholder,
    useNestedLabel,
    value = 0,
    hasErrors,
    onChange,
    onBlur,
    onFocus,
    focusOnError,
    ...rest
  }: NumberInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const [isFocused, setIsFocused] = useState(false)
  useFocusOnError(ref as RefObject<HTMLInputElement>, hasErrors, focusOnError)

  const inputClasses = clsx(
    className,
    'placeholder:text-light flex h-12 w-full flex-1 flex-col rounded-md px-4 shadow outline-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
    !disabled && 'text-dark bg-white',
    disabled && 'text-light cursor-not-allowed bg-slate-100',
    !isFocused && !hasErrors && 'ring ring-1 ring-slate-300',
    !isFocused && hasErrors && 'z-2 ring-2 ring-red-500',
    isFocused && 'ring-brand z-2 ring-2',
    useNestedLabel && value && 'pt-3',
  )

  return (
    <div className='flex w-full'>
      {leftOfInput}
      <input
        {...rest}
        type='number'
        id={name}
        name={name}
        aria-describedby={`${name}-description`}
        className={inputClasses}
        disabled={disabled}
        placeholder={useNestedLabel ? (label as string) : placeholder}
        onFocus={(e: FocusEvent<HTMLInputElement, Element>) => {
          setIsFocused(true)
          if (onFocus) onFocus(e)
        }}
        onBlur={(e: FocusEvent<HTMLInputElement, Element>) => {
          setIsFocused(false)
          if (onBlur) onBlur(e)
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (onChange) {
            onChange(e.target.valueAsNumber)
          }
        }}
        value={value}
        ref={ref}
      />
      {rightOfInput}
    </div>
  )
}

export default forwardRef<HTMLInputElement, NumberInputProps>(NumberInput)
