export enum CreateFlow {
  SELECT_TYPE = 'select-type',
  STANDARD = 'standard',
  DISTRO = 'lakehouse',
}

export enum StepId {
  CreateNetwork = 'create_network',
  CreateLakehouse = 'create_lakehouse',
  ChooseSource = 'choose_source',
  ConnectSnowflake = 'connect_snowflake',
  ConnectCloudera = 'connect_cloudera',
  SelectTable = 'select_table',
  SharingPermissions = 'sharing_permissions',
  ColumnSharingPermissions = 'column_sharing_permissions',
  RowSharingPermissions = 'row_sharing_permissions',
  AddNodes = 'add_nodes',
  Confirmation = 'confirmation',
  SelectUpdateColumns = 'select_update_columns',
  TableSettings = 'table_settings',
  SelectVendiaTables = 'select_vendia_tables',
  JoinVendiaTables = 'join_vendia_tables',
  SelectConnection = 'select_connection',
  ReviewMultipleTables = 'review_multiple_tables',
}

export const DEFAULT_REGION = 'us-west-2'
