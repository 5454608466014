import LoginForm from 'src/components/forms/login-form'
import { Illustration } from 'src/layouts/illustration.layout'
import { POSTFIX, useDocumentTitle } from 'src/utils/hooks/use-title'

export const LoginOverImageContent = () => {
  return (
    <div className='max-w-lg space-y-6 text-center'>
      <h1 className='h0'>
        Data sharing, <br /> simplified.
      </h1>
      <p className='text-light text-xl'>
        Connect any system, unlock insights, and drive action, all with Vendia's all-in-one platform.
      </p>
    </div>
  )
}

export default function Login() {
  useDocumentTitle(`Log in - ${POSTFIX}`)

  return (
    <Illustration overImageContent={<LoginOverImageContent />}>
      <LoginForm />
    </Illustration>
  )
}
